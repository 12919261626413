import React from 'react'
import './ObservationsLayer.scss';

import { AuthContext } from '../../context/AuthContext';
import { firestore } from '../../context/FirebaseConfig';
import { collection, onSnapshot, orderBy, where, query } from 'firebase/firestore';
import { addSeconds, subSeconds } from 'date-fns';

const ObservationsLayer = React.forwardRef(({ 
    fullSizePosToDisplaySizePos, interpolateFullSizePosForTimestamp, interpolateTimestampForFullSizePos, 
    screenPosToFullSizePos, materialFlowData, displaySizeOffset, displayWidth, smoothAnimation, activeObservation, 
    setActiveObservation, setOffsetToTimestamp, displayImageHeight, scrollTimestamp, appendMaterialFlowData,
    showBoundingBoxes
}, ref) => {
    const { currentUser, labelsets } = React.useContext(AuthContext);
    const [observations, setObservations] = React.useState({});
    const unsubscribe = React.useRef(null);
    const loadingAnchorOffset = React.useRef(null);

    function loadObservations() {
        /* 
        Subscribe to observations in the current view (with live updates)
        Load observations withing the current view and a padding of 2x the display width or 10 minutes
        */
        const LOADING_PADDING_SEC = 900;
        loadingAnchorOffset.current = displaySizeOffset;

        // Unsubscribe from previous listener
        if (unsubscribe.current) {
            unsubscribe.current();
        }

        // Get the left and right edges to load bounding boxes (as timestamps)
        const fullSizePosLeftEdge = screenPosToFullSizePos(0);
        const timeLeftEdge = interpolateTimestampForFullSizePos(fullSizePosLeftEdge);
        const timeLeftEdgePaddedTime = addSeconds(timeLeftEdge, LOADING_PADDING_SEC);
        const timeLeftEdgePaddedDistance = interpolateTimestampForFullSizePos(fullSizePosLeftEdge - displayWidth*2);
        const timeLeftEdgePadded = Math.max(timeLeftEdgePaddedTime, timeLeftEdgePaddedDistance);

        const fullSizePosRightEdge = screenPosToFullSizePos(displayWidth);
        const timeRightEdge = interpolateTimestampForFullSizePos(fullSizePosRightEdge);
        const timeRightEdgePaddedTime = subSeconds(timeRightEdge, LOADING_PADDING_SEC);
        const timeRightEdgePaddedDistance = interpolateTimestampForFullSizePos(fullSizePosRightEdge + displayWidth*2);
        const timeRightEdgePadded = Math.min(timeRightEdgePaddedTime, timeRightEdgePaddedDistance);

        if (!timeLeftEdge || !timeRightEdge) return;

        // Query bounding boxes
        const observationsQuery = query(
            collection(firestore, "clients", currentUser.company.company, "observations"),
            where("device", "==", currentUser.device_id),
            where("timestamp", ">=", new Date(timeRightEdgePadded)),
            where("timestamp", "<=", new Date(timeLeftEdgePadded)),
            orderBy("timestamp", "asc"),
        );
        unsubscribe.current = onSnapshot(observationsQuery, (snapshot) => {
            const observationsData = {};
            snapshot.forEach((doc) => {
                const data = {...doc.data(), doc_id: doc.id};
                observationsData[doc.id] = data;
            });
            setObservations(observationsData);
        });
    }

    // Reload observations when the user scrolled one display width since last loading
    React.useEffect(() => {
        if (!loadingAnchorOffset.current || Math.abs(loadingAnchorOffset.current - displaySizeOffset) > displayWidth) {
            loadObservations();
        }
    }, [displaySizeOffset]);
    // Reload observations when the material flow data changes (e.g. new data loaded)
    React.useEffect(() => {
        loadObservations();
    }, [materialFlowData]);

    // Pre-compute the full-size pixel position for each observation
    const observationsFullSizePos = React.useMemo(() => {
        const observationsFullSizePosNew = {};
        Object.entries(observations).forEach(([id, observation]) => {
            observationsFullSizePosNew[id] = interpolateFullSizePosForTimestamp(observation.timestamp.toDate());
        });
        return observationsFullSizePosNew;
    }, [observations, materialFlowData]);

    /** Filter out visible observations and memoize their display position */
    const visibleObservations = React.useMemo(() => {
        const PADDING = displayWidth;
        const visibleObservationsNew = {};
        Object.entries(observations).forEach(([id, observation]) => {
            if (!(id in observationsFullSizePos) || !observationsFullSizePos[id]) {
                return;
            }
            const displaySizePos = fullSizePosToDisplaySizePos(observationsFullSizePos[id]);
            if (displaySizePos+displaySizeOffset >= -PADDING && displaySizePos+displaySizeOffset <= displayWidth+PADDING) {
                const margin = Math.min(displaySizePos+displaySizeOffset, displayWidth-(displaySizePos+displaySizeOffset));
                
                //Calculate top position of the observation
                const hasPosTop = observation?.bbox_dimensions?.center_pos_top && observation?.bbox_dimensions?.source_image_shape;
                const posTop = hasPosTop ? `${(observation.bbox_dimensions.center_pos_top / observation.bbox_dimensions.source_image_shape.height) * 100}%` : '50%';
                const displaySizeWidth = fullSizePosToDisplaySizePos(observation?.bbox_dimensions?.width ?? 0);
                const displaySizeHeight = fullSizePosToDisplaySizePos(observation?.bbox_dimensions?.height ?? 0);
                visibleObservationsNew[id] = {
                    ...observation, 
                    displaySizePos: displaySizePos,
                    displaySizePosTop: posTop,
                    displaySizeWidth: displaySizeWidth,
                    displaySizeHeight: displaySizeHeight,
                    hasPosTop: hasPosTop,
                    margin: margin,
                };
            }
        });
        return visibleObservationsNew;
    }, [observations, displaySizeOffset, displayWidth, materialFlowData]);

    function jumpToNextObservation(direction) {
        /** Move screen to the next observation in the given direction. Set direction to "left" or "right". */
        // find closest timestamp to the current scroll time
        let closestTimestampDiff = null;
        let closestObservation = null;
        Object.entries(observations).forEach(([observation_id, observation]) => {
            // Skip observations that are not in the direction of the jump (with a 1 second margin)
            if (direction === "left" && observation.timestamp.toDate().getTime() < scrollTimestamp.getTime()+1000) {
                return;
            } else if (direction === "right" && observation.timestamp.toDate().getTime() > scrollTimestamp.getTime()-1000) {
                return;
            }

            const diff = Math.abs(observation.timestamp.toDate().getTime() - scrollTimestamp.getTime());
            if (closestTimestampDiff === null || diff < closestTimestampDiff) {
                closestTimestampDiff = diff;
                closestObservation = observation;
            }
        });

        // If no observation was found, load new data
        if (closestObservation === null) {
            appendMaterialFlowData(direction);
            // For now return. In future find the closest observation in the new data and jump there
            return;
        }

        // Move screen to the next timestamp
        selectObservation(closestObservation);
    }

    function selectObservation(observation) {
        /** Set the active observation and move the screen to the observation */
        setActiveObservation(observation);
        setOffsetToTimestamp(observation.timestamp.toDate())
    }

    // Make function available to parent component
    React.useImperativeHandle(ref, () => ({
        jumpToNextObservation
    }));

    return (
        <div className="observations-container" style={{opacity: displayImageHeight.current < 250 ? 0 : 1}}>
        {Object.entries(visibleObservations).map(([id, observation]) => {
            const isActive = activeObservation && activeObservation.doc_id === id;
            const isMaterialLabeled = Object.keys(observation?.labels?.materials ?? {}).length > 0;
            const materialId = isMaterialLabeled ? Object.values(observation.labels.materials)[0] : observation?.predicted_labels?.materials?.class;
            const isQualityLabeled = Object.keys(observation?.labels?.quality ?? {}).length > 0;
            const qualityId = isQualityLabeled ? Object.values(observation.labels.quality)[0] : observation?.predicted_labels?.quality?.class;
            return (
                <div key={id} 
                    style={{
                        left: displaySizeOffset+observation.displaySizePos,
                        top: observation.displaySizePosTop,
                    }} 
                    className={`observation ${smoothAnimation.current ? "smooth-scrolling" : ""}`}
                >
                    <button className={`observation-point ${isActive ? 'active-observation-point' : ""}`} onClick={() => selectObservation(observation)}></button>
                    <div className="vertical-line"></div>
                    <p className="time">{observation.time}</p>
                    <p className={`material ${isMaterialLabeled && "labeled"}`}>{labelsets["materials"].labels?.[materialId]?.display_name}</p>
                    <p className={`quality ${isQualityLabeled && "labeled"}`}>{labelsets["quality"].labels?.[qualityId]?.display_name}</p>
                    {showBoundingBoxes && observation.hasPosTop && <div className="observation-bounding-box" style={{
                        width: observation.displaySizeWidth,
                        height: observation.displaySizeHeight,
                        transform: `translate(-${observation.displaySizeWidth/2}px, -${observation.displaySizeHeight/2}px)`,
                    }}></div>}
                </div>
            );
        })}
    </div>
    )
});

export default ObservationsLayer;