import React from "react";
import "./ReclamationsTableCard.scss";

import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import { useTable } from 'react-table'
import { format } from "date-fns";

// Importing logos
import photo_icon from "../../../assets/icons/gray/image.png";

export default function ReclamationsTableCard({cardData, payload}) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);

    const COLUMNS = [
        {
            Header: t("property_date"),
            accessor: (row) => {return format(row.date, "dd.MM.yyyy")}
        },
        {
            Header: t("property_time"),
            accessor: (row) => {return format(row.date, "HH:mm")}
        },
        {
            Header: t("reclamation_nr"),
            accessor: (row) => {return row?.reclamation_nr?.toString().padStart(4, '0')}
        },
        {
            Header: t("property_supplier"),
            accessor: (row) => {
                return payload.reclamationSettings?.supplier?.[row.supplier] ?? row.supplier;
            }
        },
        {
            Header: t("property_material"),
            accessor: (row) => {
                return labelsets?.['materials']?.['labels']?.[row.materials]?.display_name ?? "";
            }
        },
        {
            Header: t("property_photos"),
            accessor: (row) => {
                return(<div className="photo-property">
                    <p>{Object.keys(row?.bales).length ?? 0}</p>
                    <img src={photo_icon}/>
                </div>)
            }
        },
    ];
    const columns = React.useMemo(() => COLUMNS, [labelsets['materials'], i18n.language, payload.reclamationSettings?.supplier]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: Object.values(payload.reclamations).reverse() })

    // If there are no reclamations, display a message
    if (!payload.reclamations || Object.keys(payload.reclamations).length === 0) {
        return (
            <div className="reclamations-table-card-body">
                <p>{t("no_reclamations")}</p>
            </div>
        )
    }

    return (
        <div className="reclamations-table-card-body">
            <div className="table-wrapper">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} 
                                    onClick={() => {payload.setActiveReclamation(row.original.id)}} 
                                    className={row.original.id==payload.activeReclamation ? "active-row" : undefined}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}