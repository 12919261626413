import React from "react"
import "./LastObservationCard.scss"
import { useTranslation } from "react-i18next";

import ObservationSquare from "../../ObservationSquare/ObservationSquare";
import FullscreenObservationModal from "../../FullscreenObservationModal/FullscreenObservationModal";

export default function LastObservationCard({ cardData, payload }) {
    const { i18n, t } = useTranslation();

    const [ showFullscreenObservation, setShowFullscreenObservation ] = React.useState(false);

    function getNextObservation(currentObservation) {
        const nextIndex = payload.findIndex(obj => obj.doc_id === currentObservation.doc_id) + 1
        setShowFullscreenObservation( payload[Math.min(nextIndex, payload.length-1)] );
    };
    function getPreviousObservation (currentObservation) {
        const previousIndex = payload.findIndex(obj => obj.doc_id === currentObservation.doc_id) - 1;
        setShowFullscreenObservation( payload[Math.max(previousIndex, 0)] );
    };

    return (
        <div className="lastobservationcard-body">
            {payload.map(observation => <div 
                onClick={() => setShowFullscreenObservation(observation)}
                key={observation.image_url}>
                    <ObservationSquare observation={observation} />
            </div>)}

            {showFullscreenObservation && <FullscreenObservationModal 
                observation={showFullscreenObservation}
                closeFullscreenModal={() => setShowFullscreenObservation(false)}
                getNext={(observation) => {getNextObservation(observation)}}
                getPrevious={(observation) => {getPreviousObservation(observation)}}
            /> }
        </div>
    )
}