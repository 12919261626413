import React from "react";
import "./Labeling.scss";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";

import { collection, doc, query, where, getDocs, onSnapshot, limit, startAfter, getCountFromServer } from "firebase/firestore";

// Importing custom pages
import FullscreenObservationModal from "../../components/FullscreenObservationModal/FullscreenObservationModal";
import ObservationSquare from "../../components/ObservationSquare/ObservationSquare";
import OverflowSquare from "../../components/ObservationSquare/OverflowSquare";

export async function getNumRequests(client, device_id) {
    /* Get the number of requests */
    let observationQuery = query(collection(firestore, "clients", client, "observations"), 
    where("device", "==", device_id),
    where("labelled", "==", false),
    where("request_label", "==", true));
    const snapshot = await getCountFromServer(observationQuery);
    return snapshot.data().count;
}

export default function LabelingClassicRequested() {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [ observations, setObservations ] = React.useState({});
    const [ currentObservation, setCurrentObservation ] = React.useState(null);
    const [ allLoaded, setAllLoaded ] = React.useState(false);
    const [ numRequests, setNumRequests ] = React.useState(0);
    const lastSnapshot = React.useRef(null);
    const observationSubscription = React.useRef(null);

    const LOAD_DOCS_START = 50;

    async function loadObservations() {
        /* Load the next batch of observations with labeling requests */
        if (!currentUser.company.company) {
            return;
        }

        let observationQuery = query(collection(firestore, "clients", currentUser.company.company, "observations"), 
        where("device", "==", currentUser.device_id),
        where("labelled", "==", false),
        where("request_label", "==", true),
        limit(LOAD_DOCS_START));

        // Data pagination: Get the next documents in line after the last one
        if (lastSnapshot.current) {
            observationQuery = query(observationQuery, startAfter(lastSnapshot.current));
        }

        const newObservations = {};
        const querySnapshot = await getDocs(observationQuery);
        querySnapshot.forEach((doc) => {
            lastSnapshot.current = doc;
            newObservations[doc.id] = {...doc.data(), doc_id:doc.id};
        });
        setObservations((prevObservations) => {
            return {
                ...prevObservations, 
                ...newObservations,
            };
        });

        // If no more documents are available with labeling requests, set the allLoaded flag
        if (querySnapshot.empty || querySnapshot.size < LOAD_DOCS_START) {
            setAllLoaded(true);
        }

        // If you reached the last observation, close the fullscreen modal.
        // If the fullscreen modal is open, move to the next observation after loading.
        if (querySnapshot.empty) {
            setCurrentObservation(null);
        } else if (currentObservation) {
            setCurrentObservation(Object.values(newObservations)[0]);
        }
    }

    React.useEffect(() => {
        /* Initialize by loading first batch of documents and the number of requests */
        async function loadNumRequests() {
            if (!currentUser.company.company) {
                return;
            }
            setNumRequests(await getNumRequests(currentUser.company.company, currentUser.device_id));
        }

        loadNumRequests();
        loadObservations();
    }, []);

    async function subscribeToObservation(docId) {
        /* Set up a listener on the doc-id to update on changes */
        // Unsubscribe from the previous listener
        if (observationSubscription.current) {
            observationSubscription.current();
        }

        // Subscribe to doc. On changes update the observation
        const observationUpdateQuery = query(doc(firestore, "clients", currentUser.company.company, "observations", docId))
        const unsubscribe = onSnapshot(observationUpdateQuery, (doc) => {
            const newObservation = {...doc.data(), doc_id:doc.id};
            setObservations((prevObservations) => {
                return {
                    ...prevObservations, 
                    [doc.id]: newObservation,
                };
            });
        });
        observationSubscription.current = () => unsubscribe();
    }

    React.useEffect(() => {
        /* Subscribe to the current observation when opened */
        if (currentObservation) {
            subscribeToObservation(currentObservation.doc_id);
        }
    }, [currentObservation]);

    function getNextObservation(currentObservation) {
        /* Go the next observation. If last loaded observation is reached, load new ones */
        const nextIndex = Object.values(observations).findIndex(obj => obj.doc_id === currentObservation.doc_id) + 1;
        if (nextIndex >= Object.values(observations).length) {
            if (!allLoaded) {
                loadObservations();
            } else {
                setCurrentObservation(false);
            }
        } else {
            setCurrentObservation(Object.values(observations)[nextIndex]);
        }
    };
    function getPreviousObservation (currentObservation) {
        /* Go the previous observation */
        const previousIndex = Object.values(observations).findIndex(obj => obj.doc_id === currentObservation.doc_id) - 1;
        setCurrentObservation( Object.values(observations)[Math.max(previousIndex, 0)] )
    };

    return (
        <div className="labeling-classic">
            <div className="labeling--list">
                {Object.values(observations).map(observation => <div 
                    onClick={() => setCurrentObservation(observation)}
                    key={observation.image_url}>
                        <ObservationSquare observation={observation} markLabeled={true}/>
                </div>)}
                {!allLoaded && <OverflowSquare numOverflow={numRequests-Object.keys(observations).length} onLoadOverflow={loadObservations}/> }
            </div>
            {allLoaded && <p className="labeling--no-more-requests">{t("labeling_no_more_requests")}</p>}
            {currentObservation && <FullscreenObservationModal 
                observation={currentObservation}
                closeFullscreenModal={() => {setCurrentObservation(false)}}
                getNext={getNextObservation}
                getPrevious={getPreviousObservation}
            />}
        </div>
    )
}