import React from "react";
import "./Labeling.scss";

import { AuthContext } from '../../context/AuthContext.js';
import { firestore } from "../../context/FirebaseConfig.js";
import { useTranslation } from "react-i18next";

import EloRank from "elo-rank";
import { Rating, rate_1vs1 } from 'ts-trueskill';
import { format } from 'date-fns';
import { collection, doc, getDoc, setDoc, getDocs, onSnapshot, increment, serverTimestamp } from "firebase/firestore";
import { fetchImageUrl } from "../../utils/ImgUtils.js"

export default function LabelingPairwiseRanking() {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [ rankingSets, setRankingSets ] = React.useState({});
    const [ selectedRankingSet, setSelectedRankingSet ] = React.useState("");
    const [ scores, setScores ] = React.useState({});
    const [ competitors, setCompetitors ] = React.useState({});   
    const loadingCompetitors = React.useRef(false); 

    async function getRankingSets() {
        /* Get available ranking sets */
        const rankingSetsNew = {};
        const rankingSetsRef = collection(firestore, "clients", currentUser.company.company, "pairwise_ranking");
        const snapshot = await getDocs(rankingSetsRef);
        snapshot.forEach(rankingSetDoc => {
            if (rankingSetDoc.data()?.enable_labeling) {
                rankingSetsNew[rankingSetDoc.id] = rankingSetDoc.data();
            }
        });
        setRankingSets(rankingSetsNew);
        // Set the first one as the currently selected
        if (rankingSetsNew && Object.keys(rankingSetsNew).length > 0) {
            setSelectedRankingSet(Object.keys(rankingSetsNew)[0]);
        }
    }
    React.useEffect(() => {
        getRankingSets();
    }, []);

    // Get the scores
    React.useEffect(() => {
        if (!selectedRankingSet) {
            return;
        }

        const scoresRef = doc(firestore, "clients", currentUser.company.company, "pairwise_ranking", selectedRankingSet, "scores", "scores");
        onSnapshot(scoresRef, (snapshot) => {
            if (snapshot.data()) {
                setScores(snapshot.data());
            }
        });
    }, [selectedRankingSet]);

    // If no competitors are loaded, get first example
    React.useEffect(() => {
        if (!loadingCompetitors.current && (!competitors || Object.keys(competitors).length===0)) {
            loadingCompetitors.current = true;
            getNewCompetitors();
        }
    }, [scores, competitors]);

    function handleBalePick(winnerIndex) {
        /* Handle when a winner is picked. WinnerIndex is 0 or 1. If -1 it is a draw */
        if (!competitors || Object.keys(competitors).length === 0) {
            return;
        }

        let scoreUpdates = {};
        if (winnerIndex === -1) {
            // Process a draw
            const player1 = Object.keys(competitors)[0];
            const player2 = Object.keys(competitors)[1];
            scoreUpdates = getScoreUpdateDraw(player1, player2);
        } else {
            // Process a win/loss
            const winner = Object.keys(competitors)[winnerIndex];
            const looser = Object.keys(competitors)[1-winnerIndex];
            scoreUpdates = getScoreUpdateWin(winner, looser);
        }

        // Update the scores
        const scoresRef = doc(firestore, "clients", currentUser.company.company, "pairwise_ranking", selectedRankingSet, "scores", "scores");
        setDoc(scoresRef, scoreUpdates, {"merge": true});

        // Save the game
        const gameRef = doc(firestore, "clients", currentUser.company.company, "pairwise_ranking", selectedRankingSet, "games", format(new Date(), "yyyy-MM-dd_hh-mm-ss"));
        setDoc(gameRef, {
            timestamp: serverTimestamp(), 
            user: currentUser.uid, 
            players: [Object.keys(competitors)[0], Object.keys(competitors)[1]],
            winner: winnerIndex,
            scoreUpdate: scoreUpdates,
        });

        setCompetitors({});
    }
    function getScoreUpdateWin(winner, looser) {
        /* Return the update-dict for the case of a win/loose */
        // Update ELO
        const elo = new EloRank();
        const expectedScoreWinner = elo.getExpected(scores[winner].elo, scores[looser].elo);
        const expectedScoreLooser = elo.getExpected(scores[looser].elo, scores[winner].elo);
        const newEloWinner = elo.updateRating(expectedScoreWinner, 1, scores[winner].elo);
        const newEloLooser = elo.updateRating(expectedScoreLooser, 0, scores[looser].elo);

        // Update TrueSkill
        const winnerTrueskill = new Rating(scores[winner].trueskill.mu, scores[winner].trueskill.sigma);
        const looserTrueskill = new Rating(scores[looser].trueskill.mu, scores[looser].trueskill.sigma);
        const [newTrueskillWinner, newTrueskillLooser] = rate_1vs1(winnerTrueskill, looserTrueskill);

        const scoreUpdates = {
            [winner]: {
                "elo": newEloWinner,
                "trueskill": {
                    "mu": newTrueskillWinner.mu,
                    "sigma": newTrueskillWinner.sigma,
                },
                "n_plays": increment(1),
                "n_wins": increment(1),
            }, 
            [looser]: {
                "elo": newEloLooser,
                "trueskill": {
                    "mu": newTrueskillLooser.mu,
                    "sigma": newTrueskillLooser.sigma,
                },
                "n_plays": increment(1),
                "n_losses": increment(1),
            }
        };
        return scoreUpdates;
    }

    function getScoreUpdateDraw(player1, player2) {
        /* Return the update-dict for the case of a draw */
        // Update ELO
        const elo = new EloRank();
        const expectedScorePlayer1 = elo.getExpected(scores[player1].elo, scores[player2].elo);
        const expectedScorePlayer2 = elo.getExpected(scores[player2].elo, scores[player1].elo);
        const newEloPlayer1 = elo.updateRating(expectedScorePlayer1, 0.5, scores[player1].elo);
        const newEloPlayer2 = elo.updateRating(expectedScorePlayer2, 0.5, scores[player2].elo);

        // Update TrueSkill
        const player1Trueskill = new Rating(scores[player1].trueskill.mu, scores[player1].trueskill.sigma);
        const player2Trueskill = new Rating(scores[player2].trueskill.mu, scores[player2].trueskill.sigma);
        const [newTrueskillPlayer1, newTrueskillPlayer2] = rate_1vs1(player1Trueskill, player2Trueskill, true);

        const scoreUpdates = {
            [player1]: {
                "elo": newEloPlayer1,
                "trueskill": {
                    "mu": newTrueskillPlayer1.mu,
                    "sigma": newTrueskillPlayer1.sigma,
                },
                "n_plays": increment(1),
            }, 
            [player2]: {
                "elo": newEloPlayer2,
                "trueskill": {
                    "mu": newTrueskillPlayer2.mu,
                    "sigma": newTrueskillPlayer2.sigma,
                },
                "n_plays": increment(1),
            }
        };
        return scoreUpdates;
    }

    async function getNewCompetitors() {
        /* Run Matchmaking: Load next two bales to compare */
        if (!scores || Object.keys(scores).length < 2) {
            loadingCompetitors.current = false;
            return;
        }

        // Sort according to number of games played
        const idsSorted = Object.keys(scores).sort((a, b) => {return scores[a].n_plays - scores[b].n_plays});
        const idsLowPlays = idsSorted.slice(0, Math.min(20, idsSorted.length));

        // Pick two random observations amongst 10 with least games played
        const idsTrueskillSorted = idsLowPlays.sort((a, b) => {return scores[a].trueskill.mu - scores[b].trueskill.mu});
        const randomId = Math.floor(Math.random()*(idsLowPlays.length-2));
        const idsSelected = idsTrueskillSorted.slice(randomId, randomId+2);
        
        // Get observations from firebase
        const docSnapshot1 = await getDoc(doc(firestore, "clients", currentUser.company.company, "observations", idsSelected[0]));
        const docSnapshot2 = await getDoc(doc(firestore, "clients", currentUser.company.company, "observations", idsSelected[1]));
        if (!docSnapshot1.exists || !docSnapshot2.exists) {
            loadingCompetitors.current = false;
            return;
        }

        const imgUrl1 = await fetchImageUrl(docSnapshot1.data().image_url);
        const imgUrl2 = await fetchImageUrl(docSnapshot2.data().image_url);

        setCompetitors({
            [docSnapshot1.id]: {data: docSnapshot1.data(), img: imgUrl1},
            [docSnapshot2.id]: {data: docSnapshot2.data(), img: imgUrl2},
        });

        loadingCompetitors.current = false;
    }

    return (
        <div className="labeling-pairwise-matching">
            <select className="default-dropdown" value={currentUser.company.company} onChange={(e) => {setSelectedRankingSet(e.target.value)}}>
                { Object.keys(rankingSets).map(id => {
                    return <option key={id} value={id}>{rankingSets[id]?.name}</option>
                }) }
            </select>
            <h3>{rankingSets[selectedRankingSet]?.message}</h3>
            <div className="matching-image-field">
                <div className="competitor-square"><div className="img-container"><img src={Object.values(competitors)?.[0]?.img} onClick={() => {handleBalePick(0)}}/></div></div>
                <div className="competitor-square"><div className="img-container"><img src={Object.values(competitors)?.[1]?.img} onClick={() => {handleBalePick(1)}}/></div></div>
            </div>
            <button className="default-button" onClick={() => {handleBalePick(-1)}}>Unentschieden</button>
        </div>
    )
}