import React from 'react'
import { useTranslation } from "react-i18next";

import "./ObservationGallery.scss"
import { fetchImageUrl } from "../../utils/ImgUtils.js"
import { AuthContext } from '../../context/AuthContext';

// Import Icons
import forward_icon_white from "../../assets/icons/white/forward_small.png"
import back_icon_white from "../../assets/icons/white/back_small.png"
import alert_icon from '../../assets/icons/alert.png';

export default function ObservationGallery({ observation, getNext, getPrevious }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [authImageUrl, setAuthImageUrl] = React.useState("");
    const [flagObservation, setFlagObservation] = React.useState(false);
    const [zoomIn, setZoomIn] = React.useState(false);
    const imgRef = React.useRef(null);

    const FLAGGING_ACCURACY_THRESHOLD = 0.8;

    React.useEffect(() => {
        if (!observation) {
            return;
        }

        fetchImageUrl(observation.image_url).then((url) => {
            setAuthImageUrl( url );
        })
    }, [observation.image_url])

    React.useEffect(() => {
        function handleKeyDown(event) {
            /* Check keys for bale-scrolling */
            // Check for left arrow key
            if (event.keyCode === 37) {
                getPrevious(observation)
            }
    
            // Check for right arrow key
            if (event.keyCode === 39) {
                getNext(observation);
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => { document.removeEventListener('keydown', handleKeyDown); }
    })

    React.useEffect(() => {
        function checkFlagObservation() {
            /* Check if the observation needs to be flagged for low accuracy prediction. Only for labelers and admins */
            let flag = false;
            if (currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                "flag_low_accuracy" in currentUser.company && "predicted_labels" in observation) {
                for(const labelset of currentUser.company.flag_low_accuracy) {
                    if (labelset in observation.predicted_labels && observation.predicted_labels[labelset].cumulative_accuracy < FLAGGING_ACCURACY_THRESHOLD) {
                        flag = true;
                    }
                }
            }
            setFlagObservation(flag);
        }
        checkFlagObservation();
    });

    const handleMouseMove = (e) => {
        /* See how the mouse moved in order to pan zoomed image */
        const { left, top, width, height } = imgRef.current.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - window.scrollY - top) / height) * 100;
        imgRef.current.style.transformOrigin = `${x}% ${y}%`;
    };
    
    return (
        <div className="observation-gallery">
            <div className="observation-gallery--next" onClick={() => getPrevious(observation)}>
                <img className="observation-gallery--button-icon" src={back_icon_white}/>
            </div>
            <div className="observation-gallery--img-container" onMouseMove={handleMouseMove}>
                <img 
                    ref={imgRef}
                    className={`observation-gallery--img ${zoomIn ? 'zoomed' : ''}`}  
                    src={authImageUrl} 
                    onClick={() => setZoomIn((prev) => !prev)}
                />
                {flagObservation && <div className="observation-gallery--alert">
                    <img className="observation-gallery--alert-icon" src={alert_icon}/>
                    <p>{t("alert_low_accuracy")}</p>
                </div>}
            </div>
            <div className="observation-gallery--next" onClick={() => getNext(observation)}>
                <img className="observation-gallery--button-icon" src={forward_icon_white}/>
            </div>
        </div>
    )
}