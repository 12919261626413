import React from 'react'
import './MaterialFlowScrollInterface.scss';
import { useTranslation } from "react-i18next";
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

import search_icon from '../../assets/icons/lightblue/detail.png'
import arrow_icon from '../../assets/icons/white/back_small.png'

export default function MaterialFlowScrollInterface({ scrollTimestamp, setScrollTimestamp, setOffsetToTimestamp, jumpToNextObservation, interfaceBottom, positioning }) {
    const { i18n, t } = useTranslation();

    return (
        <div className="material-flow-scroll-interface" 
            style={{
                bottom: interfaceBottom, 
                transform: positioning === "top" ? "translate(-50%, -1.5rem)" : positioning === "bottom" ? "translate(-50%, 2rem)" : "translate(-50%, 0)"
            }}
        >
            <button className="jump-button" onClick={() => jumpToNextObservation("left")}><img src={arrow_icon}/></button>
            <div className="date-time-picker-container">
                <DateTimePicker 
                    className="date-time-picker" 
                    calendarClassName="date-time-picker-calendar"
                    onChange={(timestamp) => {setScrollTimestamp(timestamp)}} 
                    value={scrollTimestamp}
                    disableClock={true}
                    clearIcon={null}
                    calendarIcon={null}
                    format={"dd.MM.y  HH:mm:ss"}
                />
                <img className="date-time-picker-search-button" src={search_icon} onClick={() => {setOffsetToTimestamp(scrollTimestamp)}}/>
            </div>
            <button className="jump-button" onClick={() => jumpToNextObservation("right")}><img src={arrow_icon} style={{transform: 'rotate(180deg)'}}/></button>
        </div>
    );
}