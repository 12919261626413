import React, { useState } from 'react';
import './AdminSettingsModal.scss';

import { useTranslation } from "react-i18next";

import close_icon from "../../assets/icons/close_black.svg"

const ConfirmationModal = ({ title, message, handleSubmit, handleClose }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className="admin-modal">
      <div className="modal-content modal-small">
        <div className="modal-header">
          <h2>{title}</h2>
          <div className="modal-close-button" onClick={handleClose}>
              <img className="modal-close-button-icon" src={close_icon}/>
          </div>
        </div>

        <p>{message}</p>

        <div className="save-delete-buttons">
            <button className="settings-modal-button light-button" onClick={() => {handleSubmit(); handleClose();}}>{t("settings_yes")}</button>
            <button className="settings-modal-button light-button" onClick={handleClose}>{t("settings_cancel")}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
