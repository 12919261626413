import React from "react"
import "./DayReviewCard.scss"
import { AuthContext } from '../../../context/AuthContext';
import { firestore } from "../../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { useTable } from 'react-table'

import { collection, query, where, onSnapshot, orderBy, limit } from "firebase/firestore";
import { format, addHours } from "date-fns";

import ObservationSquare from "../../ObservationSquare/ObservationSquare";
import OverflowSquare from "../../ObservationSquare/OverflowSquare";
import FullscreenObservationModal from "../../FullscreenObservationModal/FullscreenObservationModal";

export default function DayReviewCard({ cardData, payload }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);

    const [ dataHourly, setDataHourly ] = React.useState({});
    const [ showFullscreenObservation, setShowFullscreenObservation ] = React.useState(false);
    const snapshotUnsubscribes = React.useRef([]);

    const STD_OBSERVATION_LOADING_LIMIT = 20;
    const MAX_OBSERVATION_LOADING_LIMIT = 1000;

    function getHourlyData() {
        for (const unsubscribe of snapshotUnsubscribes.current) {
            unsubscribe();
        }
        const newUnsubscribes = [];

        let startTimestamp = cardData.timeframe.startDate;
        let index = 0;
        while (startTimestamp < cardData.timeframe.endDate) {
            const endTimestamp = addHours(startTimestamp, 1);
            
            const newUnsubscribe = getObservations(startTimestamp, endTimestamp, index, STD_OBSERVATION_LOADING_LIMIT);
            newUnsubscribes.push(() => newUnsubscribe());

            index += 1;
            startTimestamp = addHours(startTimestamp, 1);
        }
        snapshotUnsubscribes.current = newUnsubscribes;
    }

    function getObservations(startTimestamp, endTimestamp, index, loadLimit) {
        let observationsQuery = query(collection(firestore, "clients", currentUser.company.company, "observations"), 
            where("device", "==", currentUser.device_id),
            where("timestamp", ">=", startTimestamp), 
            where("timestamp", "<", endTimestamp),
            orderBy("timestamp", "asc"), 
            limit(loadLimit));

        const filterObservationClasses = currentUser.devices?.[currentUser.device_id]?.filter_observation_classes;
        if (filterObservationClasses && filterObservationClasses.length > 0) {
            observationsQuery = query(observationsQuery, where("object_type", "in", filterObservationClasses));
        }

        const newUnsubscribe = onSnapshot(observationsQuery, (observationSnapshot) => {
            const observations = [];
            observationSnapshot.forEach((docSnapshot) => {
                observations.push({...docSnapshot.data(), doc_id: docSnapshot.id});
            })
            const timeString = format(startTimestamp, "HH:mm");

            setDataHourly((prevDataHourly) => {
                return {...prevDataHourly, 
                    [timeString]: {
                        observations: observations, 
                        numObservations: prevDataHourly[timeString]?.numObservations || 0, 
                        time: timeString, 
                        startTimestamp: startTimestamp, 
                        endTimestamp: endTimestamp, 
                        index: index,
                    }
                }
            });
        });

        return newUnsubscribe;
    }

    React.useEffect(() => {
        getHourlyData();
        if (showFullscreenObservation) {
            const index = payload.findIndex(obj => obj.doc_id === showFullscreenObservation.doc_id)
            setShowFullscreenObservation(payload[index]);
        }
    }, [cardData.timeframe.startDate.toString(), cardData.shiftTime]);

    function setNumObservations() {
        if (!payload || !payload['yValuesDict']) {
            return;
        }
        setDataHourly((prevDataHourly) => {
            return Object.entries(prevDataHourly).reduce((acc, [timeString, yValue], index) => {
                acc[timeString] = {
                    ...yValue,
                    numObservations: Object.values(payload['yValuesDict']['productGroup']).reduce((acc, materialAggregated) => {return acc + materialAggregated[yValue.index] } , 0),
                };
                return acc;
            }, {});
        })
    }
    React.useEffect(() => {
        setNumObservations();
    }, [payload]);

    function getObservationsList() {
        return Object.values(dataHourly).reduce((aggregation, data) => {
            return [...aggregation, ...data.observations];
        }, [])
    }
    function getNextObservation(currentObservation) {
        const observationsList = getObservationsList();
        const nextIndex = observationsList.findIndex(obj => obj.doc_id === currentObservation.doc_id) + 1
        setShowFullscreenObservation( observationsList[Math.min(nextIndex, observationsList.length-1)] )
    };
    function getPreviousObservation (currentObservation) {
        const observationsList = getObservationsList();
        const previousIndex = observationsList.findIndex(obj => obj.doc_id === currentObservation.doc_id) - 1;
        setShowFullscreenObservation( observationsList[Math.max(previousIndex, 0)] )
    };

    function handleLoadOverflow(row) {
        snapshotUnsubscribes.current[row.index]();
        const newUnsubscribe = getObservations(row.startTimestamp, row.endTimestamp, row.index, MAX_OBSERVATION_LOADING_LIMIT);
        snapshotUnsubscribes.current = snapshotUnsubscribes.current.map((unsubscribe, index) => {
            return index === row.index ? newUnsubscribe : unsubscribe;
        })
    }

    const COLUMNS = [
        {
            Header: t("dayreview_time"),
            accessor: "time"
        },
        {
            Header: t("dayreview_bales"),
            accessor: (row) => {
                const numOverflow = row.numObservations - row.observations.length;
                return <div className="dayreviewcard--bales-cell">
                    {row.observations.map(observation => <div 
                        onClick={() => setShowFullscreenObservation(observation)}
                        key={observation.image_url}>
                            <ObservationSquare observation={observation} />
                    </div>)}
                    {numOverflow > 0 && <OverflowSquare numOverflow={numOverflow} onLoadOverflow={() => handleLoadOverflow(row)}/>}
                </div>
            },
            width: '100%'
        },
        {
            Header: t("dayreview_nbales"),
            accessor: "numObservations",
        }
    ]
    const columns = React.useMemo(() => COLUMNS, [payload, i18n.language]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: Object.values(dataHourly).sort((a, b) => a.index - b.index) })

    return (
        <div className="dayreviewcard-body">
            <div className="table-wrapper">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, columnIndex) => (
                                    <th {...column.getHeaderProps()} style={{width: column.width}}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {showFullscreenObservation && <FullscreenObservationModal 
                observation={showFullscreenObservation}
                closeFullscreenModal={() => setShowFullscreenObservation(false)}
                getNext={(observation) => {getNextObservation(observation)}}
                getPrevious={(observation) => {getPreviousObservation(observation)}}
            /> }
        </div>
    )
}