import React from "react";
import "./Deliveries.scss";

import { useParams } from 'react-router-dom';
import { collection, query, where, onSnapshot, Timestamp, doc } from "firebase/firestore";
import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { startOfMonth, endOfMonth } from "date-fns";

// Importing custom pages
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Navbar/Sidebar';
import TimeframeFilter from "../../components/Filters/TimeframeFilter";
import MaterialFilter from "../../components/Filters/MaterialFilter";
import SupplierFilter from "../../components/Filters/SupplierFilter";
import Card from "../../components/Card/Card";

// Importing logos
import card_icon_green from '../../assets/icons/gray/plastics_green.svg';

export default function Deliveries() {
    const { i18n, t } = useTranslation();
    const { deliveryId } = useParams();
    const { currentUser, labelsets } = React.useContext(AuthContext);

    const [deliveries, setDeliveries] = React.useState({});
    const [deliveriesFiltered, setDeliveriesFiltered] = React.useState({});
    const [activeDelivery, setActiveDelivery] = React.useState(null);
    const [nBales, setNBales] = React.useState(0);
    const [totalWeight, setTotalWeight] = React.useState(0);
    const [materialChartData, setMaterialChartData] = React.useState([]);
    const [timeframe, setTimeframe] = React.useState({startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()), label: "timeframe_this_month"});
    const [materialFilter, setMaterialFilter] = React.useState(undefined);
    const [supplierFilter, setSupplierFilter] = React.useState("");
    const [reclamationFilter, setReclamationFilter] = React.useState(false);
    const [suppliers, setSuppliers] = React.useState([]);
    const [deliveriesSettings, setDeliveriesSettings] = React.useState({});
    const unsubscribe = React.useRef(null);
    const unsubscribeDeliverySettings = React.useRef(null);

    React.useEffect(() => {
        if (deliveryId) {
            setActiveDelivery(deliveryId);
        }
    }, [deliveryId]);

    function getDeliverySettings() {
        /** Load delivery settings from database */
        // Unsubscribe from previous query
        if (unsubscribeDeliverySettings.current) unsubscribeDeliverySettings.current();

        // Fetch Delivery Settings
        const deliverySettingsDocRef = doc(firestore, "clients", currentUser.company.company, "client_settings", "deliveries");
        const newUnsubscribe = onSnapshot(deliverySettingsDocRef, (snapshot) => {
            if (!snapshot.exists || !snapshot.data()) return;
            setDeliveriesSettings(snapshot.data());
        });
        unsubscribeDeliverySettings.current = () => newUnsubscribe();
    }
    React.useEffect(() => {
        getDeliverySettings();
    }, []);

    function getMaterialChartData() {
        /** Create data-array for piechart containing material weight distribution */
        if (!deliveries || !labelsets || Object.keys(labelsets).length === 0) return;

        const materialWeights = {};
        for (const delivery of Object.values(deliveries)) {
            const n_bales = delivery.bales ? Object.keys(delivery.bales).length : 0;
            if (delivery.base_labels.materials in materialWeights) {
                materialWeights[delivery.base_labels.materials] += Number(n_bales);
            } else {
                materialWeights[delivery.base_labels.materials] = Number(n_bales);
            }
        }

        const newMaterialChartData = [];
        for (const material of Object.keys(materialWeights)) {
            newMaterialChartData.push({
                id: material,
                name: labelsets['materials']['labels'][material].full_name,
                value: materialWeights[material]
            })
        }
        setMaterialChartData(newMaterialChartData);
    }
    function getSuppliers() {
        if (!deliveries) return;

        const newSuppliers = new Set();
        Object.values(deliveries).forEach(delivery => {newSuppliers.add(delivery.supplier)});
        let newSuppliersList = Array.from(newSuppliers);
        newSuppliersList = newSuppliersList.sort()
        setSuppliers(newSuppliersList);
    }
    React.useEffect(() => {
        getMaterialChartData();
        getSuppliers();
    }, [JSON.stringify(Object.keys(deliveries)), labelsets]);

    // Load deliveries data (with live updates)
    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribe.current) unsubscribe.current();

        // Fetch Deliveries
        const deliveriesQuery = query(collection(firestore, "clients", currentUser.company.company, "deliveries"), 
            where("date", ">=", Timestamp.fromDate(timeframe.startDate)),
            where("date", "<=", Timestamp.fromDate(timeframe.endDate)),
        );
        const newUnsubscribe = onSnapshot(deliveriesQuery, (snapshot) => {
            const deliveriesUpdate = {};
            snapshot.forEach((doc) => {
                deliveriesUpdate[doc.id] = {
                    ...doc.data(), 
                    date: doc.data().date.toDate()
                };
            });
            setDeliveries(deliveriesUpdate);
        });
        unsubscribe.current = () => newUnsubscribe();
    }, [ currentUser.company, timeframe ]);

    async function filterDeliveries() {
        let deliveriesFilteredNew = deliveries;
        if (materialFilter) {
            deliveriesFilteredNew = Object.keys(deliveries).reduce((filtered, id) => {
                if (deliveries[id].base_labels.materials === materialFilter) filtered[id] = deliveries[id];
                return filtered;
            }, {});
        }
        if (supplierFilter) {
            deliveriesFilteredNew = Object.keys(deliveriesFilteredNew).reduce((filtered, id) => {
                if (deliveriesFilteredNew[id].supplier === supplierFilter) filtered[id] = deliveriesFilteredNew[id];
                return filtered;
            }, {});
        }
        if (reclamationFilter) {
            deliveriesFilteredNew = Object.keys(deliveriesFilteredNew).reduce((filtered, id) => {
                if (deliveriesFilteredNew[id]?.reclamation_data?.reclamation_status === 'confirmed') filtered[id] = deliveriesFilteredNew[id];
                return filtered;
            }, {});
        }

        setDeliveriesFiltered(deliveriesFilteredNew);
        setNBales(Object.values(deliveriesFilteredNew).reduce((a, b) => a + (b.n_bales ?? b.bales ? Object.keys(b.bales).length : 0), 0));
        setTotalWeight(Object.values(deliveriesFilteredNew).reduce((a, b) => a + (b?.weighingSlipData?.weight_kg_netto_2 ? parseInt(b?.weighingSlipData?.weight_kg_netto_2) : 0), 0));
    }
    React.useEffect(() => {
        filterDeliveries();
    }, [deliveries, materialFilter, supplierFilter, reclamationFilter, activeDelivery===null])

    return (
        <div className="deliveries">
            <Sidebar />
            <div className="deliveries--right">
                <Navbar 
                    title={`${currentUser.company.company_displayname} ${t("sidebar_deliveries")}`}
                />
                <div className="deliveries--filters">
                    <TimeframeFilter 
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                    />
                    <MaterialFilter 
                        materials={materialChartData}
                        setMaterialFilter={setMaterialFilter}
                    />
                    <SupplierFilter 
                        suppliers={suppliers}
                        setSupplierFilter={setSupplierFilter}
                        deliveriesSettings={deliveriesSettings}
                    />
                    <div className="deliveries-filter-reclamation">
                        <label htmlFor="reclamation">{t("delivery_filter_reclamations")}:</label>
                        <input 
                            type="checkbox" 
                            id="reclamation" 
                            name="reclamation" 
                            checked={reclamationFilter}
                            onChange={(e) => setReclamationFilter(e.target.checked)}
                        />
                    </div>
                </div>
                <div className="deliveries--cards">
                    <Card
                        cardClassName="deliveriestablecard"
                        cardData={{
                            ctype: 'DeliveriesTableCard',
                            icon: <img src={card_icon_green} />,
                            title: t("deliveries_card_title"),
                        }}
                        payload={{
                            deliveries: deliveriesFiltered,
                            activeDelivery: activeDelivery,
                            setActiveDelivery: setActiveDelivery,
                            deliveriesSettings: deliveriesSettings,
                        }}
                    />

                    {activeDelivery && <Card
                        cardClassName="deliverycard"
                        cardData={{
                            ctype: 'DeliveryCard',
                            icon: <img src={card_icon_green} />,
                            title: `${t("delivery_title")} #${activeDelivery}`,
                        }}
                        payload={{
                            deliveryId: activeDelivery,
                            deliveriesSettings: deliveriesSettings,
                        }}
                    />}

                    <Card 
                        cardData={{
                            ctype: 'BasicCard',
                            icon: <img src={card_icon_green} />,
                            title: t("bales_received_card_title"),
                            subtitle: `${materialFilter ? labelsets['materials']['labels'][materialFilter].full_name : "Total"}, ${t(timeframe.label)}`,
                            count: nBales
                        }}
                    />

                    <Card 
                        cardData={{
                            ctype: 'PiechartCard',
                            icon: <img src={card_icon_green} />,
                            title: t("material_piechart_card_title"),
                            subtitle: `Total, ${t(timeframe.label)}`,
                        }}
                        payload={{
                            chartData: materialChartData.filter(mat => {return mat.value > 0}),
                            tooltipFormatting: (value, name, props) => `${value} bales`
                        }}
                    />

                    <Card 
                        cardData={{
                            ctype: 'BasicCard',
                            icon: <img src={card_icon_green} />,
                            title: t("material_received_card_title"),
                            subtitle: `${materialFilter ? labelsets['materials']['labels'][materialFilter].full_name : "Total"}, ${t(timeframe.label)}`,
                            count: `${(totalWeight/1000).toFixed(1)} t`
                        }}
                    />
                </div>
            </div>
        </div>
    );
}