import React from 'react'
import './PropertyField.scss'
import { format } from 'date-fns'
import { debounce } from 'lodash';

import MultiSelectDropdown from '../MultiSelectDropdown/MultiSelectDropdown';

export default function PropertyField(props) {
    const [ value, setValue ] = React.useState(props.value);
    const inputRef = React.useRef(null);

    // Update value when props change
    React.useEffect(() => {
        setValue(props.value ?? "");
    }, [props.value]);

    function datetimeToString(datetime, seconds=false) {
        /** Convert a datetime object to string */
        if (seconds) {
            return format(datetime, "yyyy-MM-dd'T'HH:mm:ss")
        } else {
            return format(datetime, "yyyy-MM-dd'T'HH:mm")
        }
    }

    function handleClick() {
        /* Focus on the input element when clicking on any place on the field */
        if (props.editable && inputRef.current) {
            inputRef.current.focus();
        }
        if (props.onClick){
            props.onClick();
        }
    }

    function handleChangesWithDebouncing(event) {
        /* 
        Handle changes in input fields with debouncing.
        Update state immediately, but only trigger the update function (for firebase) after 1 second.
        */
        setValue(event.target.value);
        uploadChanges(event);
    }
    
    const uploadChanges = React.useCallback(debounce((event) => {
        // Update value with a debouncing timer.
        props.handleChange(event);
    }, 700), [props.handleChange]);

    function renderView() {
        return (
            <p className="property--value">{value}</p>
        )
    }

    function renderInput() {
        return (
            <input className="property--value"
                type={props.type ? props.type : "text"}
                onChange={handleChangesWithDebouncing}
                name={props.name}
                value={value ? value : ""}
                ref={inputRef}
            /> 
        )
    }

    function renderDatetimeLocal() {
        return (
            <input className="property--value"
                type="datetime-local"
                onChange={(e) => {handleChangesWithDebouncing({...e, target:{"name":props.name, "value":new Date(e.target.value)}})}}
                name={props.name}
                value={value ? datetimeToString(value) : ""}
                ref={inputRef}
            /> 
        )
    }

    function renderTextArea() {
        return (
            <textarea className="property--value property--value-textarea"
                onChange={handleChangesWithDebouncing}
                name={props.name}
                value={value ? value : ""}
                ref={inputRef}
            /> 
        )
    }

    function renderSelect() {
        if (Object.keys(props.options).length > 1) {
            return (
                <select className="property--value"
                    onChange={handleChangesWithDebouncing}
                    name={props.name}
                    value={value}
                    ref={inputRef}
                >
                    {Object.entries(props.options).sort().map(([key, value]) => {
                        return (
                            <option key={key} value={key}>{value}</option>
                        )
                    }
                    )}
                </select>
            )
        } else {
            return (
                <p className="property--value">{props.options[value]}</p>
            )
        }
    }

    function renderMultipleChoiceSelect() {
        return (
            <MultiSelectDropdown 
                selectedOptions={value}
                options={props.options}
                onChange={(e) => {handleChangesWithDebouncing({"target":{"name":props.name, "value":e}})}}
                propertyFieldStyle={true}
            />
        )
    }

    function renderValueField() {
        switch (props.type) {
            case "textarea":
                return renderTextArea();
            case "text":
                return renderInput();
            case "number":
                return renderInput();
            case "date":
                return renderInput();
            case "datetime-local":
                return renderDatetimeLocal();
            case "select":
                return renderSelect();
            case "multiple-choice-select":
                return renderMultipleChoiceSelect();
            default:
                return renderView();
        }
    }

    return (
        <div className={props.highlight ? "property-field property--highlight" : "property-field"}
            style={{
                "gridColumn": `span ${props.grid_span ? props.grid_span : 1}`, 
                "zIndex": `${props.type === "multiple-choice-select" ? 100 : 0}`}}
            onClick={handleClick}
        >
            <img className="property--icon" src={props.icon} />
            <p className="property--title">{props.title}</p>
            { props.editable ? renderValueField() : <p className="property--value">{value}</p> }
        </div>
    )
}