import React, { useState } from 'react'

import './Dropdown.scss'

export default function Dropdown({ options, setOption, defaultOption, title }) {
    const [ selectedString, setSelectedString ] = React.useState(defaultOption.name)

    function setSelectedOption(option) {
        setSelectedString(option.name);
        setOption(option);
    }

    return (
        <div className="dropdown">
            <div className="dropdown-splitbutton">
                <button className="dropdown-splitbutton-text">{title}:</button>
                <button className="dropdown-splitbutton-button-large dropdown-splitbutton-button">{selectedString}</button>
            </div>
            <div className="dropdown-content">
                {options.map(option => {
                    return (
                        <a key={option.id} onClick={() => setSelectedOption(option)}>{option.name}</a>
                    )
                })}
            </div>
        </div>
    )
}