import { firestore } from "../context/FirebaseConfig";
import { collection, query, where, doc, getDoc, getDocs } from "firebase/firestore";

export async function fetchUserInfo(uid)  {
    const userInfo = {};

    // Fetch user company & language
    const userDocSnap = await getDoc(doc(firestore, "users", uid));
    if (!userDocSnap.exists()) {
        return;
    }
    userInfo["info"] = userDocSnap.data();
    const company = userDocSnap.data().company;

    // Fetch company display name
    const clientDocSnap = await getDoc(doc(firestore, "clients", company));
    if (clientDocSnap.exists()) {
        userInfo["company"] = clientDocSnap.data();
    }

    // Fetch device
    const deviceQuery = query(collection(firestore, "clients", company, "devices"), where("client", "==", company));
    const querySnapshot = await getDocs(deviceQuery);
    if (querySnapshot.empty) {
        userInfo["device_id"] = null;
        return userInfo;
    }
    userInfo["devices"] = {};
    for (const doc of querySnapshot.docs) {
        userInfo["devices"][doc.id] = doc.data();
    }

    if (userInfo.info.selected_device_id && userInfo.info.selected_device_id in userInfo.devices) {
        userInfo["device_id"] = userInfo.info.selected_device_id;
    } else {
        userInfo["device_id"] = querySnapshot.docs[0].data().device_id;
    }

    return userInfo;
}

export async function fetchLabelsets(company) {
    const labelsets = {};
    const labelsetsSnapshot = await getDocs(collection(firestore, "clients", company, "labelsets"));
    labelsetsSnapshot.forEach((doc) => {
        labelsets[doc.id] = {...doc.data()};
    });
    
    return labelsets;
}

export async function fetchParametersConfig() {
    /* Fetch the structure of the device parameters and their default values */
    const snapshot = await getDoc(doc(firestore, "settings", "device_parameters_config"));
    if (!snapshot.exists()) {
        return;
    }
    return snapshot.data();
}