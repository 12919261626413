import React from "react";
import "./Reclamations.scss";

import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { collection, doc, query, onSnapshot } from "firebase/firestore";

// Importing logos
import card_icon_green from '../../assets/icons/gray/plastics_green.svg';
import card_icon_red from '../../assets/icons/gray/plastics_red.svg';

// Importing custom pages
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Navbar/Sidebar';
import Card from "../../components/Card/Card";

export default function Reclamations() {
    const { i18n, t } = useTranslation();
    const { reclamationId } = useParams();
    const { currentUser } = React.useContext(AuthContext);
    const [ reclamations, setReclamations ] = React.useState({});
    const [ activeReclamation, setActiveReclamation ] = React.useState(null);
    const [ reclamationSettings, setReclamationSettings ] = React.useState({});
    const unsubscribe = React.useRef(null);
    const unsubscribeSettings = React.useRef(null);

    // Load reclamation data (with live updates)
    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribe.current) unsubscribe.current();

        // Fetch Deliveries
        const reclamationsQuery = query(collection(firestore, "clients", currentUser.company.company, "reclamations"));
        const newUnsubscribe = onSnapshot(reclamationsQuery, (snapshot) => {
            const reclamationsUpdate = {};
            snapshot.forEach((doc) => {
                reclamationsUpdate[doc.id] = {
                    ...doc.data(), 
                    date: doc.data().date.toDate(),
                };
            });
            setReclamations(reclamationsUpdate);
        });
        unsubscribe.current = () => newUnsubscribe();

        // Fetch Reclamation Settings
        if (unsubscribeSettings.current) unsubscribeSettings.current();

        const settingsQuery = query(doc(firestore, "clients", currentUser.company.company, "client_settings", "reclamations"));
        const newSettingsUnsubscribe = onSnapshot(settingsQuery, (doc) => {
            setReclamationSettings(doc.data());
        });
        unsubscribeSettings.current = () => newSettingsUnsubscribe();
    }, []);

    // Set active reclamation if reclamationId is provided (from URL params)
    React.useEffect(() => {
        if (reclamationId) {
            setActiveReclamation(reclamationId);
        }
    }, [reclamationId]);

    return (
        <div className="reclamations">
            <Sidebar />
            <div className="reclamations--right">
                <Navbar 
                    title={`${currentUser.company.company_displayname} ${t("sidebar_reclamations")}`}
                />
                <div className="reclamations--cards">
                    <Card 
                        cardData = {{
                            ctype: "ReclamationsTableCard",
                            icon: <img src={card_icon_green} />,
                            title: t("reclamations_table_card_title"),
                            subtitle: "",
                        }}
                        payload = {{
                            reclamations: reclamations,
                            activeReclamation: activeReclamation,
                            setActiveReclamation: setActiveReclamation,
                            reclamationSettings: reclamationSettings,
                        }}
                    />

                    {activeReclamation && <Card
                        cardData = {{
                            ctype: "ReclamationCard",
                            icon: <img src={card_icon_red} />,
                            title: t("reclamation_card_title"),
                            subtitle: `${t("reclamation_nr")} ${reclamations[activeReclamation]?.reclamation_nr?.toString().padStart(4, '0')}`,
                        }}
                        payload = {{
                            reclamationId: activeReclamation,
                            reclamationSettings: reclamationSettings,
                        }}
                    />}
                </div>
            </div>
        </div>
    )
}