import React from 'react';
import Logo from '../assets/logos/logo_uc_white_blue.svg';
import Logo_dark from '../assets/logos/logo_uc_blues_on_white.svg';
const LoadingScreen = ({loading="Loading . . .", fullscreen=true, message="", logoColor="white"}) => {
  return (
    <>
      {fullscreen ?
        <div className='loading-screen-fullscreen'>
          <div className='spinning-logo-container'>
            <img src={logoColor==="white" ? Logo : Logo_dark} className="spinning-logo" />
          </div>
          <p>{loading}</p>
        </div>
        :
        <div className='loading-screen-small'>
          <div className='spinning-logo-container'>
            <img src={logoColor==="white" ? Logo : Logo_dark} className="spinning-logo" />
          </div>
          <p>{loading}</p>
          {message && <p>{message}</p>}
        </div>
      }
    </>
  )
}

export default LoadingScreen