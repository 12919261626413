import React, { useContext } from 'react'
import './Navbar.scss'
import { AuthContext } from '../../context/AuthContext';
import logout_icon from '../../assets/icons/darkblue/logout.png';
import { useTranslation } from "react-i18next";

// Firebase imports
import { signOut } from 'firebase/auth';
import { auth, firestore } from '../../context/FirebaseConfig';
import { doc, setDoc, getDocs, collection, updateDoc} from 'firebase/firestore'

export default function Navbar({ title }) {
    const { i18n, t } = useTranslation();
    const { currentUser, changeActiveDevice } = useContext(AuthContext);
    const [ clients, setClients ] = React.useState({});

    async function getClients() {
        const snapshots = await getDocs(collection(firestore, "clients"))
        const clientsNew = {};
        snapshots.forEach((doc) => {
            clientsNew[doc.id] = doc.data();
        });
        setClients(clientsNew);
    }
    React.useEffect(() => {
        getClients();
    }, []);

    // Handle language change
    const handleLanguageChange = async (e) => {
        const selectedLanguage = e.target.value;
        i18n.changeLanguage(selectedLanguage);
        // Update the language code for the user
        await setLanguageCode(currentUser, selectedLanguage);
    };
    const setLanguageCode = async (user, languageCode) => {
        if (user) {
            const userRef = doc(firestore, 'users', user.uid);
            await setDoc(userRef, { languageCode: languageCode }, { merge: true });
        }
    };

    async function handleClientChange(client) {
        await updateDoc(doc(firestore, "users", currentUser.uid), {"company": client});
        // Refresh the page to apply changes
        window.location.reload(false); 
    }
    
    return (
        <div className="navbar">
            <h1 className='title'>{title}</h1>
            <div className='user'>
                <span className="navbar-hello">{t("navbar_hello")}, {currentUser.info.displayName}</span>
                <img src={currentUser.info.photoURL} alt="" className='user_photo_img'/>
                <button onClick={() => signOut(auth)} style={{display:"none"}} id='logout_icon'/>
                <select className="navbar-dropdown"value={i18n.language} onChange={handleLanguageChange}>
                    <option value="en">EN</option>
                    <option value="de">DE</option>
                </select>
                {currentUser.info.user_flags && currentUser.info.user_flags.includes('admin') &&
                <select className="navbar-dropdown" value={currentUser.company.company} onChange={(e) => {handleClientChange(e.target.value)}}>
                    { Object.keys(clients).map(id => {
                        return <option key={id} value={id}>{clients[id].company_displayname}</option>
                    }) }
                </select>}
                {currentUser?.devices && Object.keys(currentUser.devices).length > 1 && 
                <select className="navbar-dropdown" value={currentUser.device_id} onChange={(e) => {changeActiveDevice(e.target.value)}}>
                    { Object.keys(currentUser.devices).map(id => {
                        const displayName = currentUser.devices[id]?.display_name ?? id;
                        return <option key={id} value={id}>{displayName}</option>
                    }) }
                </select>}
                <label htmlFor='logout_icon'>
                    <img src={logout_icon} alt="logout icon" className='logout_icon_img'/>
                </label>
            </div>
        </div>
    )
}