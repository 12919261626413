import React from "react"
import "./DeliveryImagePreview.scss"

import { fetchImageUrl } from "../../utils/ImgUtils.js"

export default function DeliveryImagePreview({deliveryImageData, displayBale=null, onClick}) {
    const [deliveryImageUrlAuth, setDeliveryImageUrlAuth] = React.useState(null);
    const [isDeliveryImageLoaded, setIsDeliveryImageLoaded] = React.useState(false);
    const [imagePositioning, setImagePositioning] = React.useState({ width: 0, height: 0, top: 0, left: 0, scale: 0 });
    const [bboxPositioning, setBboxPositioning] = React.useState({ left: 0, top: 0, width: 0, height: 0 });
    const wrapperRef = React.useRef(null);

    React.useEffect(() => {
        setIsDeliveryImageLoaded(false);
        if (!deliveryImageData || !deliveryImageData.image_URL) return;

        fetchImageUrl(deliveryImageData.image_URL).then((url) => {
            setDeliveryImageUrlAuth(url);
        })
    }, [deliveryImageData?.image_URL]);

    function handleOnImageLoad(e) {
        setIsDeliveryImageLoaded(true);

        const wrapperRect = wrapperRef.current.getBoundingClientRect();
        const wrapperWidth = wrapperRect.width;
        const wrapperHeight = wrapperRect.height;
        const imageAspectRatio = e.target.naturalWidth / e.target.naturalHeight;
        let width, height, top, left;
        if (imageAspectRatio > 14/10) {
            setImagePositioning({})
            width = wrapperWidth;
            height = wrapperWidth / imageAspectRatio;
            top = (wrapperHeight - height) / 2;
            left = 0;
        } else {
            width = wrapperHeight * imageAspectRatio;
            height = wrapperHeight;
            top = 0;
            left = (wrapperWidth - width) / 2;
        }
        const scale = width / e.target.naturalWidth;
        setImagePositioning({ width: width, height: height, top: top, left: left, scale: scale });
    }

    React.useEffect(() => {
        if (displayBale) {
            const [x1, y1, x2, y2, conf, label] = displayBale.bbox;
            setBboxPositioning({
                left: imagePositioning.left + x1 * imagePositioning.scale,
                top: imagePositioning.top + y1 * imagePositioning.scale,
                width: (x2 - x1) * imagePositioning.scale,
                height: (y2 - y1) * imagePositioning.scale
            });
        }
    }, [displayBale, imagePositioning]);

    return (
        <div className="delivery-image-preview" 
            ref={wrapperRef}
            onClick={onClick}
        >
            <div className="delivery-image-wrapper"
                style ={{
                    width: imagePositioning.width,
                    height: imagePositioning.height,
                    top: imagePositioning.top,
                    left: imagePositioning.left
                }}
            >
                <img className="delivery-image" 
                    src={deliveryImageUrlAuth}
                    onLoad={handleOnImageLoad}
                    style={{ display: isDeliveryImageLoaded ? 'block' : 'none' }}
                />

                {/* Show bboxes on image */}
                { displayBale && isDeliveryImageLoaded &&
                    <div className="bale-bbox" style={{
                        left: bboxPositioning.left + 'px',
                        top: bboxPositioning.top + 'px',
                        width: bboxPositioning.width + 'px',
                        height: bboxPositioning.height + 'px'
                    }} 
                    onClick={() => {}} />
                }
            </div>
        </div>
    )
}