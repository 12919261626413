import React from 'react'
import "./ImageSquare.scss"
import { fetchImageThumbnailUrl } from "../../utils/ImgUtils.js"

import alert_icon from "../../assets/icons/alert.png";

export default function ImageSquare({ imageUrl, setGreyedOut=false, setIcons=[] }) {
    const [baleImg, setBaleImg] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        fetchImageThumbnailUrl(imageUrl).then((url) => {
            setBaleImg(url);
        })
    }, [imageUrl]);

    return (
        <div className={"bale_square"}>
            { baleImg && <img className={`bale_square--img ${setGreyedOut && "greyed-out"}`}
                src={baleImg}
                onLoad={() => setIsLoaded(true)}
                style={{ display: isLoaded ? 'block' : 'none' }}
            /> }
            { setIcons && 
            <div className="bale_square--icons-container">
                { setIcons.map((icon) => {
                    return <img className="bale_square--icon" src={icon} />
                }) }
            </div> }
        </div>
    )
}