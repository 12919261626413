import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Importing custom pages
import Login from './pages/Login';
import Overview from './pages/Overview/Overview';
import LoadingScreen from './components/LoadingScreen';
import NoRole from './pages/NoRole/NoRole';
import Deliveries from './pages/Deliveries/Deliveries';
import PlantFeed from './pages/PlantFeed/PlantFeed';
import LiveView from './pages/LiveView/LiveView';
import Reclamations from './pages/Reclamations/Reclamations';
import AdminSettings from './pages/AdminSettings/AdminSettings';
import Labeling from './pages/Labeling/Labeling';
import MaterialFlowPage from './pages/MaterialFlow/MaterialFlowPage';

// Importing context provider. 
import { AuthContext } from './context/AuthContext';

function App() {

  // finding the current user
  const { authUser, currentUser, userLoaded } = useContext(AuthContext);

  // Creating a protected route to only allow logged-in users to access the dashboard. 
  const ProtectedRoute = ({children}) => {
    if(!authUser){
      return <Navigate to="/login"/>
    }
    // else
    return children
  }

  const LimitedAccessRoute = ({acceptedUserFlags, children}) => {
    if (currentUser && currentUser.info && currentUser.info.user_flags && 
        acceptedUserFlags.some(flag => currentUser.info.user_flags.includes(flag))) {
      return children;
    }
    return <Navigate to="/"/>
  }

  if (authUser && !userLoaded) {
    return (
      <LoadingScreen />
    )
  }

  const preferredHomePage = 
  !(currentUser && currentUser.info && currentUser.info.user_flags) ? 'no-role' :
  ['admin','manager','demo'].some(flag => currentUser.info.user_flags.includes(flag)) ? 'overview' :
  currentUser.info.user_flags.includes('labeler') ? 'labeling' : 
  'no-role'

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Navigate to={preferredHomePage}/>} />
          <Route path="login" element={<Login/>} />
          <Route path="loading" element={<ProtectedRoute><LoadingScreen /></ProtectedRoute>} />
          <Route path="no-role" element={<ProtectedRoute><NoRole/></ProtectedRoute>} />
          <Route path="overview" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','manager']}><Overview/></LimitedAccessRoute></ProtectedRoute>}/>
          <Route path="deliveries" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','manager']}><Deliveries/></LimitedAccessRoute></ProtectedRoute>}/>
          <Route path="deliveries/:deliveryId" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','manager']}><Deliveries/></LimitedAccessRoute></ProtectedRoute>}/>
          <Route path="plant-feed" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','labeler','manager']}><PlantFeed/></LimitedAccessRoute></ProtectedRoute>}/>
          <Route path="live-view" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin']}><LiveView/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="reclamations" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['beta']}><Reclamations/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="reclamations/:reclamationId" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','manager']}><Reclamations/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="labeling" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','labeler','manager']}><Labeling/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="admin-settings" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin']}><AdminSettings/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="material-flow" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','manager','labeler']}><MaterialFlowPage/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="material-flow/:timestamp" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','manager','labeler']}><MaterialFlowPage/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="observation/:observationId" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','manager']}><Overview/></LimitedAccessRoute></ProtectedRoute>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
