import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import './Dropdown.scss';

export default function TopicFilter({ topics, setTopicFilter }) {
    const { t } = useTranslation();
    const [selectedTopic, setSelectedTopic] = useState('');

    function handleTopicChange(topic) {
        setSelectedTopic(topic);
        setTopicFilter(topic.value);
    }

    return (
        <div className="dropdown">
            <div className="dropdown-splitbutton">
                <button className="dropdown-splitbutton-text">{t("live_view_topic_filter_title")}:</button>
                <button className="dropdown-splitbutton-button-large dropdown-splitbutton-button">
                    {selectedTopic ? selectedTopic.display_name : t("live_view_topic_filter_select")}
                </button>
            </div>
            <div className="dropdown-content">
                {topics.map(topic => (
                    <a key={topic.id} onClick={() => handleTopicChange(topic)}>{topic.display_name}</a>
                ))}
            </div>
        </div>
    );
}