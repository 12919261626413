import React from "react";
import "./ReclamationCard.scss";

import { AuthContext } from '../../../context/AuthContext';
import { firestore, functions } from "../../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { doc, query, onSnapshot, setDoc, deleteField } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Modal, Button } from 'react-bootstrap';
import { format } from "date-fns";

import ImageSquare from "../../ImageSquare/ImageSquare";
import PropertyField from "../../PropertyField/PropertyField";
import LoadingIcon from "../../LoadingIcon/LoadingIcon";
import { authAndDownloadImage, deleteImageFromStorage } from "../../../utils/ImgUtils";

// Importing icons
import material_icon from '../../../assets/icons/lightblue/piechart.png';
import supplier_icon from '../../../assets/icons/lightblue/delivery.png';
import label_icon from '../../../assets/icons/lightblue/label.png';
import license_plate_icon from '../../../assets/icons/lightblue/license_plate.png';
import document_icon from '../../../assets/icons/lightblue/document.png';
import user_icon from '../../../assets/icons/lightblue/user.png';
import date_icon from '../../../assets/icons/lightblue/calendar.png';
import notes_icon from '../../../assets/icons/lightblue/document.png';
import alert_icon from '../../../assets/icons/lightblue/alert.png';
import close_icon from '../../../assets/icons/close.svg';
import back_icon_white from '../../../assets/icons/white/back_small.png';
import forward_icon_white from '../../../assets/icons/white/forward_small.png';
import download_icon from '../../../assets/icons/white/download.png';
import trash_icon from '../../../assets/icons/white/trash.svg';

export default function ReclamationCard({cardData, payload}) {
    const { i18n, t } = useTranslation();
    const { authUser, currentUser, labelsets } = React.useContext(AuthContext);
    const [ reclamation, setReclamation ] = React.useState({});
    const [ showDownloadReportModal, setShowDownloadReportModal ] = React.useState(false);
    const [ reportDownloadLinks, setReportDownloadLinks ] = React.useState(null);
    const [ reportDownloadError, setReportDownloadError ] = React.useState(null);
    const [ fullscreenNote, setFullscreenNote ] = React.useState(null);
    const [ showDeleteNoteModal, setShowDeleteNoteModal ] = React.useState(false);
    const unsubscribe = React.useRef(null);

    // Subscribe to active delivery and load data
    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribe.current) unsubscribe.current();

        // Subscribe to active reclamation
        const reclamationQuery = query(doc(firestore, "clients", currentUser.company.company, "reclamations", payload.reclamationId));
        const newUnsubscribe = onSnapshot(reclamationQuery, (snapshot) => {
            if (snapshot.empty) return;
            setReclamation({
                ...snapshot.data(), 
                date: snapshot.data().date.toDate()
            });

            // Reset download links
            if (!showDownloadReportModal) {
                setReportDownloadLinks(null);
            }
        });
        unsubscribe.current = () => newUnsubscribe();
    }, [payload.reclamationId]);

    function handleReclamationFieldChange(event) {
        /** Change value of a delivery field and update on database */
        const { name, value } = event.target;
        const reclamationRef = doc(firestore, "clients", currentUser.company.company, "reclamations", payload.reclamationId);
        setDoc(reclamationRef, {[name]: value}, {merge: true});
    }

    function handleNoteFieldChange(event) {
        /** Change value of a note field and update on database */
        const { name, value } = event.target;
        const reclamationRef = doc(firestore, "clients", currentUser.company.company, "reclamations", payload.reclamationId);
        setDoc(reclamationRef, {bales: {[fullscreenNote]: {[name]: value}}}, {merge: true});
    }

    async function handleReportDownload() {
        /*
        Download reclamation report. 
        Open the modal and call function to generate the reports.
        Once the function responds, show the download links.
        */
        setShowDownloadReportModal(true);

        // If download links are already loaded, do not call the function again (resets when reclamation is updated)
        if (reportDownloadLinks) {
            return;
        }

        // Call function to generate the report
        setReportDownloadLinks(null);
        setReportDownloadError(null);
        const getReclamationsReport = httpsCallable(functions, "onGetReclamationsReportOld");
        try {
            const response = await getReclamationsReport({reclamationId: payload.reclamationId});
            setReportDownloadLinks({
                pdf: response.data?.pdf_url,
                docx: response.data?.docx_url
            })
        } catch (error) {
            setReportDownloadError(error.message);
            console.error("Error: ", error, error.message);
        }
    }

    function goToNextNote() {
        /* Go to the next note in the fullscreen modal */
        const nextNotePosition = Object.keys(reclamation.bales).indexOf(fullscreenNote)+1;
        const nextNoteId = nextNotePosition >= Object.keys(reclamation.bales).length ? Object.keys(reclamation.bales)[0] : Object.keys(reclamation.bales)[nextNotePosition];
        setFullscreenNote(nextNoteId);
    }

    function goToPreviousNote() {
        /* Go to the previous note in the fullscreen modal */
        const previousNotePosition = Object.keys(reclamation.bales).indexOf(fullscreenNote)-1;
        const previousNoteId = previousNotePosition < 0 ? Object.keys(reclamation.bales)[Object.keys(reclamation.bales).length-1] : Object.keys(reclamation.bales)[previousNotePosition];
        setFullscreenNote(previousNoteId);
    }

    function handleDeleteNote() {
        /* Delete the note from the reclamation */
        const noteId = fullscreenNote;
        setShowDeleteNoteModal(false);
        setFullscreenNote(null);

        // Delete the image from the storage
        deleteImageFromStorage(reclamation.bales[noteId].image_URL, ["_400x400", "_1920x1920"]);

        // Delete the note from the database
        handleReclamationFieldChange({target: {name: "bales", value: {[noteId]: deleteField()}}});
    }

    const materialOptions = React.useMemo(() => {
        const newMaterialOptions = {};
        for (const [id, m] of Object.entries(labelsets['materials']['labels'])) {
            newMaterialOptions[id] = m.display_name;
        }
        return newMaterialOptions;
    }, [labelsets]);

    // Render loading screen if reclamation is not loaded
    if (!reclamation) {
        return (
            <div className="reclamation-card-body">
            </div>
        )
    }

    return (
        <div className="reclamation-card-body">
            <div className="reclamation-properties">
                <PropertyField 
                    icon={material_icon} 
                    title={t("property_material").toUpperCase()} 
                    value={reclamation.materials} 
                    options={materialOptions}
                    name={"materials"} 
                    type="select"
                    handleChange={handleReclamationFieldChange} 
                    editable={true}
                    grid_span={1}
                />
                <PropertyField 
                    icon={supplier_icon} 
                    title={t("property_supplier").toUpperCase()} 
                    value={reclamation.supplier} 
                    name={"supplier"} 
                    type="select"
                    options={payload.reclamationSettings?.supplier ?? {}}
                    handleChange={handleReclamationFieldChange} 
                    editable={true}
                    grid_span={2}
                />
                <PropertyField 
                    icon={license_plate_icon} 
                    title={t("property_truck_license_plate").toUpperCase()} 
                    value={reclamation.truck_license_plate} 
                    name={"truck_license_plate"} 
                    type="text"
                    handleChange={handleReclamationFieldChange} 
                    editable={true}
                    grid_span={1}
                />
                <PropertyField 
                    icon={label_icon} 
                    title={t("property_dispo_number").toUpperCase()} 
                    value={reclamation.dispo_number} 
                    name={"dispo_number"} 
                    type="text"
                    handleChange={handleReclamationFieldChange} 
                    editable={true}
                    grid_span={1}
                />
                <PropertyField 
                    icon={label_icon} 
                    title={t("property_bale_mark").toUpperCase()} 
                    value={reclamation.bale_mark} 
                    name={"bale_mark"} 
                    type="text"
                    handleChange={handleReclamationFieldChange} 
                    editable={true}
                    grid_span={1}
                />
                <PropertyField 
                    icon={document_icon} 
                    title={t("property_weighing_slip_nr").toUpperCase()} 
                    value={reclamation.weighing_slip_nr} 
                    name={"weighing_slip_nr"} 
                    type="text"
                    handleChange={handleReclamationFieldChange} 
                    editable={true}
                    grid_span={1}
                />
                <PropertyField 
                    icon={user_icon} 
                    title={t("property_submitted_by").toUpperCase()} 
                    value={reclamation.delivery_submitted_by_user} 
                    name={"delivery_submitted_by"} 
                    type="select"
                    options={payload.reclamationSettings?.delivery_submitted_by_user ?? {}}
                    handleChange={handleReclamationFieldChange} 
                    editable={true}
                    grid_span={1}
                />
                <PropertyField 
                    icon={date_icon} 
                    title={t("property_date").toUpperCase()} 
                    value={reclamation.date} 
                    name="date"
                    type="datetime-local"
                    handleChange={handleReclamationFieldChange}
                    editable={true}
                />
                <PropertyField
                    icon={alert_icon}
                    title="PROBLEMPUNKTE"
                    value={reclamation?.delivery_issues ?? []}
                    name="delivery_issues"
                    type="multiple-choice-select"
                    handleChange={handleReclamationFieldChange}
                    editable={true}
                    options={{
                        discrepancy_delivery_note: "Unstimmigkeit Ladegut",
                        wetness: "Feuchtegehalt zu hoch",
                        inadmissible_material: "Unzulässiges Material",
                        unwanted_material: "Unerwünschtes Material",
                        not_all_bales_marked: "Ballen nicht gekennzeichnet",
                    }}
                />    
                <PropertyField 
                    icon={notes_icon} 
                    title={t("property_notes").toUpperCase()} 
                    value={reclamation.notes} 
                    name="notes"
                    type="textarea"
                    handleChange={handleReclamationFieldChange}
                    editable={true}
                    grid_span={3}
                />
            </div>
            <div className="reclamation-images-gallery">
                <p className="reclamation-images-title">{t("reclamation_images_title")}</p>
                <div className="reclamation-images-wrapper">
                    {reclamation?.bales && Object.entries(reclamation?.bales).sort().map(([bale_id, bale]) => {
                        return (
                            <div onClick={() => {setFullscreenNote(bale_id)}} key={bale_id}>
                                <ImageSquare key={bale_id} 
                                    imageUrl={bale.image_URL}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            <button className="reclamation-download-report-button" onClick={handleReportDownload}>
                {t("reclamation_download_report")}
            </button>

            {/* Show Fullscreen modal to show a single image and note */}
            {fullscreenNote && <div className="fullscreen-bale-modal">
                <div className="fullscreen-modal--buttons">
                    <div className="fullscreen-modal--button fullscreen-modal--next" onClick={goToPreviousNote}>
                        <img className="fullscreen-modal--button-icon" src={back_icon_white}/>
                    </div>
                </div>
                <div className="fullscreen-modal--center">
                    <div className="fullscreen-modal--img-wrapper">
                        <img className="fullscreen-modal--img" src={reclamation?.bales?.[fullscreenNote]?.image_URL}/>
                    </div>
                    <PropertyField 
                        icon={notes_icon} 
                        title={t("property_notes").toUpperCase()} 
                        value={reclamation?.bales?.[fullscreenNote]?.notes ?? ""} 
                        name="notes"
                        type="textarea"
                        handleChange={handleNoteFieldChange}
                        editable={true}
                        grid_span={1}
                    />
                </div>
                <div className="fullscreen-modal--buttons">
                    <div className="fullscreen-modal--button" onClick={() => setFullscreenNote(null)}>
                        <img className="fullscreen-modal--button-icon" src={close_icon}/>
                    </div>
                    <div className="fullscreen-modal--button" onClick={() => {
                        authAndDownloadImage(reclamation?.bales?.[fullscreenNote]?.image_URL, `reclamation_${reclamation?.supplier ?? 'unknown'}_${format(reclamation.date, 'yyyy-MM-dd_HH-mm')}_${Object.keys(reclamation.bales).indexOf(fullscreenNote)}.jpg`)}}>
                        <img className="fullscreen-modal--button-icon" src={download_icon}/>
                    </div>
                    <div className="fullscreen-modal--button" onClick={() => {setShowDeleteNoteModal(true)}}>
                        <img className="fullscreen-modal--button-icon" src={trash_icon} style={{height: "80%"}}/>
                    </div>
                    <div className="fullscreen-modal--button fullscreen-modal--next" onClick={goToNextNote}>
                        <img className="fullscreen-modal--button-icon" src={forward_icon_white}/>
                    </div>
                </div>
            </div> }

            {/* Show modal to download the reclamation report as docx/pdf */}
            <Modal 
                className="default-modal"
                show={showDownloadReportModal}
                backdrop = "static"
                onHide={() => setShowDownloadReportModal(false)}
            >
                <Modal.Header>
                    <Modal.Title>{t("reclamation_download_report_title")}</Modal.Title>
                    <div className="btn-close" onClick={() => {setShowDownloadReportModal(false)}}><img src={close_icon}/></div>
                </Modal.Header>
                <Modal.Body>
                    {!reportDownloadLinks && !reportDownloadError && <div className="reclamation-report-loading">
                        <LoadingIcon/>
                        <p>{t("reclamation_download_report_generating")}</p>
                    </div>}
                    {reportDownloadError && 
                    <p className="reclamation-report-error">{t("reclamation_download_report_error")}</p>}
                </Modal.Body>
                {reportDownloadLinks && <Modal.Footer>
                    {reportDownloadLinks.docx && <Button variant="secondary" onClick={() => authAndDownloadImage(reportDownloadLinks?.docx, `reclamation_${reclamation?.supplier ?? 'unknown'}_${format(reclamation.date, 'yyyy-MM-dd_HH-mm')}.docx`)}>
                        Download Docx
                    </Button>}
                    {reportDownloadLinks.pdf && <Button variant="primary" onClick={() => authAndDownloadImage(reportDownloadLinks?.pdf, `reclamation_${reclamation?.supplier ?? 'unknown'}_${format(reclamation.date, 'yyyy-MM-dd_HH-mm')}.pdf`)}>
                        Download PDF
                    </Button>}
                </Modal.Footer> }
            </Modal>

            {/* Show modal to delete a note */}
            <Modal 
                className="default-modal"
                show={showDeleteNoteModal}
                backdrop = "static"
                onHide={() => setShowDeleteNoteModal(false)}
                contentClassName="delete-modal"
            >
                <Modal.Header>
                    <Modal.Title>{t("reclamation_delete_note_title")}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteNoteModal(false)}>
                        {t("modal_cancel")}
                    </Button>
                    <Button variant="primary" onClick={handleDeleteNote}>
                        {t("modal_delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}