import React from "react";
import "./ReclamationModals.scss";

import { AuthContext } from '../../context/AuthContext';
import { firestore, functions } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { doc, query, onSnapshot, setDoc, deleteField } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Modal, Button } from 'react-bootstrap';
import { format } from "date-fns";

import PropertyField from "../PropertyField/PropertyField";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import { authAndDownloadImage, deleteImageFromStorage, fetchImageUrl } from "../../utils/ImgUtils";

// Importing icons
import notes_icon           from '../../assets/icons/lightblue/document.png';
import close_icon           from '../../assets/icons/close.svg';
import back_icon_white      from '../../assets/icons/white/back_small.png';
import forward_icon_white   from '../../assets/icons/white/forward_small.png';
import download_icon        from '../../assets/icons/white/download.png';
import trash_icon           from '../../assets/icons/white/trash.svg';

function ReclamationImageView({ delivery, reclamationImageId, setReclamationImageId, handleReclamationFieldChange }) {
    const { i18n, t } = useTranslation();
    const [ showDeleteImageModal, setShowDeleteImageModal ] = React.useState(false);
    const [ reclamationImageAuth, setReclamationImageAuth ] = React.useState(null);
    const [ isImgLoaded, setIsImgLoaded ] = React.useState(false);

    React.useEffect(() => {
        setIsImgLoaded(false);
        if (!delivery.reclamation_data.reclamation_images[reclamationImageId].image_URL) return;
        
        fetchImageUrl(delivery.reclamation_data.reclamation_images[reclamationImageId].image_URL).then((url) => {
            setReclamationImageAuth(url);
        })
    }, [delivery.reclamation_data?.reclamation_images?.[reclamationImageId]?.image_URL]);

    function scrollReclamationImage(scrollAmount) {
        /* Change the displayed image by scrolling through scrollAmount images. */
        if (!reclamationImageId || !scrollAmount) return;

        const imageIds = Object.keys(delivery?.reclamation_data?.reclamation_images).sort();
        const currentIndex = imageIds.indexOf(reclamationImageId);
        if (currentIndex === -1) return;

        const nextIndex = currentIndex + scrollAmount;
        if (nextIndex >= imageIds.length || nextIndex < 0) return;
        const nextImageId = imageIds[nextIndex];
        setReclamationImageId(nextImageId);
    }

    async function handleDeleteImage() {
        /* Delete the note from the reclamation */
        const imageId = reclamationImageId;
        setShowDeleteImageModal(false);
        setReclamationImageId(null);

        // Delete the image from the storage
        deleteImageFromStorage(
            delivery.reclamation_data.reclamation_images[imageId].image_URL,
            ["_400x400", "_1920x1920"]
        );

        // Delete the note from the database
        handleReclamationFieldChange({target: {name: [imageId], value: deleteField()}});
    }

    return (
        <div className="fullscreen-bale-modal">
            <div className="fullscreen-modal--buttons">
                <div className="fullscreen-modal--button fullscreen-modal--next" onClick={() => scrollReclamationImage(-1)}>
                    <img className="fullscreen-modal--button-icon" src={back_icon_white}/>
                </div>
            </div>
            <div className="fullscreen-modal--center">
                <div className="fullscreen-modal--img-wrapper">
                    <img className="fullscreen-modal--img" 
                        src={reclamationImageAuth}
                        onLoad={() => setIsImgLoaded(true)}
                        style={{ display: isImgLoaded ? 'block' : 'none' }}
                    />

                    {/* Show loading spinner */}
                    { !isImgLoaded &&
                        <LoadingIcon />
                    }
                </div>
                <PropertyField 
                    icon={notes_icon} 
                    title={t("property_notes").toUpperCase()} 
                    value={delivery.reclamation_data.reclamation_images[reclamationImageId]?.notes ?? ""} 
                    name="notes"
                    type="textarea"
                    handleChange={(e) => handleReclamationFieldChange({target: {name: [reclamationImageId], value: {notes: e.target.value}}})}
                    editable={true}
                    grid_span={1}
                />
            </div>
            <div className="fullscreen-modal--buttons">
                <div className="fullscreen-modal--button" onClick={() => setReclamationImageId(null)}>
                    <img className="fullscreen-modal--button-icon" src={close_icon}/>
                </div>
                <div className="fullscreen-modal--button" onClick={() => {
                    authAndDownloadImage(delivery.reclamation_data.reclamation_images[reclamationImageId]?.image_URL, `reclamation_${delivery?.supplier ?? 'unknown'}_${format(delivery.date, 'yyyy-MM-dd_HH-mm')}_${Object.keys(delivery.reclamation_data.reclamation_images).indexOf(reclamationImageId)}.jpg`)}}>
                    <img className="fullscreen-modal--button-icon" src={download_icon}/>
                </div>
                <div className="fullscreen-modal--button" onClick={() => {setShowDeleteImageModal(true)}}>
                    <img className="fullscreen-modal--button-icon" src={trash_icon} style={{height: "80%"}}/>
                </div>
                <div className="fullscreen-modal--button fullscreen-modal--next" onClick={() => scrollReclamationImage(1)}>
                    <img className="fullscreen-modal--button-icon" src={forward_icon_white}/>
                </div>
            </div>

            {/* Show modal to delete a note */}
            <Modal 
                className="default-modal"
                show={showDeleteImageModal}
                backdrop = "static"
                onHide={() => setShowDeleteImageModal(false)}
                contentClassName="delete-modal"
            >
                <Modal.Header>
                    <Modal.Title>{t("reclamation_delete_note_title")}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteImageModal(false)}>
                        {t("modal_cancel")}
                    </Button>
                    <Button variant="primary" onClick={handleDeleteImage}>
                        {t("modal_delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function ReclamationReportButton({ delivery }) {
    const { i18n, t } = useTranslation();
    const [ showDownloadReportModal, setShowDownloadReportModal ] = React.useState(false);
    const [ reportDownloadLinks, setReportDownloadLinks ] = React.useState(null);
    const [ reportDownloadError, setReportDownloadError ] = React.useState(null);

    React.useEffect(() => {
        // Reset download links
        if (!showDownloadReportModal) {
            setReportDownloadLinks(null);
        }
    }, [delivery]);

    async function handleReportDownload() {
        /*
        Download reclamation report. 
        Open the modal and call function to generate the reports.
        Once the function responds, show the download links.
        */
        setShowDownloadReportModal(true);

        // If download links are already loaded, do not call the function again (resets when reclamation is updated)
        if (reportDownloadLinks) {
            return;
        }

        // Call function to generate the report
        setReportDownloadLinks(null);
        setReportDownloadError(null);
        const getReclamationsReport = httpsCallable(functions, "onGetReclamationsReport");
        try {
            const response = await getReclamationsReport({deliveryId: delivery.id});
            setReportDownloadLinks({
                pdf: response.data?.pdf_url,
                docx: response.data?.docx_url
            })
        } catch (error) {
            setReportDownloadError(error.message);
            console.error("Error: ", error, error.message);
        }
    }

    return (
        <>
            <button className="reclamation-download-report-button" onClick={handleReportDownload}>
                {t("reclamation_download_report")}
            </button>
            {/* Show modal to download the reclamation report as docx/pdf */}
            <Modal 
                className="default-modal"
                show={showDownloadReportModal}
                backdrop = "static"
                onHide={() => setShowDownloadReportModal(false)}
            >
                <Modal.Header>
                    <Modal.Title>{t("reclamation_download_report_title")}</Modal.Title>
                    <div className="btn-close" onClick={() => {setShowDownloadReportModal(false)}}><img src={close_icon}/></div>
                </Modal.Header>
                <Modal.Body>
                    {!reportDownloadLinks && !reportDownloadError && <div className="reclamation-report-loading">
                        <LoadingIcon/>
                        <p>{t("reclamation_download_report_generating")}</p>
                    </div>}
                    {reportDownloadError && 
                    <p className="reclamation-report-error">{t("reclamation_download_report_error")}</p>}
                </Modal.Body>
                {reportDownloadLinks && <Modal.Footer>
                    {reportDownloadLinks.docx && <Button variant="secondary" onClick={() => authAndDownloadImage(reportDownloadLinks?.docx, `reclamation_${delivery?.supplier ?? 'unknown'}_${format(delivery.date, 'yyyy-MM-dd_HH-mm')}.docx`)}>
                        Download Docx
                    </Button>}
                    {reportDownloadLinks.pdf && <Button variant="primary" onClick={() => authAndDownloadImage(reportDownloadLinks?.pdf, `reclamation_${delivery?.supplier ?? 'unknown'}_${format(delivery.date, 'yyyy-MM-dd_HH-mm')}.pdf`)}>
                        Download PDF
                    </Button>}
                </Modal.Footer> }
            </Modal>
        </>
    )
}

export { ReclamationImageView, ReclamationReportButton };