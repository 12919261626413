import React from "react";
import "./Labeling.scss";

import LabelingClassicRequested from "./LabelingClassicRequested";
import LabelingClassicRandomized from "./LabelingClassicRandomized";
import LabelingPairwiseRanking from "./LabelingPairwiseRanking";

import { getNumRequests } from "./LabelingClassicRequested";
import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { collection, query, where, getDocs, limit } from "firebase/firestore";

import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Navbar/Sidebar';

import requestsIcon from '../../assets/icons/gray/labeling_request.png';
import randomizedIcon from '../../assets/icons/gray/dice.png';
import pairwiseRankingIcon from '../../assets/icons/gray/fight.png';

export default function Labeling() {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [ labelingModes, setLabelingModes ] = React.useState(null);
    const [ selectedLabelingMode, setSelectedLabelingMode ] = React.useState(null);
    const [ numRequests, setNumRequests ] = React.useState(0);

    React.useEffect(() => {
        checkLabelingModes();

        // Load the number of labeling requests
        async function loadNumRequests() {
            if (!currentUser.company.company) {
                return;
            }
            setNumRequests(await getNumRequests(currentUser.company.company, currentUser.device_id));
        }
        loadNumRequests();
    }, []);

    async function checkLabelingModes() {
        /* Check which labeling modes are available for the client */
        const labelingModesNew = ["requested", "randomized"];
        if (await pairwiseRankingAvailable()) {
            labelingModesNew.push("pairwiseRanking");
        }
        setLabelingModes(labelingModesNew);

        // If there is only one mode available, select it automatically.
        if (labelingModesNew.length === 1) {
            setSelectedLabelingMode(labelingModesNew[0]);
        }
    }

    async function pairwiseRankingAvailable() {
        /* Check if there are labelsets available for pairwise ranking */
        const q = query(
            collection(firestore, "clients", currentUser.company.company, "pairwise_ranking"),
            where("enable_labeling", "==", true),
            limit(1));
        const snapshot = await getDocs(q);
        return !snapshot.empty;
    }

    return (
        <div className="labeling">
            <Sidebar />
            <div className="labeling--right">
                <Navbar 
                    title={t("sidebar_labeling")}
                />
                { selectedLabelingMode==="requested" && <LabelingClassicRequested/>}
                { selectedLabelingMode==="randomized" && <LabelingClassicRandomized/>}
                { selectedLabelingMode==="pairwiseRanking" && <LabelingPairwiseRanking/>}
                { selectedLabelingMode===null && labelingModes && <div className="labeling-mode-list">
                    {labelingModes.map(mode => {
                        return <div key={mode} className="labeling-mode-square" onClick={() => {setSelectedLabelingMode(mode);}}>
                            <div className="img-container">
                                <img src={mode==="pairwiseRanking" ? pairwiseRankingIcon : 
                                mode==="randomized" ? randomizedIcon : requestsIcon}/>
                            </div>
                            <p>{mode==='pairwiseRanking' ? t('labeling_mode_pairwise') : 
                                mode==='randomized' ? t('labeling_mode_randomized') : 
                                mode==='requested' ? t('labeling_mode_requested') : ""}</p>
                            {mode==="requested" && numRequests>0 && <div className="num-requests">{numRequests}</div>}
                        </div>
                    })}
                </div> }
            </div>
        </div>
    )
}