import React from 'react'
import { useTranslation } from "react-i18next";

import "./LabelingPanel.scss"
import MotivationalLines from './MotivationalLines.jsx';
import { AuthContext } from '../../context/AuthContext';
import { firestore } from '../../context/FirebaseConfig';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore'
import { format } from "date-fns";
import Select from 'react-select';

export default function LabelingPanel({ observation, getNext }) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);
    const [selectedLabels, setSelectedLabels] = React.useState({});
    const [labelOptions, setLabelOptions] = React.useState({});
    const [multiLabelOptions, setMultiLabelOptions] = React.useState({});
    const [requestLabel, setRequestLabel] = React.useState(false);
    const [userLabelsCount, setUserLabelsCount] = React.useState(0);
    const unsubscribeLabelsCount = React.useRef(null);

    React.useEffect(() => {
        /* Load labels for the observation and build select-dropdowns */
        if (!observation) {
            return;
        }

        const selectedLabelsNew = {};
        if (observation.labels) {
            Object.keys(labelsets).forEach(labelsetId => {
                if (labelsetId in observation.labels && currentUser.uid in observation.labels[labelsetId]) {
                    if (labelsets[labelsetId].type === 'multiple-choice') {
                        selectedLabelsNew[labelsetId] = [];
                        observation.labels[labelsetId][currentUser.uid].forEach(id => {
                            if (id) selectedLabelsNew[labelsetId].push({"value":id, "label":labelsets[labelsetId].labels[id]?.full_name});
                        });
                    } else {
                        const id = observation.labels[labelsetId][currentUser.uid];
                        if (id) selectedLabelsNew[labelsetId] = {"value":id, "label":labelsets[labelsetId].labels[id]?.full_name}
                    }
                } else {
                    selectedLabelsNew[labelsetId] = [];
                }
            });
        } else {
            Object.keys(labelsets).forEach(id => {
                if (labelsets[id]?.type === "multiple-choice") {
                    selectedLabelsNew[id] = [];
                } else {
                    selectedLabelsNew[id] = "";
                }
            });
        }
        setSelectedLabels(selectedLabelsNew);
        buildLabelOptions();
        setRequestLabel(observation?.request_label ?? false);
    }, [observation])

    async function buildLabelOptions() {
        /* Build the options lists for the select-dropdowns. One for each labelset */
        const labelOptionsNew = {};
        const multiLabelOptionsNew = {};

        Object.keys(labelsets).forEach(id => {
            if (labelsets[id]?.enable_labelling) {
                if (labelsets[id]?.type === "multiple-choice") {
                    multiLabelOptionsNew[id] = [];
                    Object.keys(labelsets[id].labels).forEach(labelId => {
                        multiLabelOptionsNew[id].push({"value":labelId, "label":labelsets[id].labels[labelId].full_name});
                    });
                } else {
                    labelOptionsNew[id] = getGroupedLabelsList(id);
                }
            }
        });

        setLabelOptions(labelOptionsNew);
        setMultiLabelOptions(multiLabelOptionsNew);
    }

    function getGroupedLabelsList(id) {
        /** 
         * Create different groups for labels in order to facilitate labeling
         * First group: Predicted -> put predicted labels on top and highlight
         * Second group: Closely Related -> put labels closesly related to predictions second
         * Third group: All others in same product group
         */
        if (!("predicted_labels" in observation)) {
            // If there are no predictions for the labelset, then just return as is.
            return Object.keys(labelsets[id].labels).sort().map(labelId => {
                return {"value":labelId, "label":labelsets[id].labels[labelId].full_name}
            });
        } else {
            const labelOptionsGrouped = [];
            const labelsFilteredRemaining = {...labelsets[id].labels};

            // Put predicted labels in recommended list
            let predictedLabelIds = [];
            if (id in observation.predicted_labels) {
                predictedLabelIds.push(observation.predicted_labels[id].class);
            } else {
                predictedLabelIds = getMappedLabelIds(id);
            }
            const recommendedLabelOptions = [];
            for (const predictedLabelId of predictedLabelIds) {
                recommendedLabelOptions.push({
                    "value": predictedLabelId, 
                    "label": labelsets[id].labels[predictedLabelId].full_name,
                    "group": "predicted",
                })
                delete labelsFilteredRemaining[predictedLabelId];
            }

            // Put closely related labels in recommended list
            for (const predictedLabelId of predictedLabelIds) {
                if (predictedLabelId in labelsets[id].labels && "close_relations" in labelsets[id].labels[predictedLabelId]) {
                    labelsets[id].labels[predictedLabelId].close_relations.forEach(closeLabelId => {
                        if (closeLabelId in labelsFilteredRemaining) {
                            recommendedLabelOptions.push({
                                "value": closeLabelId, 
                                "label": labelsets[id].labels[closeLabelId].full_name,
                                "group": "related",
                            })
                            delete labelsFilteredRemaining[closeLabelId];
                        }
                    })
                }
            }
            if (recommendedLabelOptions?.length > 0) {
                labelOptionsGrouped.push({
                    "label": t("labeling_select_recommended"),
                    "options": recommendedLabelOptions,
                    "group": "recommended",
                });
            }

            // Add all further labels belonging to the same product_group
            const remainingLabelOptions = []
            Object.keys(labelsFilteredRemaining).sort().forEach(labelId => {
                if ("product_group" in labelsFilteredRemaining[labelId] && labelsFilteredRemaining[labelId].product_group != observation.object_type) {
                    return;
                }
                remainingLabelOptions.push({"value":labelId, "label":labelsets[id].labels[labelId].full_name})
            });
            if (remainingLabelOptions?.length > 0) {
                labelOptionsGrouped.push({
                    'label': t("labeling_select_others"),
                    'options': remainingLabelOptions,
                });
            }

            if (labelOptionsGrouped.length === 1) {
                // No need for grouping if there is only one group
                return labelOptionsGrouped[0].options;
            } else {
                return labelOptionsGrouped;
            }
        }
    }

    function getMappedLabelIds(labelsetId) {
        /* Select which labels are predicted based on predictions for a labelset with mapping */
        if ("use_mapping" in labelsets[labelsetId] && labelsets[labelsetId].use_mapping) {
            if (labelsets[labelsetId].use_mapping in observation.predicted_labels) {
                // Predictions for mapped labelset available
                const mappedLabelsetId = labelsets[labelsetId].use_mapping;
                if ("mappings" in labelsets[mappedLabelsetId] && labelsetId in labelsets[mappedLabelsetId]["mappings"] 
                && observation.predicted_labels[mappedLabelsetId]["class"] in labelsets[mappedLabelsetId]["mappings"][labelsetId]) {
                    // Return mapped prediction
                    return labelsets[mappedLabelsetId]["mappings"][labelsetId][observation.predicted_labels[mappedLabelsetId]["class"]];
                } else {
                    // The predicted label in the mapped labelset has no mapping in the target labelset
                    return [];
                }
            } else {
                // No predictions for mapped labelset either -> Check for higher order mapping in recursive call
                const labelIds = getMappedLabelIds(labelsets[labelsetId].use_mapping);
                let mappedLabelIds = [];
                const mappedLabelsetId = labelsets[labelsetId].use_mapping;
                for (const labelId of labelIds) {
                    mappedLabelIds = [...mappedLabelIds, 
                        ...labelsets[mappedLabelsetId]["mappings"][labelsetId][labelId]]
                }
                return mappedLabelIds;
            }
        } else {
            // No mapping available, therefore no predictions found
            return [];
        }
    }

    async function onLabelChange(id, selectedLabelId) {
        /* Handle when labels are selected/deselected in the dropdowns. Id signifies for which labelset the selection happened */
        setSelectedLabels(prevSelectedLabels => {
            return {...prevSelectedLabels, [id]: selectedLabelId};
        })

        const label = labelsets[id]?.type === "multiple-choice" ? selectedLabelId.map(option => option?.value ?? "") : selectedLabelId?.value ?? "";
        if (observation.doc_id) {
            updateDoc(doc(firestore, 'clients', currentUser.company.company, 'observations', observation.doc_id), {
                [`labels.${id}.${currentUser.uid}`]:label, 
                'labelled': true,
                'request_label': false,
            });
        }
    }

    React.useEffect(() => {
        async function getUserLabelsCount() {
            /* Get statistic on how many labels the user counted on the given day */
            const dateString = format(new Date(), "yyyy-MM-dd");
            const firebaseQuery = doc(firestore, "clients", currentUser.company.company, "labelling_statistics", dateString);
            const newUnsubscribe = onSnapshot(firebaseQuery, (snapshot) => {
                if (snapshot.exists && snapshot.data()) {
                    if (currentUser.uid in snapshot.data().labellers) {
                        setUserLabelsCount(snapshot.data().labellers[currentUser.uid]);
                    } else {
                        setUserLabelsCount(0);
                    }
                } else {
                    setUserLabelsCount(0);
                }
            });
    
            unsubscribeLabelsCount.current = () => newUnsubscribe();
        }
        getUserLabelsCount();
    }, []);

    function getObjectType() {
        const translation = t(`object_type_${observation.object_type.replace(" ", "_")}`);
        return translation.startsWith("object_type_") ? observation.object_type : translation;
    }

    async function onSkipLabel() {
        updateDoc(doc(firestore, 'clients', currentUser.company.company, 'observations', observation.doc_id), { 'labelled':true });
        getNext(observation);
    }

    function onRequestLabel() {
        /* Handle when the checkbox to request labeling for an observation is changed */
        if (observation.doc_id) {
            updateDoc(doc(firestore, 'clients', currentUser.company.company, 'observations', observation.doc_id), {
                'request_label': !requestLabel,
            });
            setRequestLabel(prev => !prev);
        }
    }

    return (
        <div className="labeling-panel">
            <p className="labeling-panel--date">{observation.date}</p>
            <h1>{observation.time}</h1>
            <p>{t(getObjectType())}</p>
            { /* Show predicted classes to people with flags */ }
            {currentUser.info.user_flags && ['labeler', 'admin', 'manager'].some(flag => currentUser.info.user_flags.includes(flag)) && observation.predicted_labels &&
            Object.keys(observation.predicted_labels).sort().map(labelset => {
                const predictedClass = observation?.predicted_labels[labelset]?.class;
                if (!predictedClass || !labelsets?.[labelset]?.name) return;
                const predictedClassName = labelsets[labelset]?.['labels']?.[predictedClass]?.display_name ?? predictedClass;
                const accuracyFraction = observation.predicted_labels[labelset]?.cumulative_accuracy ?? 100;
                const accuracy = Math.round(accuracyFraction*100);
                return <p className="predicted-recipe-material" key={labelset}>{`${labelsets[labelset].name}: ${predictedClassName} (${accuracy}%)`}</p>
            })}
            {currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) &&
            <div className="material-label">
                { /* Single-Choice Labelling Dropdowns */}
                {Object.keys(labelOptions).sort((a,b) => {return labelsets[a]?.sorting_index-labelsets[b]?.sorting_index}).map(id => {
                    if (labelOptions[id].length === 0) {
                        // No labels available for this product-group
                        return;
                    }
                    return <div key={id}>
                        <p className="material-label-title">{labelsets[id].name}:</p>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            options={labelOptions[id]}
                            value={selectedLabels[id]}
                            onChange={(e) => {onLabelChange(id, e)}}
                            className="material-label-select"
                            styles={{
                                option: (base, state) => ({
                                    ...base,
                                    padding: '0.2rem 0rem 0.2rem 0.5rem',
                                    backgroundColor: state.data.group === "predicted" ? "#b8f5cc" : state.data.group === "related" ? "#dcfce7" : base.backgroundColor,
                                    ':hover': {
                                        ...base[':hover'],
                                        backgroundColor: "#b5d4ff",
                                    },
                                }),
                            }}
                        />
                        {currentUser.info.user_flags.includes('admin') && observation.labels && observation.labels[id] && Object.keys(observation.labels[id]).map(key => {
                            const labelId = observation.labels[id][key];
                            if (!labelId) return;
                            return <p key={key} className="material-label--annotations">{labelsets[id].labels[labelId].display_name}</p>
                        })}
                    </div>
                })}
                { /* Multiple-Choice Labelling Dropdowns */}
                {Object.keys(multiLabelOptions).map(id => {
                    return <div key={id}>
                        <p className="material-label-title">{labelsets[id].name}:</p>
                        <Select
                            isMulti
                            options={multiLabelOptions[id]}
                            value={selectedLabels[id]}
                            onChange={(e) => {onLabelChange(id, e)}}
                            className="material-label-select"
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    padding: '0.2rem 0rem 0.2rem 0.5rem',
                                }),
                            }}
                        />

                        {/*<MultiSelectDropdown selectedOptions={selectedLabels[id]} options={multiLabelOptions[id]} onChange={(e) => {onLabelChange(id, e)}}/>*/}
                        {currentUser.info.user_flags.includes('admin') && observation.labels && observation.labels[id] && Object.keys(observation.labels[id]).map(key => {
                            const labelIds = observation.labels[id][key];
                            if (!labelIds || labelIds.length===0) return;
                            return <p className="material-label--annotations">{labelIds.reduce((acc, labelId) => `${acc}${labelsets[id].labels[labelId].display_name}, `, "")}</p>
                        })}
                    </div>
                })}
                { /* Skip-button */}
                <button className="material-label--button" onClick={onSkipLabel}>{t("labeling_skip")}</button>
                { /* Request label checkbox */}
                {currentUser.info.user_flags && ['admin'].some(flag => currentUser.info.user_flags.includes(flag)) && 
                <div className="request-label-container"><input
                    type="checkbox"
                    checked={requestLabel}
                    onChange={onRequestLabel}
                />
                <label>{t("labeling_request")}</label></div>}
            </div>
            }
            { /* Motivation Messages */}
            {currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) && userLabelsCount > 0 &&
            <div className="label-count-field">
                <div className="label-count-line">
                    <p>{t("labeling_you_labeled")}</p>
                    <p className="label-count-number">{userLabelsCount}</p>
                    <p>{t("labeling_bales_today")}</p>
                </div>
                {userLabelsCount > 0 && 
                <MotivationalLines numLabeled={userLabelsCount}/>}
            </div>
            }
        </div>
    )
}