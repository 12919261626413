import { ref, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../context/FirebaseConfig";

export async function fetchImageUrl(firebase_url) {
    const baseUrl = firebase_url.split("?")[0];
    const imageRef = ref(storage, baseUrl);
    const url = getDownloadURL(imageRef).catch((error) => {
        return "";
    })
    return url;
}

function getThumbnailUrlFromFullUrl(firebase_url, thumb_extension = "_400x400") {
    /* Get the thumbnail URL for the given firebase_url */
    const baseUrl = firebase_url.split("?")[0];
    const fileExtension =
    baseUrl.endsWith(".jpeg") ? ".jpeg" :
    baseUrl.endsWith(".jpg") ? ".jpg" :
    baseUrl.endsWith(".png") ? ".png" : ""

    return baseUrl.replace(fileExtension, "") + thumb_extension + fileExtension;
}

export async function fetchImageThumbnailUrl(firebase_url) {
    const thumbnailUrl = getThumbnailUrlFromFullUrl(firebase_url);
    const thumbImageRef = ref(storage, thumbnailUrl);
    const url = getDownloadURL(thumbImageRef).catch((error) => {
        return fetchImageUrl(firebase_url);
    })
    return url;
}

export async function downloadImage(url, filename) {
    const a = document.createElement("a");
    a.href = await fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export async function authAndDownloadImage(firebase_url, filename) {
    const url = await fetchImageUrl(firebase_url);
    downloadImage(url, filename);
}

export async function deleteImageFromStorage(firebase_url, thumb_extensions = ["_400x400"]) {
    /* Delete an image (and associated thumbnail) from storage */
    const baseUrl = firebase_url.split("?")[0];
    const imageRef = ref(storage, baseUrl);
    deleteObject(imageRef).catch((error) => {
        console.error(`Failed to delete image for ${firebase_url}: ${error.message}`);
    });

    // Delete thumbnails
    if (!thumb_extensions) return;
    for (const thumb_extension of thumb_extensions) {
        const thumbnailUrl = getThumbnailUrlFromFullUrl(firebase_url, thumb_extension);
        const thumbImageRef = ref(storage, thumbnailUrl);
        deleteObject(thumbImageRef).catch((error) => {
            console.error(`Failed to delete thumbnail for ${firebase_url}: ${error.message}`);
        });
    }
}