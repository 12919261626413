import React from "react";
import "./LiveView.scss";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";

import { fetchUserInfo } from "../../utils/FirebaseUtils";

// Importing custom pages
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Navbar/Sidebar';
import TopicFilter from "../../components/Filters/TopicFilter";

export default function LiveView() {
    const { i18n, t } = useTranslation();
    // Hardcoded topics for now [FE]. TODO: Read from firebase. 
    const topics = [
        { id: 0, value: '/ximea_camera_node/image_raw', display_name: t("liveview_choice_camera") },
        { id: 1, value: '/waste_detection_node/image_trash_prediction', display_name: t("liveview_choice_predictions") },
    ];
    const { currentUser } = React.useContext(AuthContext);
    const [topicFilter, setTopicFilter] = React.useState(topics[0].value); // Default topic
    const [isStreamError, setIsStreamError] = React.useState(false);

    const handleStreamError = () => {
        setIsStreamError(true);
    }

    // Function for displaying current time
    function Clock() {
        const [currentTime, setCurrentTime] = React.useState(new Date());
        React.useEffect(() => {
            const timerID = setInterval(() => tick(), 1000);
            return function cleanup() {
                clearInterval(timerID);
            };
        });
        function tick() {
            setCurrentTime(new Date());
        }
        return <span>{currentTime.toLocaleTimeString()}</span>;
    }

    return (
        <div className="live-view">
            <Sidebar />
            <div className="live-view--right">
                <Navbar 
                    title={`${currentUser.company.company_displayname} ${t("sidebar_live")}`}
                />
                <div className="live-view--filters">
                    <TopicFilter
                        topics={topics}
                        setTopicFilter={setTopicFilter}
                    />
                </div>
                <>
                    <div className="upcircle-header">
                        {t("liveview_title")}
                    </div>
                    <div className="stream-box">
                        <div className="clock">
                            <Clock />
                        </div>
                        {isStreamError ? (
                            <div className="stream-box--error">
                                <p>{t("liveview_error")}</p>
                            </div>
                        ) : (
                            <img
                            className="stream-box--stream"
                            src={`http://${currentUser.devices?.[currentUser.device_id].local_ip}:8080/stream?topic=${topicFilter}&type=mjpeg&quality=20`}
                            alt={`${topicFilter} stream`}
                            onError={handleStreamError}
                            />
                        )
                        }                        
                    </div>
                </>
            </div>
        </div>
    )
}