import React from "react";
import "./PlantFeed.scss";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";

import { doc, updateDoc } from "firebase/firestore";
import { addDays, startOfDay, addHours } from "date-fns";

import { subscribeAggregatedData, getEmptyAggregatedData } from "../../utils/dataAggregationUtils";
import LoadingScreen from "../../components/LoadingScreen";

// Importing custom pages
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Navbar/Sidebar';
import TimeframeFilter from "../../components/Filters/TimeframeFilter";
import ShiftTimeFilter from "../../components/Filters/ShiftTimeFilter";
import Card from "../../components/Card/Card";

// Importing icons
import card_icon_red from '../../assets/icons/gray/plastics_red.svg';
import card_icon_green from '../../assets/icons/gray/plastics_green.svg';
import card_icon_yellow from '../../assets/icons/gray/plastics_yellow.svg';
import card_icon_blue from '../../assets/icons/gray/plastics_blue.svg';

export default function PlantFeed({}) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
   
    const [ timeframe, setTimeframe ] = React.useState({startDate: startOfDay(new Date()), endDate: startOfDay(new Date()), label: "timeframe_today"});
    const [ shiftTime, setShiftTime ] = React.useState(0);
    const [ aggregatedData, setAggregatedData ] = React.useState(getEmptyAggregatedData());
    const unsubscribeAggregatedData = React.useRef(null);

    React.useEffect(() => {
        setShiftTime(currentUser.company.shift_time);
    }, [currentUser.company.shift_time]);

    React.useEffect(() => {
        if (currentUser.device_id === null) {
            return;
        }

        if (unsubscribeAggregatedData.current) {
            unsubscribeAggregatedData.current();
        }

        const timeframeShifted = getShiftedTimeframe()
        const unsubscribe = subscribeAggregatedData(setAggregatedData, timeframeShifted, currentUser, true);
        unsubscribeAggregatedData.current = () => unsubscribe();
    }, [timeframe, currentUser.device_id, shiftTime])

    async function changeShiftTime(shiftTimeNew) {
        setShiftTime(shiftTimeNew)
        await updateDoc(doc(firestore, "clients", currentUser.company.company), {shift_time: shiftTimeNew});
    }
    function getShiftedTimeframe() {
        return {...timeframe, startDate: addHours(startOfDay(timeframe.startDate), shiftTime), endDate: addDays(addHours(startOfDay(timeframe.startDate), shiftTime), 1)};
    }

    // Return error message if there is no registered device for the client
    if (!currentUser.device_id) {
        return (
            <div className="plant-feed">
                <Sidebar />
                <div className="plant-feed--right">
                    <Navbar 
                        title={`${currentUser.company.company_displayname} ${t("sidebar_plant_feed")}`}
                    />
                    <LoadingScreen loading={t("loading")} fullscreen={false} message={t("device_not_found_error")} logoColor="dark"/>
                </div>
            </div>
        )
    }

    return (
        <div className="plant-feed">
            <Sidebar />
            <div className="plant-feed--right">
                <Navbar 
                    title={`${currentUser.company.company_displayname} ${t("sidebar_plant_feed")}`}
                />
                <div className="plant-feed--filters">
                    <TimeframeFilter 
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        singleDate={true}
                    />
                    <ShiftTimeFilter 
                        shiftTime={shiftTime}
                        setShiftTime={changeShiftTime}
                    />
                </div>
                { currentUser.device_id !== null && <div className="plant-feed--cards">
                    <Card 
                        cardClassName="daysummarycard"
                        cardData={{
                            ctype: 'DaySummaryCard',
                            icon: <img src={card_icon_yellow} />,
                            title: t("title_daysummary"),
                            subtitle: t("subtitle_daysummary"),
                        }}
                        payload={aggregatedData}
                    />
                    <Card 
                        cardClassName="timelinecard"
                        cardData={{
                            ctype: 'MultiTimelineCard',
                            icon: <img src={card_icon_red} />,
                            title: t("title_timeline"),
                            subtitle: `Total, ${t(timeframe.label)}`,
                            timeframe: timeframe,
                        }}
                        payload={aggregatedData["timelineData"]}            
                    />
                    <Card 
                        cardClassName="devicestatuscard"
                        cardData={{
                            ctype: 'DeviceStatusCard',
                            icon: <img src={card_icon_green} />,
                            title: t("title_devicestatus"),
                            subtitle: t(timeframe.label),
                            timeframe: getShiftedTimeframe(),
                        }}           
                    />
                    <Card
                        cardClassName="dayreviewcard"
                        cardData={{
                            ctype: "DayReviewCard",
                            icon: <img src={card_icon_blue} />,
                            title: t("title_dayreview"),
                            subtitle:t("subtitle_dayreview"),
                            shiftTime: shiftTime,
                            timeframe: getShiftedTimeframe(),
                        }}
                        payload={aggregatedData["timelineData"]}
                    />
                </div>}
            </div>
        </div>
    )
}