import React from "react";
import "./css/Card.scss"
import { storage } from "../../context/FirebaseConfig";
import { ref, getDownloadURL } from "firebase/storage";
import { useTranslation } from "react-i18next";

import {useTable} from 'react-table'
import FullscreenObservationModal from "../FullscreenObservationModal/FullscreenObservationModal";
import { fetchImageUrl } from "../../utils/ImgUtils.js"

export default function TableCard({cardData, payload}) {
    const { i18n, t } = useTranslation();

    const COLUMNS = [
        {
            Header: t("table_time"),
            accessor: 'time'
        },
        {
            Header: t("table_date"),
            accessor: 'date'
        },
        {
            Header: t("table_objecttype"),
            accessor: (row) => {
                const translation = t(`object_type_${row.object_type.replace(" ", "_")}`);
                return translation.startsWith("object_type") ? row.object_type : translation;
            }
        }
    ];

    const columns = React.useMemo(() => COLUMNS, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data:payload })
    const [selectedObservationId, setSelectedObservationId] = React.useState(0)
    const [imgUrl, setImgUrl] = React.useState("")
    const [showFullscreenObservation, setShowFullscreenObservation] = React.useState(false)

    async function setObservation(observationId) {
        setSelectedObservationId(observationId)
        setImgUrl( await fetchImageUrl(payload[observationId].image_url) )
    }

    React.useEffect(() => {
        if (payload && payload.length>0 && imgUrl === "") {
            setObservation(0);
        }
    }, [payload])

    function handleKeyDown(event) {
        if (event.keyCode === 38) {
            setObservation(Math.max(selectedObservationId-1, 0))
        }

        if (event.keyCode === 40) {
            setObservation(Math.min(selectedObservationId+1, payload.length-1))
        }
    }
    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => { document.removeEventListener('keydown', handleKeyDown); }
    })

    return(
        <div className="tablecard-body">
            <div className="table-wrapper">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} 
                                    onClick={() => {setObservation(row.index)}} 
                                    className={row.index==selectedObservationId ? "active-row" : undefined}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="example-image-wrapper">
                <img className="example-image" onClick={() => setShowFullscreenObservation(true)} src={imgUrl} />
            </div>
            {showFullscreenObservation && <FullscreenObservationModal 
                observation={payload[selectedObservationId]}
                closeFullscreenModal={() => setShowFullscreenObservation(false)}
                getNext={() => {setObservation(Math.min(selectedObservationId+1, payload.length-1))}}
                getPrevious={() => {setObservation(Math.max(selectedObservationId-1, 0))}}
            />}
        </div>  
    )
}