function hsv2rgb(h, s, v) {
    // input: h in [0,360] and s,v in [0,1] - output: r,g,b in [0,1]                              
    let f = (n, k = (n + h / 60) % 6) => v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
    return [f(5), f(3), f(1)];
}

const rgbToHex = (r, g, b) => {
    return "#" + [r, g, b].map(x => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }).join('');
};

export function getColorPalette(n) {
    const COLORS = ['#0A86F9', '#0af9c9', '#0acff9', '#f9be0a', '#0af9f6', '#b80af9', '#daf90a', '#e58e8e', '#663535', '#518259'];
    if (n <= COLORS.length) {
        return COLORS.slice(0, n);
    }

    const palette = [];
    const hueRange = Math.min(n * 30, 360);
    for (let i = 0; i < n; i++) {
        const [r, g, b] = hsv2rgb(140 + i * hueRange / n, 0.5, 0.95);
        palette.push(rgbToHex(r, g, b));
    }
    return palette;
}