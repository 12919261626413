import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

import './Dropdown.scss'

export default function ShiftTimeFilter({ shiftTime, setShiftTime }) {
    const { i18n, t } = useTranslation();

    const times = [...Array(24).keys()];

    return (
        <div className="dropdown">
            <div className="dropdown-splitbutton">
                <button className="dropdown-splitbutton-text">{t("dayreview_shift_time")}:</button>
                <button className="dropdown-splitbutton-button">{`${shiftTime}:00`}</button>
            </div>
            <div className="dropdown-content">
                {times.map(hour => {
                    return (
                        <a key={hour} onClick={() => setShiftTime(hour)}>{`${hour}:00`}</a>
                    )
                })}
            </div>
        </div>
    )
}