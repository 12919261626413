import React from "react"
import "./TimelineCard.scss"

import { useTranslation } from "react-i18next";
import { AuthContext } from '../../../context/AuthContext';
import { getColorPalette } from "../../../utils/colorPaletteUtils";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import { Bar, Line } from 'react-chartjs-2';
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend)

export default function TimelineCard({ cardData, payload }) {
    const { i18n, t } = useTranslation();
    const { labelsets } = React.useContext(AuthContext);

    // Transform the payload into a format that ChartJS can use
    const data = React.useMemo(() => {
        if (!payload?.yValuesDict) return {};

        const colorPalette = getColorPalette(Object.keys(payload.yValuesDict).length);
    
        let yValuesDictTransformed;
        if (payload?.options?.normalize) {
            // Normalize values accross different categories
            const sumArray = Object.values(payload.yValuesDict).reduce((a, b) => a.map((val, i) => val + b[i]), new Array(Object.values(payload.yValuesDict)[0]?.length ?? 1).fill(0));
            yValuesDictTransformed = Object.keys(payload.yValuesDict).reduce((acc, key) => {
                acc[key] = payload.yValuesDict[key].map((val, i) => val / sumArray[i]);
                return acc;
            }, {});
        } else {
            // Use raw values
            yValuesDictTransformed = payload.yValuesDict;
        }
        
        return {
            labels: payload.xValues,
            datasets: Object.keys(payload.yValuesDict).sort((a, b) => {
                return a === 'uncategorized' ? 1 : b === 'uncategorized' ? -1 : a.localeCompare(b);
            }).map((key, index) => {
                return {
                    label: payload.labelset && labelsets[payload.labelset]?.labels?.[key] ? labelsets[payload.labelset].labels[key].display_name : t(key),
                    data: yValuesDictTransformed[key],
                    backgroundColor: key === 'uncategorized' ? '#CCCCCC' : colorPalette[index],
                    stack: 'Stack 0',
                    fill: true,     // Fill the area under the line
                    radius: 0,      // Remove the points on the line chart
                }
            })
        }
    }, [payload]);

    // If there is no data, return an empty div
    if (!payload?.yValuesDict) {
        return <></>
    }

    const multiDayTimeframe = cardData?.timeframe?.startDate?.getDate() !== cardData?.timeframe?.endDate?.getDate();
    const nDaysInTimeframe = Math.ceil((cardData?.timeframe?.endDate - cardData?.timeframe?.startDate) / (1000 * 60 * 60 * 24));

    // ChartJS options
    let options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    title: function(tooltipItem){return format(tooltipItem[0].parsed.x, 'dd MMMM yyyy HH:mm', {locale: de})},
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                type: 'time',
                time: {
                    displayFormats: {
                        // Define formats for various time units
                        minute: 'HH:mm',
                        hour: !multiDayTimeframe ? 'HH:mm' : nDaysInTimeframe <= 7 ? 'd.MM-HH:mm' : 'd. MMM',
                        day: 'd. MMM',
                    },
                    unit: cardData?.stepUnit ?? 'hour',
                    unitStepSize: 1,
                },
                ticks: {
                    maxRotation: multiDayTimeframe ? 30 : 50,
                },
                adapters: {
                    date: {
                        locale: de,
                    }
                },
            },
            y: {
                stacked: true,
            },
        },
    }

    // If the chart is normalized, set the y-axis to be between 0 and 1
    if (payload?.options?.normalize) {
        options.scales.y = {
            ...options.scales.y,
            min: 0,
            max: 1,
        }
    }

    return (
        <div className="timelinecard-body">
            { payload?.options?.normalize ?
                <Line className="timeline-chart"
                    data={data}
                    options={options}
                />
            :
                <Bar className="timeline-chart"
                    data={data}
                    options={options}
                />
            }
        </div>
    )
}