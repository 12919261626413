import React from 'react';
import './DeviceParameters.scss';

import Select from 'react-select';

import { useTranslation } from "react-i18next";
import { firestore } from '../../../context/FirebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const DeviceParametersModelWeights = ({ deviceParameters, setDeviceParameters }) => {
    const { i18n, t } = useTranslation();
    const [availableModels, setAvailableModels] = React.useState({"detector": {}, "classifier": {}});
    const modelsData = React.useRef({});

    React.useEffect(() => {
        async function fetchAvailableModels() {
            /* Fetch all available ML models from database to choose from */ 
            const newAvailableModels = {"detector": {}, "classifier": {}};
            const snapshots = await getDocs(collection(firestore, "ML_models"));
            snapshots.forEach((doc) => {
                const data = doc.data();
                modelsData.current[doc.id] = data;
                if (!newAvailableModels[data.model_type][data.model]) {
                    newAvailableModels[data.model_type][data.model] = [];
                }
                newAvailableModels[data.model_type][data.model].push(doc.id);
            });

            setAvailableModels(newAvailableModels);
        }
        fetchAvailableModels();
    }, [])

    function handleModelWeightChange(modelType, model, newValue) {
        /* Update the model weights for the device */
        if (modelType === "detector") {
            setDeviceParameters(prev => {
                return {
                    ...prev,
                    detection_parameters: {
                        ...prev?.detection_parameters,
                        model_weights: {
                            ...prev?.detection_parameters?.model_weights,
                            id: newValue ?? "",
                            filename: modelsData.current[newValue]?.filename ?? "",
                            download_url: modelsData.current[newValue]?.download_url ?? "",
                        }
                    }
                }
            });
        } else if (modelType === "classifier") {
            setDeviceParameters(prev => {
                return {
                    ...prev,
                    classification_parameters: {
                        ...prev?.classification_parameters,
                        model_weights: {
                            ...prev?.classification_parameters?.model_weights,
                            [model]: {
                                id: newValue ?? "",
                                filename: modelsData.current[newValue]?.filename ?? "",
                                download_url: modelsData.current[newValue]?.download_url ?? "",
                            }
                        }
                    }
                }
            });
        }
    }

    function getOptionLabel(id, showNewest=false) {
        /* Get the display element for the model options */
        // If no model is selected show "None"
        if (!id) {
            return (
                <div className="model-option">
                    <p className="empty">None</p>
                </div>
            );
        }

        // If model is selected show the model name and version. Show dot if not newest version
        const showNewerAvailable = showNewest && getLatestModelVersion(modelsData.current[id]?.model) !== id;
        return (
            <div className="model-option">
                {showNewerAvailable && <div className="update-available"></div>}
                <p className="version">V{modelsData.current?.[id]?.version}</p>
                <p className="name">{modelsData.current?.[id]?.name}</p>
            </div>
        )
    }

    function getLatestModelVersion(model) {
        /* Get the latest version of the model */
        const modelVersions = Object.keys(modelsData.current)?.filter((id) => modelsData.current[id]?.model === model);
        if (modelVersions.length === 0) return null;
        return modelVersions.reduce((prev, current) => (prev.version > current.version) ? prev : current);
    }

    function handleDetectorModelChange(e) {
        /* If model has changed, select highest available version for that model */
        handleModelWeightChange("detector", null, getLatestModelVersion(e?.value));
    }

    const selectedDetectorModel = modelsData.current?.[deviceParameters?.detection_parameters?.model_weights?.id]?.model;
    const detectorVersionOptions = React.useMemo(() => {
        /* Get the available versions for the selected detector model */
        const optionsNew = [];
        Object.entries(modelsData.current).forEach(([id, data]) => {
            if (data.model_type !== "detector") return;
            if (selectedDetectorModel && data.model !== selectedDetectorModel) return;

            optionsNew.push({
                value: id,
                label: getOptionLabel(id)
            });
        });
        return optionsNew;
    }, [selectedDetectorModel]);

    return (
        <div className="model-weights">
            <p>Detector</p>
            <div className="detector-model-selection-block">
                <Select 
                    value={selectedDetectorModel ? {
                        value: selectedDetectorModel,
                        label: selectedDetectorModel?.replace(/_/g, " "),
                    } : null}
                    onChange={handleDetectorModelChange}
                    options={Object.keys(availableModels?.detector).map((model) => {
                        return {
                            value: model, 
                            label: model.replace(/_/g, " "),
                        }
                    })} 
                    isClearable={true}
                />
                <Select 
                    value={{
                        value: deviceParameters?.detection_parameters?.model_weights?.id,
                        label: getOptionLabel(deviceParameters?.detection_parameters?.model_weights?.id, true),
                    }}
                    onChange={(e) => {handleModelWeightChange("detector", null, e?.value ?? null)}}
                    options={detectorVersionOptions} 
                    isClearable={true}
                />
            </div>
            <p>Classifier</p>
            {Object.keys(availableModels?.classifier ?? []).map((model, index) => {
                return (
                    <div key={index} className="model-selection-block">
                        <p>{model.replace(/_/g, " ")}</p>
                        <Select 
                            value={{
                                value: deviceParameters?.classification_parameters?.model_weights?.[model]?.id,
                                label: getOptionLabel(deviceParameters?.classification_parameters?.model_weights?.[model]?.id, true),
                            }}
                            onChange={(e) => {handleModelWeightChange("classifier", model, e?.value ?? null)}}
                            options={availableModels.classifier[model].map((id, index) => {
                                return {
                                    value: id, 
                                    label: getOptionLabel(id),
                                }
                            })} 
                            isClearable={true}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default DeviceParametersModelWeights;