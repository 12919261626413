import React from "react";
import "./DeliveryCard.scss";

import { AuthContext } from '../../../context/AuthContext';
import { firestore } from "../../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { doc, onSnapshot, setDoc} from "firebase/firestore";

import BaleView from "../../BaleView/BaleView";
import DeliveryImageView from "../../DeliveryImageView/DeliveryImageView";
import ImageSquare from "../../ImageSquare/ImageSquare";
import PropertyField from "../../PropertyField/PropertyField";
import WeighingSlip from "../../WeighingSlip/WeighingSlip";
import { ReclamationImageView, ReclamationReportButton } from "../../ReclamationModals/ReclamationModals";

// Importing icons
import material_icon    from '../../../assets/icons/lightblue/piechart.png';
import supplier_icon    from '../../../assets/icons/lightblue/delivery.png';
import label_icon       from '../../../assets/icons/lightblue/label.png';
import document_icon    from '../../../assets/icons/lightblue/document.png';
import user_icon        from '../../../assets/icons/lightblue/user.png';
import date_icon        from '../../../assets/icons/lightblue/calendar.png';
import notes_icon       from '../../../assets/icons/lightblue/document.png';
import bales_icon       from '../../../assets/icons/lightblue/bales_stacked.png';
import location_icon    from '../../../assets/icons/lightblue/location.png';
import shapes_icon      from '../../../assets/icons/lightblue/shape.png';
import piechart_icon    from '../../../assets/icons/lightblue/piechart.png';
import color_icon       from '../../../assets/icons/lightblue/color.png';
import alert_icon       from '../../../assets/icons/lightblue/alert.png';
import alert_icon_red   from '../../../assets/icons/alert.png';
import notes_icon_white from '../../../assets/icons/white/document.png';

const iconMapping = {
    "notes": document_icon,
    "user": user_icon,
    "label": label_icon,
    "location": location_icon,
    "bales": bales_icon,
    "shapes": shapes_icon,
    "piechart": piechart_icon,
    "color": color_icon,
}

export default function DeliveryCard({cardData, payload}) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);
    const [delivery, setDelivery] = React.useState({});
    const [showBaleId, setShowBaleId] = React.useState(null);
    const [showDeliveryImageId, setShowDeliveryImageId] = React.useState(null);
    const [showWeighingSlipPopup, setShowWeighingSlipPopup] = React.useState(false);
    const [showReclamationImageId, setShowReclamationImageId] = React.useState(null);
    const unsubscribeDelivery = React.useRef(null);

    // Subscribe to active delivery and load data
    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribeDelivery.current) unsubscribeDelivery.current();

        // Subscribe to active delivery
        const deliveryDocRef = doc(firestore, "clients", currentUser.company.company, "deliveries", payload.deliveryId);
        const unsubscribeNew = onSnapshot(deliveryDocRef, (snapshot) => {
            if (!snapshot.exists || !snapshot.data()) return;
            setDelivery({...snapshot.data(), date: snapshot.data().date.toDate()});
        });
        unsubscribeDelivery.current = () => unsubscribeNew();
    }, [payload.deliveryId]);

    function handleDeliveryFieldChange(event) {
        /** Change value of a delivery field and update on database */
        const { name, value } = event.target;
        const deliveryRef = doc(firestore, "clients", currentUser.company.company, "deliveries", payload.deliveryId);
        setDoc(deliveryRef, {[name]: value}, {merge: true});
    }

    function getPreviousId(collection, currentId) {
        /** Get the id of the previous bale */
        const Ids = Object.keys(collection).sort();
        const currentIndex = Ids.indexOf(currentId);
        if (currentIndex === 0 || currentIndex === -1) return currentId;
        return Ids[currentIndex - 1];
    }

    function getNextId(collection, currentBaleId) {
        /** Get the id of the next bale */
        const baleIds = Object.keys(collection).sort();
        const currentBaleIndex = baleIds.indexOf(currentBaleId);
        if (currentBaleIndex === baleIds.length - 1 || currentBaleIndex === -1) return currentBaleId;
        return baleIds[currentBaleIndex + 1];
    }

    const materialOptions = React.useMemo(() => {
        const newMaterialOptions = {};
        for (const [id, m] of Object.entries(labelsets['materials']['labels'])) {
            newMaterialOptions[id] = m.display_name;
        }
        return newMaterialOptions;
    }, [labelsets]);

    // Render loading screen if reclamation is not loaded
    if (!delivery) {
        return (
            <div className="delivery-card-body">
            </div>
        )
    }

    return (
        <div className="delivery-card-body">
            <div className="delivery-properties-section">
                <div className="delivery-properties">
                    <PropertyField 
                        icon={material_icon} 
                        title={t("property_material").toUpperCase()} 
                        value={delivery?.base_labels?.materials} 
                        options={materialOptions}
                        name={"base_labels.materials"} 
                        type="select"
                        handleChange={handleDeliveryFieldChange} 
                        editable={true}
                        grid_span={1}
                    />
                    <PropertyField 
                        icon={supplier_icon} 
                        title={t("property_supplier").toUpperCase()} 
                        value={delivery.supplier} 
                        name={"supplier"} 
                        type="select"
                        options={payload.deliveriesSettings?.supplier ?? {}}
                        handleChange={handleDeliveryFieldChange} 
                        editable={true}
                        grid_span={2}
                    />
                    <PropertyField 
                        icon={document_icon} 
                        title="WIEGESCHEIN" 
                        value={delivery?.weighingSlipData?.weighing_slip_nr} 
                        name={"weighing_slip_nr"} 
                        type={null}
                        handleChange={handleDeliveryFieldChange} 
                        editable={false}
                        onClick={() => {setShowWeighingSlipPopup(true)}}
                    />
                    <PropertyField 
                        icon={location_icon} 
                        title="LAGER FELD" 
                        value={delivery.storage_field} 
                        name={"storage_field"} 
                        type="select-extendible"
                        handleChange={handleDeliveryFieldChange} 
                        editable={true}
                    />  
                    {Object.entries(payload.deliveriesSettings?.custom_delivery_fields ?? {}).map(([key, value]) => {
                        return (
                            <PropertyField 
                                icon={iconMapping[value.icon]} 
                                title={value.title} 
                                value={delivery.key} 
                                name={key} 
                                type={value.type}
                                handleChange={handleDeliveryFieldChange} 
                                editable={true}
                            />
                        )
                    })}
                    <PropertyField 
                        icon={user_icon} 
                        title={t("property_submitted_by").toUpperCase()} 
                        value={delivery.delivery_submitted_by_user} 
                        name={"delivery_submitted_by"} 
                        type="select"
                        options={payload.deliveriesSettings?.delivery_submitted_by_user ?? {}}
                        handleChange={handleDeliveryFieldChange} 
                        editable={true}
                        grid_span={1}
                    />
                    <PropertyField 
                        icon={date_icon} 
                        title={t("property_date").toUpperCase()} 
                        value={delivery.date} 
                        name="date"
                        type="datetime-local"
                        handleChange={handleDeliveryFieldChange}
                        editable={true}
                    />
                </div>
                <div className="delivery-properties">
                    <PropertyField 
                        icon={notes_icon} 
                        title={t("property_notes").toUpperCase()} 
                        value={delivery.notes} 
                        name="notes"
                        type="textarea"
                        handleChange={handleDeliveryFieldChange}
                        editable={true}
                        grid_span={3}
                    />
                </div>
            </div>

            { /* Reclamation Section */ }
            { delivery?.reclamation_data?.reclamation_status === 'confirmed' &&
            <div className="delivery-reclamation">
                <div className="delivery-reclamation-header">
                    <img className="alert-icon" src={alert_icon_red}/>
                    <p>{`${t("delivery_reclamation_title")} #${delivery.reclamation_data?.reclamation_nr?.toString().padStart(4, '0')}`}</p>
                </div>
                <div className="delivery-images-gallery reclamations-images-gallery">
                    <p className="delivery-images-title">{t("delivery_reclamation_images")}</p>
                    <div className="delivery-images-wrapper">
                        {delivery?.reclamation_data?.reclamation_images ? 
                        Object.entries(delivery?.reclamation_data?.reclamation_images)
                        .sort()
                        .map(([reclamationImageId, reclamationImage]) => {
                            return (
                                <div onClick={() => {setShowReclamationImageId(reclamationImageId)}} key={reclamationImageId}>
                                    <ImageSquare key={reclamationImageId} 
                                        imageUrl={reclamationImage.image_URL}
                                        setIcons={reclamationImage?.notes ? [notes_icon_white] : []}
                                    />
                                </div>
                            )
                        })
                        : <p className="delivery-images-no-images">{t("delivery_no_images")}</p>}
                    </div>
                </div>
                <div className="reclamation-properties">
                    <PropertyField
                        icon={alert_icon}
                        title="PROBLEMPUNKTE"
                        value={delivery?.reclamation_data?.delivery_issues ?? []}
                        name="delivery_issues"
                        type="multiple-choice-select"
                        handleChange={(e) => {
                            handleDeliveryFieldChange({
                                ...e, 
                                target:{"name": "reclamation_data", "value": {[e.target.name]:e.target.value}}
                            })
                        }}
                        editable={true}
                        options={{
                            discrepancy_delivery_note: "Unstimmigkeit Ladegut",
                            wetness: "Feuchtegehalt zu hoch",
                            inadmissible_material: "Unzulässiges Material",
                            unwanted_material: "Unerwünschtes Material",
                            not_all_bales_marked: "Ballen nicht gekennzeichnet",
                        }}
                    />
                    <ReclamationReportButton
                        delivery={delivery}
                    />
                </div>
            </div>}

            {/* Delivery Images */}
            <div className="delivery-images-gallery">
                <p className="delivery-images-title">{t("delivery_registered_images")}</p>
                <div className="delivery-images-wrapper">
                    {delivery?.delivery_images ? Object.entries(delivery?.delivery_images).sort().map(([deliveryImageId, deliveryImage]) => {
                        return (
                            <div onClick={() => {setShowDeliveryImageId(deliveryImageId)}} key={deliveryImageId}>
                                <ImageSquare key={deliveryImageId} 
                                    imageUrl={deliveryImage.image_URL}
                                />
                            </div>
                        )
                    })
                    : <p className="delivery-images-no-images">{t("delivery_no_images")}</p>}
                </div>
            </div>

            { /* Bale Images */}
            <div className="delivery-images-gallery">
                <p className="delivery-images-title">{t("delivery_registered_bales")}</p>
                <div className="bale-images-wrapper">
                    {delivery?.bales && Object.entries(delivery?.bales)
                    .sort((a, b) => {
                        return a[1]?.flagged && !b[1]?.flagged ? -1 : 
                        !a[1]?.flagged && b[1]?.flagged ? 1 : a[0].localeCompare(b[0]);
                    })
                    .map(([baleId, bale]) => {
                        return (
                            <div onClick={() => {setShowBaleId(baleId)}} key={baleId}>
                                <ImageSquare key={baleId} 
                                    imageUrl={bale.image_URL}
                                    setIcons={bale?.flagged ? [alert_icon_red] : []}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* Bale Modal */}
            {showBaleId && <BaleView 
                deliveryId={payload.deliveryId} 
                baleId={showBaleId}
                deliveryImages={delivery?.delivery_images}
                onClose={() => setShowBaleId(null)}
                goToPreviousBale={() => {setShowBaleId(getPreviousId(delivery.bales, showBaleId))}}
                goToNextBale={() => {setShowBaleId(getNextId(delivery.bales, showBaleId))}}
                showDeliveryImage={(deliveryImageId) => {setShowBaleId(null); setShowDeliveryImageId(deliveryImageId)}}
            />}

            {/* Delivery Image Modal */}
            {showDeliveryImageId && <DeliveryImageView 
                deliveryId={payload.deliveryId} 
                deliveryImageId={showDeliveryImageId}
                onClose={() => setShowDeliveryImageId(null)}
                goToPreviousDeliveryImage={() => {setShowDeliveryImageId(getPreviousId(delivery.delivery_images, showDeliveryImageId))}}
                goToNextDeliveryImage={() => {setShowDeliveryImageId(getNextId(delivery.delivery_images, showDeliveryImageId))}}
                showBale={(baleId) => {setShowDeliveryImageId(null); setShowBaleId(baleId)}}
            />}

            {/* Weighing Slip Popup Modal */}
            <WeighingSlip
                show={showWeighingSlipPopup}
                onHide={() => setShowWeighingSlipPopup(false)}
                weighingSlipData={delivery?.weighingSlipData}
                deliveryId={payload.deliveryId}
                handleWeighingSlipFieldChange={(e) => {
                    handleDeliveryFieldChange({
                        ...e, 
                        target:{"name":"weighingSlipData", "value":{[e.target.name]:e.target.value}}
                    })
                }}
            />

            {/* Reclamation Image Modal */}
            {showReclamationImageId && <ReclamationImageView
                delivery={delivery}
                reclamationImageId={showReclamationImageId}
                setReclamationImageId={setShowReclamationImageId}
                handleReclamationFieldChange={(e) => {
                    handleDeliveryFieldChange({
                        ...e, 
                        target:{"name":"reclamation_data", "value":{"reclamation_images": {[e.target.name]:e.target.value}}}
                    })
                }}
            />}
        </div>
    )
}