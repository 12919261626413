import React from "react";
import "./DevicesTable.scss";

import { useTranslation } from "react-i18next";
import { useTable } from 'react-table'

export default function DevicesTable({ devices, setSelectedDevice }) {
    const { i18n, t } = useTranslation();
    const COLUMNS = [
        {
            Header: "Device Name",
            accessor: 'display_name'
        },
        {
            Header: "Device Id",
            accessor: 'device_id'
        },
        {
            Header: "Status",
            accessor: (row) => {
                return (
                    <div className="status-container">
                        <div className={`status ${row.status.toLowerCase().replace(/\s+/g, '_')}`}></div>
                        <p>{row.status}</p>
                    </div>
                )
            }
        },
        {
            Header: "Client",
            accessor: 'client'
        },
        {
            Header: "IP Address",
            accessor: 'local_ip'
        },
    ];

    const columns = React.useMemo(() => COLUMNS, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data:Object.values(devices) })

    if (Object.keys(devices).length === 0) {
        return <div className="devices-table">
            <h3>{t("settings_devices")}</h3>
            <p className="devices-table-empty">{t("settings_no_devices_found")}</p>
            <button className="create-device-button" onClick={() => {setSelectedDevice(null)}}>{t("settings_create_new_device")}</button>
        </div>
    }

    return <div className="devices-table">
        <h3>{t("settings_devices")}</h3>
        <div className="devices-table-wrapper">
            <table {...getTableProps()}>
                <thead className="devices-table-header">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} 
                                onClick={() => {setSelectedDevice(Object.values(devices)[row.index])}}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
        <button className="create-device-button" onClick={() => {setSelectedDevice(null)}}>{t("settings_create_new_device")}</button>
    </div>
}