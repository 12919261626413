import React from "react"
import "./DeliveryImageView.scss"
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext.js';
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../context/FirebaseConfig.js";

import { fetchImageUrl, authAndDownloadImage } from "../../utils/ImgUtils.js"
import ImageSquare from "../ImageSquare/ImageSquare.jsx";
import LoadingIcon from "../LoadingIcon/LoadingIcon.jsx";

import close_icon           from '../../assets/icons/close.svg';
import back_icon_white      from '../../assets/icons/white/back_small.png';
import forward_icon_white   from '../../assets/icons/white/forward_small.png';
import download_icon        from '../../assets/icons/white/download.png';
import bbox_icon            from '../../assets/icons/white/bbox.png';

export default function DeliveryImageView({deliveryId, deliveryImageId, onClose, goToPreviousDeliveryImage, goToNextDeliveryImage, showBale}) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [deliveryImageData, setDeliveryImageData] = React.useState(null);
    const [deliveryImage, setDeliveryImage] = React.useState("");
    const [isImgLoaded, setIsImgLoaded] = React.useState(false);
    const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });
    const [showBboxes, setShowBboxes] = React.useState(true);
    const imgRef = React.useRef(null);
    const unsubscribe = React.useRef(null);

    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribe.current) unsubscribe.current();

        // Subscribe to delivery image document
        const deliveryImageRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "delivery_images", deliveryImageId);
        const newUnsubscribe = onSnapshot(deliveryImageRef, (snapshot) => {
            setDeliveryImageData(snapshot.data());
        });
        unsubscribe.current = () => newUnsubscribe();
    }, [deliveryId, deliveryImageId]);

    React.useEffect(() => {
        /* Load the image from the url */
        setIsImgLoaded(false);
        if (!deliveryImageData?.image_URL) return;

        fetchImageUrl(deliveryImageData?.image_URL).then((url) => {
            setDeliveryImage(url);
        })
    }, [deliveryImageData?.image_URL]);

    function setImageDimensions() {
        /* Set image size to fit the screen depending on aspect ratios */
        if (!imgRef.current) return;

        const WIDTH_BORDER = window.innerWidth*0.2;
        const HEIGHT_BORDER = window.innerHeight*0.25;
        let scale = (window.innerWidth - WIDTH_BORDER) / imgRef.current.naturalWidth;
        if (imgRef.current.naturalHeight * scale > window.innerHeight - HEIGHT_BORDER) {
            scale = (window.innerHeight - HEIGHT_BORDER) / imgRef.current.naturalHeight;
        }
        setImageSize({ width: imgRef.current.naturalWidth * scale, height: imgRef.current.naturalHeight * scale, scale: scale });
    }

    function handleImageLoad() {
        /* Once image is loaded, make it visibale and calculate image dimensions */
        setIsImgLoaded(true);
        setImageDimensions();
    }

    React.useEffect(() => {
        /* Add event listener for window resize to correctly set image size */
        window.addEventListener('resize', setImageDimensions);

        return () => {
            window.removeEventListener('resize', setImageDimensions);
        }
    }, []);

    React.useEffect(() => {
        /* Add event listener for arrow keys to navigate between images */
        function handleKeyDown(event) {
            // Check for left arrow key
            if (event.keyCode === 37) {
                goToPreviousDeliveryImage();
            }
    
            // Check for right arrow key
            if (event.keyCode === 39) {
                goToNextDeliveryImage();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => { document.removeEventListener('keydown', handleKeyDown); }
    }, [deliveryImageId]);

    return (
        <div className="fullscreen-delivery-image-modal">
            <div className="fullscreen-modal--buttons">
                <div className="fullscreen-modal--button fullscreen-modal--next" onClick={goToPreviousDeliveryImage}>
                    <img className="fullscreen-modal--button-icon" src={back_icon_white}/>
                </div>
            </div>
            <div className="fullscreen-modal--center">
                <div className="fullscreen-modal--img-wrapper"
                    style={{ width: imageSize.width, height: imageSize.height }}
                >
                    <img className="fullscreen-modal--img"
                        ref={imgRef}
                        src={deliveryImage}
                        onLoad={handleImageLoad}
                        style={{ display: isImgLoaded ? 'block' : 'none' }}
                    />

                    {/* Show bboxes on image */}
                    { showBboxes && isImgLoaded && deliveryImageData?.detected_bales && 
                    Object.entries(deliveryImageData.detected_bales).map(([baleId, bale], index) => {
                        const [x1, y1, x2, y2, conf, label] = bale.bbox;
                        return (
                            <div key={index} className="delivery-image-view--bale-bbox" style={{
                                left: x1 * imageSize.scale + 'px',
                                top: y1 * imageSize.scale + 'px',
                                width: (x2 - x1) * imageSize.scale + 'px',
                                height: (y2 - y1) * imageSize.scale + 'px',
                                borderColor: bale?.selected ? 'green' : 'red'
                            }} 
                            onClick={() => {}} />
                        )
                    })}

                    {/* Show loading spinner */}
                    { !isImgLoaded &&
                        <LoadingIcon />
                    }
                </div>

                { /* Show detected bales in gallery */}
                {isImgLoaded && deliveryImageData?.detected_bales && <div className="bale-images-wrapper">
                    {Object.entries(deliveryImageData.detected_bales)
                    .sort((a, b) => a[1].selected === b[1].selected ? 0 : a[1].selected ? -1 : 1)
                    .map(([baleId, bale]) => {
                        return (
                            <div onClick={() => {showBale(baleId)}} key={baleId}>
                                <ImageSquare key={baleId} 
                                    imageUrl={bale.image_URL}
                                    setGreyedOut={!bale.selected}
                                />
                            </div>
                        )
                    })}
                </div>}
            </div>
            <div className="fullscreen-modal--buttons">
                <div className="fullscreen-modal--button" onClick={onClose}>
                    <img className="fullscreen-modal--button-icon" src={close_icon}/>
                </div>
                <div className={`fullscreen-modal--button ${showBboxes && "toggled"}`} 
                    onClick={() => {setShowBboxes(prev => !prev)}}
                    title={t("delivery_image_show_bbox")}
                >
                    <img className="fullscreen-modal--button-icon" src={bbox_icon}/>
                </div>
                <div className="fullscreen-modal--button" onClick={() => {
                    authAndDownloadImage(deliveryImageData?.image_URL, `bale_${deliveryImageId}.jpg`)}}>
                    <img className="fullscreen-modal--button-icon" src={download_icon}/>
                </div>
                <div className="fullscreen-modal--button fullscreen-modal--next" onClick={goToNextDeliveryImage}>
                    <img className="fullscreen-modal--button-icon" src={forward_icon_white}/>
                </div>
            </div>
        </div>
    )
}