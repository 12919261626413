import React from "react";
import "./ClientSettings.scss";

import { useTranslation } from "react-i18next";
import { firestore } from '../../context/FirebaseConfig';
import { query, collection, doc, setDoc, getDocs } from 'firebase/firestore';

export default function UsersTable({ client }) {
    const { i18n, t } = useTranslation();
    const [displayName, setDisplayName] = React.useState(client.company_displayname);
    const [lowAccuracyFlags, setLowAccuracyFlags] = React.useState([]);
    const [clientLabelsets, setClientLabelsets] = React.useState([]);

    React.useEffect(() => {
        setDisplayName(client.company_displayname);
        loadLowAccuracyFlags();
    }, [client])

    async function loadClientLabelsets() {
        const labelsetsNew = {};
        const q = query(collection(firestore, "clients", client.company, "labelsets"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            labelsetsNew[doc.id] = doc.data();
        });
        setClientLabelsets(labelsetsNew);
    }

    async function loadLowAccuracyFlags() {
        await loadClientLabelsets();
        if ("flag_low_accuracy" in client) {
            setLowAccuracyFlags(client.flag_low_accuracy);
        } else {
            setLowAccuracyFlags([]);
        }
    }

    function handleLowAccuracyFlagsChange(index, newValue) {
        const newLowAccuracyFlags = lowAccuracyFlags.map((lowAccuracyFlag, attrIndex) => {
          if (index === attrIndex) {
            return newValue;
          }
          return lowAccuracyFlag;
        });
        setLowAccuracyFlags(newLowAccuracyFlags);
    };
    
      function handleAddLowAccuracyFlag() {
        setLowAccuracyFlags([...lowAccuracyFlags, '']); // Append a new empty string for the new lowAccuracyFlag field
    };

    async function onSave() {
        if (!displayName) {
            return;
        }

        await setDoc(doc(firestore, "clients", client.company), {
            company_displayname: displayName,
            flag_low_accuracy: lowAccuracyFlags.filter((flag) => {return flag != ''})
        }, { merge: true });
    }

    return <div className="client-settings">
        <div className="client-settings-header">
            <h3>{client.company_displayname}</h3>
            <button className="client-save-button" onClick={onSave}>{t("settings_save")}</button>
        </div>
        <label>
            {t("settings_client_name")}:
            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
        </label>
        <div className="admin-settings-array">
          <label>{t("settings_client_low_accuracy_flag")}:</label>
          <div className="admin-settings-array-fields">
              {lowAccuracyFlags.map((lowAccuracyFlag, index) => (
                <select value={lowAccuracyFlag} onChange={(e) => handleLowAccuracyFlagsChange(index, e.target.value)}>
                  <option value=""></option>
                  {Object.keys(clientLabelsets).map((labelset) => {
                    return <option value={labelset}>{clientLabelsets[labelset].name}</option>
                  })}
                </select>
              ))}
              <button className="settings-modal-button light-button" onClick={handleAddLowAccuracyFlag}>{t("settings_client_add_low_accuracy_flag")}</button>
          </div>
        </div>
    </div>
}