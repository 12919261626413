import React, { useState } from 'react';
import './AdminSettingsModal.scss';
import ConfirmationModal from './ConfirmationModal.jsx';

import { useTranslation } from "react-i18next";
import { firestore, storage, auth } from '../../context/FirebaseConfig';
import { doc, updateDoc, setDoc, serverTimestamp, getCountFromServer, query, collection, where } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { sendPasswordResetEmail } from 'firebase/auth';
import { fetchImageThumbnailUrl } from "../../utils/ImgUtils.js"
import { format } from "date-fns";

import close_icon from "../../assets/icons/close_black.svg"
import user_icon_default from "../../assets/icons/lightblue/user.png"
import user_upload_icon from "../../assets/icons/blue/upload_image.png"

const UserModal = ({ user, selectedClient, clients, handleClose }) => {
  const { i18n, t } = useTranslation();
  const [isNewUser, setIsNewUser] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState('en');
  const [userFlags, setUserFlags] = useState([]);
  const [userIcon, setUserIcon] = useState(user_icon_default);
  const [newUserIcon, setNewUserIcon] = useState(false);
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showConfirmNewUserModal, setShowConfirmNewUserModal] = useState(false);
  const [showChangeCredentialsModal, setShowChangeCredentialsModal] = useState(false);
  const profilePictureFile = React.useRef(user_icon_default);

  React.useEffect(() => {
    setIsNewUser(user ? false : true);
    if (user) {
      setName(user.displayName);
      setEmail(user.email);
      setLanguage(user.languageCode);
      setCompany(user.company);
      if(user.user_flags) {
        setUserFlags(user.user_flags);
      }
      fetchImageThumbnailUrl(user.photoURL).then((url) => {
        setUserIcon(url);
      })
    } else {
      if (selectedClient.id) {
        setCompany(selectedClient.id);
      } else {
        setCompany(Object.keys(clients)[0]);
      }
    }
  }, [user])

  function handleUserFlagsChange(index, newValue) {
    const newUserFlags = userFlags.map((userFlag, attrIndex) => {
      if (index === attrIndex) {
        return newValue;
      }
      return userFlag;
    });
    setUserFlags(newUserFlags);
  };

  function handleAddUserFlag() {
    setUserFlags([...userFlags, '']); // Append a new empty string for the new userFlag field
  };

  async function handleUploadImage(file) {
    const storageRef = ref(storage, `/profile_photos/${name+new Date().getTime()}`);
    await uploadBytesResumable(storageRef, file).then(() => {
      getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
              setUserIcon(downloadURL);
              setNewUserIcon(true);
          } catch(err) {
              console.log(err);
          }
      });
  });
  };

  async function handleSubmit() {
    if (isNewUser) {
      setShowConfirmNewUserModal(true);
      return;
    } else if (password !== "" || email !== user.email) {
      setShowChangeCredentialsModal(true);
      return;
    }
    saveUser();
  };

  async function emailExists() {
    const firebaseQuery = query(collection(firestore, "users"), where("email", "==", email));
    const countSnapshot = await getCountFromServer(firebaseQuery);
    return (countSnapshot.data().count > 0);
  }
  async function checkSaveAllowed() {
    if (isNewUser) {
      if (!name || !email || !password) {
        setErrorMessage(t("settings_user_error_fields"));
        return false;
      }
      if (await emailExists()) {
        setErrorMessage(t("settings_user_error_email"));
        return false;
      }
      if (password.length < 6) {
        setErrorMessage(t("settings_user_error_pw"));
        return false;
      }
    } else {
      if (!name) {
        setErrorMessage(t("settings_user_error_fields"));
        return false;
      }
      if (email !== user.email && await emailExists()) {
        setErrorMessage(t("settings_user_error_email"));
        return false;
      }
      if (password && password.length < 6) {
        setErrorMessage(t("settings_user_error_pw"));
        return false;
      }
    }

    return true;
  }
  async function changeCredentials() {
    const userChanges = {email: email};
    if (password && password.length >= 6) {
      userChanges["password"] = password;
    }
    await setDoc(doc(firestore, "admin_function_calls", "function_types", "change_user", format(new Date(), "yyyy-MM-dd_HH-mm-ss")), {
      uid: user.uid,
      userChanges: userChanges,
    });
  }
  async function saveUser() {
    if (!await checkSaveAllowed()) {
      return;
    }

    if (isNewUser) {
      let photoURL = userIcon;
      if (!newUserIcon) {
        const response = await fetch(user_icon_default);
        const blob = await response.blob();
        const storageRef = ref(storage, `/profile_photos/${name+new Date().getTime()}`);
        await uploadBytesResumable(storageRef, blob).then(async () => {
          await getDownloadURL(storageRef).then(async (downloadURL) => {
              try {
                  photoURL = downloadURL;
              } catch(err) {
                  console.log(err);
              }
          });
        });
      }

      const newUser = {
        displayName: name,
        email: email,
        languageCode: language,
        company: company,
        user_flags: userFlags.filter((flag) => {return flag != ''}),
        photoURL: photoURL,
        status: "active",
        createdAt: serverTimestamp(),
      };

      await setDoc(doc(firestore, "admin_function_calls", "function_types", "create_user", format(new Date(), "yyyy-MM-dd_HH-mm-ss")), {
        email: email,
        password: password,
        displayName: name,
        userDoc: newUser,
      })

    } else {  
      const newUser = {
        displayName: name,
        languageCode: language,
        company: company,
        user_flags: userFlags.filter((flag) => {return flag != ''}),
      };

      if (newUserIcon) {
        newUser["photoURL"] = userIcon;
      }

      await updateDoc(doc(firestore, 'users', user.uid), newUser);

      if (email !== user.email || password) {
        await changeCredentials();
      }
    }
    handleClose();
  };

  async function handleResetPassword() {
    sendPasswordResetEmail(auth, email).then(() => {
      console.log('Password reset email sent.');
    })
    .catch((error) => {
      console.error('Error sending password reset email: ', error);
    });
  };

  return (
    <div className="admin-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{isNewUser ? t("settings_create_new_user") : t("settings_edit_user")}</h2>
          <div className="modal-close-button" onClick={handleClose}>
              <img className="modal-close-button-icon" src={close_icon}/>
          </div>
        </div>

        <input type='file' id='file' ref={profilePictureFile} style={{display: 'none'}} onChange={(e) => {handleUploadImage(e.target.files[0])}}/>
        <div className="modal-profile-picture">
          <img className="modal-icon" src={userIcon}/>
          <div className="modal-profile-picture-upload" onClick={()=>{profilePictureFile.current.click()}}>
            <img className="modal-upload-icon" src={user_upload_icon}/>
          </div>
        </div>

        <div className="label-container">
          <label>{t("settings_user_company")}:</label>
          <select value={company} onChange={(e) => setCompany(e.target.value)}>
            { Object.keys(clients).map(id => {
                return <option value={id}>{clients[id].company_displayname}</option>
            }) }
          </select>
        </div>

        <div className="label-container">
          <label>{t("settings_user_name")}:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="label-container">
          <label>{t("settings_user_language")}:</label>
          <select value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value="de">Deutsch</option>
            <option value="en">English</option>
          </select>
        </div>

        <div className="admin-settings-array">
          <label>{t("settings_user_flags")}:</label>
          <div className="admin-settings-array-fields">
              {userFlags.map((userFlag, index) => (
                <select value={userFlag} onChange={(e) => handleUserFlagsChange(index, e.target.value)}>
                  <option value=""></option>
                  <option value="manager">manager</option>
                  <option value="labeler">labeler</option>
                  <option value="admin">admin</option>
                  <option value="beta">beta</option>
                  <option value="observer">observer</option>
                </select>
              ))}
              <button className="settings-modal-button light-button" onClick={handleAddUserFlag}>{t("settings_user_add_flag")}</button>
          </div>
        </div>

        <div className="label-container">
          <label>{t("settings_user_email")}:</label>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

         <div className="label-container">
          <label>{t("settings_user_password")}:</label>
          <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
          {!isNewUser && <button className="settings-modal-button light-button" onClick={() => setShowResetPasswordModal(true)}>{t("settings_user_reset_password")}</button>}
        </div>

        {!isNewUser && <p className="modal-fixed-fields">UID: {user.uid}</p>}

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="save-delete-buttons">
            <button className="settings-modal-button light-button" onClick={handleSubmit}>{t("settings_save")}</button>
            {/*<button className="settings-modal-button delete-button" onClick={() => {}}>Delete</button>*/}
        </div>
      </div>

      {showConfirmNewUserModal && <ConfirmationModal 
        title={t("settings_create_new_user")}
        message={t("settings_user_confirm_create")}
        handleSubmit={saveUser}
        handleClose={() => setShowConfirmNewUserModal(false)}
      />}
      {showResetPasswordModal && <ConfirmationModal 
        title={t("settings_user_reset_password")}
        message={t("settings_user_reset_password_message")}
        handleSubmit={handleResetPassword}
        handleClose={() => setShowResetPasswordModal(false)}
      />}
      {showChangeCredentialsModal && <ConfirmationModal 
        title={t("settings_user_change_credentials")}
        message={t("settings_user_change_credentials_message")}
        handleSubmit={saveUser}
        handleClose={() => setShowChangeCredentialsModal(false)}
      />}
    </div>
  );
};

export default UserModal;
