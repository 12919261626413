import React from "react"
import "./DeviceStatusCard.scss"
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import { getDeviceStatus } from "./DeviceStatus";
import { de } from 'date-fns/locale';
import { format } from 'date-fns';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

export default function DeviceStatusCard({ cardData }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [ deviceStatus, setDeviceStatus ] = React.useState({x:[], y:[]});
    const unsubscribeDeviceStatus = React.useRef(null);

    async function getDeviceStatusData() {
        if (unsubscribeDeviceStatus.current){
            unsubscribeDeviceStatus.current();
        }

        const newUnsubscribeDeviceStatus = getDeviceStatus(currentUser.company.company, currentUser.device_id, cardData.timeframe, setDeviceStatus);
        unsubscribeDeviceStatus.current = () => newUnsubscribeDeviceStatus();
    }
    React.useEffect(() => {
        getDeviceStatusData();
    }, [cardData.timeframe.startDate.getTime(), cardData.timeframe.endDate.getTime()]);

    // Convert status values to numbers for chart
    const yValuesConverted = React.useMemo(() => {return deviceStatus.y.map((status) => typeof status === 'number' ? status : status==='Online' ? 1 : status==='Offline' ? 0 : 0.2)}, [deviceStatus.y]);

    function getUptimePercentage(x, y) {
        /* Returns the percentage of time the device was online */
        const totalTimespan = x[x.length - 1] - x[0];

        let timespanWithValueOne = 0;
        for (let i = 0; i < x.length - 1; i++) {
            if (y[i] === 1) {
                timespanWithValueOne += x[i + 1] - x[i];
            }
        }

        const percentage = (timespanWithValueOne / totalTimespan) * 100;
        return percentage;
    }
    const uptimePercentage = React.useMemo(() => getUptimePercentage(deviceStatus.x, yValuesConverted), [deviceStatus.x, yValuesConverted]);

    const data = {
        labels: deviceStatus.x,
        datasets: [
            {
                fill: true,
                label: 'online',
                data: yValuesConverted.map((status) => status===0 ? 0.01 : status), // change zeros to 0.01 for better visibility
                pointBorderColor:       yValuesConverted.map((status) => status==1 ? 'rgb(160, 235, 50)' : status==0 ? 'rgba(200, 50, 50)' : 'rgba(200, 150, 50)'),
                pointBackgroundColor:   yValuesConverted.map((status) => status==1 ? 'rgb(160, 235, 50)' : status==0 ? 'rgba(200, 50, 50)' : 'rgba(200, 150, 50)'),
                borderColor: (context) => {
                    /* Return a gradient */
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;
            
                    if (!chartArea) {
                      // This case happens on initial chart load
                      return;
                    }

                    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                    gradient.addColorStop(0, "rgb(200, 50, 50");
                    gradient.addColorStop(0.3, "rgb(160, 235, 50)");
                    return gradient;
                },
                backgroundColor:        'rgba(160, 235, 50, 0.3)',
                borderWidth: 2,
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point:{
                radius: 0.5
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function(tooltipItem){return format(tooltipItem[0].parsed.x, 'dd MMMM yyyy HH:mm', {locale: de})},
                    label: (tooltipItem) => {return tooltipItem.raw==1 ? "Online" : tooltipItem.raw==0.01 ? "Offline" : "Detection Offline"}
                },
            },
        },
        scales: {
            y: {
                min: 0,
                max: 1.1,
                ticks: {
                    display: false,
                    callback: (value, index, ticks) => {
                        return  value == 0 ? "Offline" :
                                value == 1 ? "Online" :
                                ""
                    }
                }
            },
            x: {
                type: 'time',
                min: cardData.timeframe.startDate,
                max: cardData.timeframe.endDate,
                time: {
                    displayFormats: {
                        // Define formats for various time units
                        minute: 'HH:mm',
                        hour: 'HH:mm',
                        day: 'd. MMM',
                    },
                },
                adapters: {
                    date: {
                        locale: de,
                    }
                },
            },
        },
        
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
    };

    return (
        <div className="devicestatus-body">
            <p>{t("devicestatus_percentage")} {uptimePercentage.toFixed(1)}%</p>
            <dev className="devicestatus-chart-wrapper">
                <Line className="devicestatus-chart" options={options} data={data}/>
            </dev>
        </div>
    )
}