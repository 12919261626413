import React from "react";
import "./UsersTable.scss";

import { useTranslation } from "react-i18next";
import { useTable } from 'react-table'
import { format } from "date-fns";

export default function UsersTable({ users, setSelectedUser }) {
    const { i18n, t } = useTranslation();
    const COLUMNS = [
        {
            Header: "Name",
            accessor: 'displayName',
        },
        {
            Header: "ID",
            accessor: 'uid',
        },
        {
            Header: "Email",
            accessor: 'email',
        },
        {
            Header: t("settings_user_last_login"),
            accessor: (row) => {
                let date = "";
                if (row.last_login) {
                    date = format(row.last_login.toDate(), "dd. MMM yyyy");
                }
                return date;
            }
        }
    ];

    // Create the table entries. Exclude users with device flag -> handle them over device settings to avoid mistakes
    const columns = React.useMemo(() => COLUMNS, []);
    const usersFiltered = React.useMemo(() => {
        return Object.values(users)
            .filter((user) => !user?.user_flags?.includes("device") ?? true)
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
    }, [users]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ 
        columns, 
        data: usersFiltered,
    })

    return <div className="users-table">
        <h3>{t("settings_users")}</h3>
        <div className="users-table-wrapper">
            <table {...getTableProps()}>
                <thead className="users-table-header">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} 
                                onClick={() => {setSelectedUser(usersFiltered[row.index])}}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
        <button className="create-user-button" onClick={() => {setSelectedUser(null)}}>{t("settings_create_new_user")}</button>
    </div>
}