import React from "react";
import "./WeighingSlip.scss";

import { Modal, Button } from 'react-bootstrap';

import PropertyField from "../PropertyField/PropertyField";
import { AuthContext } from '../../context/AuthContext';

// Importing icons
import supplier_icon        from '../../assets/icons/lightblue/delivery.png';
import notes_icon           from '../../assets/icons/lightblue/document.png';
import license_plate_icon   from '../../assets/icons/lightblue/license_plate.png';
import label_icon           from '../../assets/icons/lightblue/label.png';
import weight_icon          from '../../assets/icons/lightblue/weight.png';

export default function WeighingSlip({ deliveryId, weighingSlipData, show, onHide, handleWeighingSlipFieldChange }) {
    const { currentUser } = React.useContext(AuthContext);

    function getWeighingSlip() {
        return (
            <div className="weighing-slip">
                <div className="weighing-slip-properties">
                    <PropertyField 
                        icon={notes_icon} 
                        title="WIEGESCHEIN NR" 
                        value={weighingSlipData?.weighing_slip_nr} 
                        name={"weighing_slip_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={supplier_icon} 
                        title="LIEFERANTEN NR" 
                        value={weighingSlipData?.supplier_nr} 
                        name={"supplier_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={supplier_icon} 
                        title="LIEFERANTEN NAME" 
                        value={weighingSlipData?.supplier_name} 
                        name={"supplier_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={label_icon} 
                        title="BESTELL NR" 
                        value={weighingSlipData?.order_nr} 
                        name={"order_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={license_plate_icon} 
                        title="KENNZEICHEN LKW" 
                        value={weighingSlipData?.vehicle_nr} 
                        name={"vehicle_nr"} 
                        type="text"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title="1. WÄGUNG" 
                        value={weighingSlipData?.weight_kg_first} 
                        name={"weight_kg_first"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title="2. WÄGUNG" 
                        value={weighingSlipData?.weight_kg_second} 
                        name={"weight_kg_second"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title="NETTO I" 
                        value={weighingSlipData?.weight_kg_netto_1} 
                        name={"weight_kg_netto_1"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                    <PropertyField 
                        icon={weight_icon} 
                        title="NETTO II" 
                        value={weighingSlipData?.weight_kg_netto_2} 
                        name={"weight_kg_netto_2"} 
                        type="number"
                        handleChange={handleWeighingSlipFieldChange} 
                        editable={false}
                    />
                </div>
            </div>
        )
    }    

    return (
        <Modal 
            show={show}
            onHide={onHide}
            backdrop = "static"
            className = "modal"
        >
            <div className={"modal-box weighing-slip-modal-box"}>
                {<>
                    {getWeighingSlip()}
                    <Modal.Footer>
                        <Button className="modal-button" variant="secondary" onClick={onHide}>
                            Schliessen
                        </Button>
                    </Modal.Footer>
                </>}
            </div>
        </Modal>
    )
}