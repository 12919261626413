import React from "react"
import "./DaySummaryCard.scss"
import { useTranslation } from "react-i18next";

// Import Logo
import weightIcon from "../../../assets/icons/lightblue/weight.png"
import balesIcon from "../../../assets/icons/lightblue/shape.png"
import materialIcon from "../../../assets/icons/lightblue/piechart.png"

export default function DaySummaryCard({ cardData, payload }) {
    const { i18n, t } = useTranslation();

    function statComponent(icon, title, value) {
        return (
            <div className="daysummary--stat">
                <img src={icon}/>
                <div className="daysummary--stat-right">
                    <p className="daysummary--stat-title">{title}</p>
                    <p className="daysummary--stat-value">{value}</p>
                </div>
            </div>
        )
    }

    const processedMaterialsString = payload.processedMaterials.map(material => t(material)).join(", ");

    return(
        <div className="daysummarycard-body">
            {statComponent(weightIcon, t("daysummary_weight"), `${Math.round(payload.totalWeight/100)/10} ${t("daysummary_tons")}`)}
            {statComponent(balesIcon, t("daysummary_num_bales"), `${payload.totalCount} ${t("daysummary_bales")}`)}
            {statComponent(materialIcon, t("daysummary_material"), processedMaterialsString)}
        </div>
    )
}