import React from 'react'
import './MaterialFlow.scss';
import { useTranslation } from "react-i18next";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from '../../context/FirebaseConfig';
import { getDocs, query, collection, startAfter, orderBy, where, limit } from 'firebase/firestore'
import { subHours } from 'date-fns';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

import MaterialFlowView from './MaterialFlowView.jsx';
import ObservationGallery from '../FullscreenObservationModal/ObservationGallery.jsx';
import LabelingPanel from '../FullscreenObservationModal/LabelingPanel.jsx';

import closeIcon from "../../assets/icons/close.svg"
import openPanelIcon from "../../assets/icons/white/back_small.png"
import imageIcon from "../../assets/icons/white/image.png"

export default function MaterialFlow({ timestamp }) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);
    const [materialFlowData, setMaterialFlowData] = React.useState([]);
    const [baleFeedData, setBaleFeedData] = React.useState({});
    const [anchorTimestamp, setAnchorTimestamp] = React.useState(null);
    const [activeObservation, setActiveObservation] = React.useState(null);
    const [showLabelingPanel, setShowLabelingPanel] = React.useState(true);
    const [galleryView, setGalleryView] = React.useState(false);
    const loadingNewSegment = React.useRef(false);

    const materialFlowViewRef = React.useRef();

    React.useEffect(() => {
        if (timestamp) {
            // If timestamp is passed, start with the given timestamp
            getMaterialFlowDataWithBlocking(new Date(timestamp), "right", true);
        } else {
            // If no timestamp is given, start with the last hour
            getMaterialFlowDataWithBlocking(subHours(new Date(), 1), "right", true);
        }
    }, []);

    async function getMaterialFlowData(date, appendDirection, initialize=false) {
        if (initialize) {
            setMaterialFlowData([]);
            setBaleFeedData({});
            setAnchorTimestamp(date);
            materialFlowViewRef.current.resetPositionTimestamps();
            materialFlowViewRef.current.resetBaleFeedPositionTimestamps();
        }

        let q = collection(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "material_flow_timestamps");
        if (materialFlowData.length === 0 || initialize) {
            q = query(q,
                where("start_timestamp", "<=", date),
                orderBy("start_timestamp", "desc"),
                limit(1),
            );
        } else if (appendDirection === "right") {
            q = query(q,
                where("start_timestamp", "<=", date),
                orderBy("start_timestamp", "desc"),
                startAfter(materialFlowData[materialFlowData.length - 1].snapshot),
                limit(1),
            );
        } else if (appendDirection === "left") {
            q = query(q,
                where("start_timestamp", ">=", date),
                orderBy("start_timestamp", "asc"),
                startAfter(materialFlowData[0].snapshot),
                limit(1),
            );
        } else {
            return;
        }
        
        const snapshot = await getDocs(q);
        if (snapshot.empty || snapshot.docs.length === 0) {
            return;
        }
        const docSnapshot = snapshot.docs[0];
        const docData = docSnapshot.data();
        if (docSnapshot.id in materialFlowData.map((d) => d.id)) {
            console.log("Already loaded")
            return;
        }
        if (!("image_dimensions" in docData)) {
            // If this attribute is missing, it most likely means that the material-flow-node was cut and not shut down properly -> the image was not saved
            // In this situation, just look at the timestamps to get the width. No image will be displayed in this section.
            console.log("No image dimensions");
            const positions = Object.keys(docData.position_timestamps);
            docData.image_dimensions = {width: parseInt(positions[positions.length-1]), height: materialFlowData[0].data.image_dimensions.height};
            if (!("image_url" in docData)) {
                docData.image_url = "";
            }
        }
        
        // Calculate the offset to append the new image
        let docOffset = 0;
        if (materialFlowData.length > 0 && !initialize && appendDirection === "right") {
            const edgeDoc = materialFlowData[materialFlowData.length - 1];
            docOffset = edgeDoc.offset + edgeDoc.data.image_dimensions.width;
        } else if (materialFlowData.length > 0  && !initialize && appendDirection === "left") {
            const edgeDoc = materialFlowData[0];
            docOffset = edgeDoc.offset - docData.image_dimensions.width;
        }

        // Convert the document pixel-positions (counted from the right) to the display pixel-positions (counted from the left) and shift them by the offset
        const positionTimestampsShifted = {};
        Object.entries(docData.position_timestamps).forEach(([pixel, time]) => {
            const convertedPixelPos = docData.image_dimensions.width - parseInt(pixel);
            positionTimestampsShifted[convertedPixelPos + docOffset] = time;
        });
        materialFlowViewRef.current.appendPositionTimestamps(positionTimestampsShifted);

        // Convert the document bale-feed-pixel-positions (counted from the right) to the display bale-feed-pixel-positions (counted from the left) and shift them by the offset
        if ("bale_feed_position_timestamps" in docData) {
            const baleFeedPositionTimestampsShifted = {};
            Object.entries(docData.bale_feed_position_timestamps).forEach(([pixel, time]) => {
                const convertedPixelPos = docData.image_dimensions.width - parseInt(pixel);
                baleFeedPositionTimestampsShifted[convertedPixelPos + docOffset] = time;
            });
            materialFlowViewRef.current.appendBaleFeedPositionTimestamps(baleFeedPositionTimestampsShifted);
        }

        materialFlowViewRef.current.setOriginalImageHeight(docData.image_dimensions.height);

        // Append the new data
        const appendMaterialFlowData = {
            snapshot: docSnapshot, 
            data: docData, 
            id: docSnapshot.id,
            offset: docOffset,
        }
        if (appendDirection === "right") {
            setMaterialFlowData(prev => { return [...prev, appendMaterialFlowData] });
        } else if (appendDirection === "left") {
            setMaterialFlowData(prev => { return [appendMaterialFlowData, ...prev] });
        }

        // Find the first and last bale-feed-timestamp and load the supplier information in that timeframe
        const baleFeedtimestamps = Object.values(docData.bale_feed_position_timestamps).map((timestamp) => timestamp.toDate().getTime());
        const minBaleFeedTimestamp = new Date(Math.min(...baleFeedtimestamps));
        const maxBaleFeedTimestamp = new Date(Math.max(...baleFeedtimestamps));
        loadBaleFeedData(minBaleFeedTimestamp, maxBaleFeedTimestamp);
    }

    function getMaterialFlowDataWithBlocking(date, appendDirection, initialize = false) {
        if (loadingNewSegment.current) {
            return;
        }
        loadingNewSegment.current = true;
        getMaterialFlowData(date, appendDirection, initialize).finally(() => {
            if (initialize) {
                // TODO: Handle case when data is not yet loaded
                materialFlowViewRef.current.setOffsetToTimestamp(date);
            }
            loadingNewSegment.current = false;
        });
    }

    function appendMaterialFlowData(appendDirection) {
        /** Load and append material-flow-data to 'left' or 'right' of current data */
        getMaterialFlowDataWithBlocking(anchorTimestamp, appendDirection, false);
    }

    function initializeMaterialFlowData(date) {
        /** Load and initialize material-flow-data at the given date */
        setActiveObservation(null);
        getMaterialFlowDataWithBlocking(date, "right", true);
    }

    async function loadBaleFeedData(minDate, maxDate) {
        let baleFeedDataNew = {};
        let lastDoc = null;
        while (!lastDoc || lastDoc.data()?.end_timestamp.toDate().getTime() <= maxDate.getTime()) {
            let q = query(
                collection(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps"),
                where("end_timestamp", ">", minDate),
                orderBy("end_timestamp", "asc"),
                limit(1),
            )
            if (lastDoc) {
                q = query(q, startAfter(lastDoc));
            }

            const snapshot = await getDocs(q);
            if (snapshot.empty || snapshot.docs.length === 0) {
                break;
            }
            lastDoc = snapshot.docs[0];
            baleFeedDataNew[lastDoc.id] = lastDoc.data();
        }
        setBaleFeedData(prev => {return {...prev, ...baleFeedDataNew}});
    }

    return (
        <div className="material-flow">
            <MaterialFlowView
                ref={materialFlowViewRef}
                materialFlowData={materialFlowData}
                baleFeedData={baleFeedData}
                appendMaterialFlowData={appendMaterialFlowData}
                initializeMaterialFlowData={initializeMaterialFlowData}
                activeObservation={activeObservation}
                setActiveObservation={setActiveObservation}
            />
            {activeObservation && showLabelingPanel && <div className="overlay-panel" style={{width: galleryView ? "100%" : "auto"}}>
                {galleryView && <ObservationGallery
                    observation={activeObservation}
                    getNext={() => {materialFlowViewRef.current.jumpToNextObservation("right")}}
                    getPrevious={() => {materialFlowViewRef.current.jumpToNextObservation("left")}}
                />}
                <div className="labeling-panel-container">
                    <div className="top-buttons-container">
                        <div className={`show-image-labeling-button ${galleryView ? "show-image-active" : ""}`} onClick={() => {setGalleryView(prev => !prev)}}>
                            <img className="show-image-labeling-button-icon" src={imageIcon}/>
                        </div>
                        <div className="close-labeling-button" onClick={() => {setShowLabelingPanel(false)}}>
                            <img className="close-labeling-button-icon" src={closeIcon}/>
                        </div>
                    </div>
                    <LabelingPanel 
                        observation={activeObservation}
                        getNext={() => {materialFlowViewRef.current.jumpToNextObservation("right")}}
                    />
                </div>
            </div>}
            {activeObservation && !showLabelingPanel && <div className="open-labeling-button" onClick={() => {setShowLabelingPanel(true)}}>
                <img className="open-labeling-button-icon" src={openPanelIcon}/>
            </div>}
        </div>
    );
};
