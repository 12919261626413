import React from "react";
import "./css/Card.scss";
import { PieChart, Pie, Tooltip } from "recharts";
import { getColorPalette } from "../../utils/colorPaletteUtils";

export default function PiechartCard({ cardData, payload }) {
    const chartData = [];
    const nSegments = payload.chartData ? payload.chartData.length : 0;
    const colorPalette = getColorPalette(nSegments);
    for (let i = 0; i < nSegments; i++) {
        chartData.push({
            name: payload.chartData[i].name,
            value: payload.chartData[i].value,
            fill: colorPalette[i],
        })
    }

    if (nSegments == 0) {
        chartData.push({
            name: "",
            value: 1,
            fill: `rgb(200,200,200)`
        })
    }

    return (
        <div className="piechartcard-body">
            <PieChart className="piechart" width={300} height={200}>
                <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={70}
                    innerRadius={30}
                    label={(value) => `${(value.percent * 100).toFixed(1)} %`}
                />
                <Tooltip
                    formatter={payload.tooltipFormatting ? payload.tooltipFormatting : ""}
                    contentStyle={{
                        "backgroundColor": "rgba(255,255,255,0.7)",
                        "padding": "0 1rem",
                        "borderRadius": "0.5rem",
                    }}
                />
            </PieChart>
        </div>
    )
}