import React from 'react'
import './MaterialFlowView.scss';
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext';
import { subSeconds, addSeconds, subHours } from "date-fns";
import inobounce from "inobounce"

import LoadingScreen from '../LoadingScreen.jsx';
import MaterialFlowScrollInterface from './MaterialFlowScrollInterface.jsx';
import MaterialFlowImg from './MaterialFlowImg.jsx';
import ObservationsLayer from './ObservationsLayer.jsx';
import BBoxLabeler from './BBoxLabeler.jsx';
import { getColorPalette } from '../../utils/colorPaletteUtils.js';

import bbox_icon from '../../assets/icons/white/bbox.png';

const MaterialFlowView = React.forwardRef(({ materialFlowData, baleFeedData, appendMaterialFlowData, initializeMaterialFlowData, activeObservation, setActiveObservation, setMaterialFlowData }, ref) => {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);
    const containerRef = React.useRef(null);
    const viewRef = React.useRef(null);
    const [displayWidth, setContainerWidth] = React.useState(0);
    const [displaySizeOffset, setDisplaySizeOffset] = React.useState(0);
    const [scrollTimestamp, setScrollTimestamp] = React.useState(subHours(new Date(), 1));
    const [enableBboxDisplay, setEnableBboxDisplay] = React.useState(false);
    const positionTimestamps = React.useRef({});
    const baleFeedPositionTimestamps = React.useRef({});
    const scrollAnimation = React.useRef(false);
    const loadingNewSegment = React.useRef(false);
    const lastEdgeLoadingTimer = React.useRef(null);
    const displayImageHeight = React.useRef(500);
    const originalImageHeight = React.useRef(0);
    const wheelScroolSpeed = React.useRef(2);
    const touchScrollAnchor = React.useRef(0);
    const observationsLayerRef = React.useRef();

    const LOADING_PADDING_SEC = 900;

    inobounce.enable(); // Enable inobounce to prevent scrolling on iOS

    React.useEffect(() => {
        // Function to update the container width
        const updateContainerWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        updateContainerWidth(); // Update width on mount

        // Add event listener for window resize
        window.addEventListener('resize', updateContainerWidth);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', updateContainerWidth);
        };
    }, [containerRef.current]);

    // Pre-compute full-size pixel position for each bale-feed entry (keep in mind: start is to the right of image since conveyor moves to the left)
    const baleFeedFullSizePos = React.useMemo(() => {
        const baleFeedFullSizePosNew = {};
        Object.entries(baleFeedData).forEach(([id, baleFeedEntry]) => {
            const fullSizePosRight = interpolateFullSizePosForTimestamp(baleFeedEntry.start_timestamp.toDate(), true);
            const fullSizePosLeft = interpolateFullSizePosForTimestamp(baleFeedEntry.end_timestamp.toDate(), true);

            // If both timestamps are null, then check whether to keep or remove the entry
            if (fullSizePosLeft === null && fullSizePosRight === null) {
                // Check if it is out-of-bounds (both timestamps to the left/right) or overarching entire timespan (one left, one right)
                const minTimestamp = Math.min(...Object.values(baleFeedPositionTimestamps.current).map((timestamp) => timestamp.toDate().getTime()));
                const maxTimestamp = Math.max(...Object.values(baleFeedPositionTimestamps.current).map((timestamp) => timestamp.toDate().getTime()));
                if (baleFeedEntry.start_timestamp.toDate().getTime() > maxTimestamp && baleFeedEntry.end_timestamp.toDate().getTime() > maxTimestamp) {
                    return;
                } else if (baleFeedEntry.start_timestamp.toDate().getTime() < minTimestamp && baleFeedEntry.end_timestamp.toDate().getTime() < minTimestamp) {
                    return;
                }
            }

            baleFeedFullSizePosNew[id] ={
                "right": fullSizePosRight,
                "left": fullSizePosLeft,
            }
        });
        return baleFeedFullSizePosNew;
    }, [materialFlowData, baleFeedData]);

    function scrollImage (direction, scrollAmount) {
        // Function to handle scrolling, for example, based on user input. Scroll without animation.
        scrollAmount = scrollAmount * (displayImageHeight.current / originalImageHeight.current);
        scrollAnimation.current = false;
        setDisplaySizeOffset((currentOffset) => currentOffset + (direction === 'left' ? -scrollAmount : scrollAmount));
    };

    function findBracketingPixelsForTimestamp(timestamp, useBaleFeed=false) {
        let lessThanKey = null;
        let greaterThanKey = null;
        let targetTime = timestamp.getTime();
        const usedPositionTimestamps = useBaleFeed ? baleFeedPositionTimestamps.current : positionTimestamps.current;
        
        Object.entries(usedPositionTimestamps).forEach(([pixel, time]) => {
            const pixelTime = time.toDate().getTime();
            if (pixelTime <= targetTime && (!lessThanKey || pixelTime > usedPositionTimestamps[lessThanKey].toDate().getTime())) {
                lessThanKey = pixel;
            }
            if (pixelTime >= targetTime && (!greaterThanKey || pixelTime < usedPositionTimestamps[greaterThanKey].toDate().getTime())) {
                greaterThanKey = pixel;
            }
        });

        return [parseInt(lessThanKey), parseInt(greaterThanKey)];
    }

    function findBracketingPixelsForOffset(offsetRight) {
        let lessThanKey = null;
        let greaterThanKey = null;

        Object.keys(positionTimestamps.current).forEach((pixel) => {
            const pixelInt = parseInt(pixel);
            if (pixelInt <= offsetRight && (!lessThanKey || pixelInt > lessThanKey)) {
                lessThanKey = pixelInt;
            }
            if (pixelInt >= offsetRight && (!greaterThanKey || pixelInt < greaterThanKey)) {
                greaterThanKey = pixelInt;
            }
        });

        return [lessThanKey, greaterThanKey];
    }

    function linearInterpolate(x, x0, x1, y0, y1) {
        /* Linear interpolation: Given the points (x0, y0) and (x1, y1), find the y value for x */
        if (x0 === x1) {
            return (y0 + y1) / 2;
        }
        return ((x - x0) / (x1 - x0)) * (y1 - y0) + y0;
    }

    function interpolateFullSizePosForTimestamp(timestamp, useBaleFeed=false) {
        const [lessThanKey, greaterThanKey] = findBracketingPixelsForTimestamp(timestamp, useBaleFeed);

        if (lessThanKey === null || greaterThanKey === null || isNaN(lessThanKey) || isNaN(greaterThanKey)) {
            // Handle edge cases where you can't interpolate (e.g., the timestamp is out of bounds)
            return null;
        }

        const usedPositionTimestamps = useBaleFeed ? baleFeedPositionTimestamps.current : positionTimestamps.current;
        const targetTime = timestamp.getTime();
        const lessThanTime = usedPositionTimestamps[lessThanKey].toDate().getTime();
        const greaterThanTime = usedPositionTimestamps[greaterThanKey].toDate().getTime();

        // Interpolate to find the estimated pixel position
        const interpolatedPixel = linearInterpolate(targetTime, lessThanTime, greaterThanTime, lessThanKey, greaterThanKey);
        return interpolatedPixel;
    }

    function interpolateTimestampForFullSizePos(pos) {
        const [lessThanKey, greaterThanKey] = findBracketingPixelsForOffset(pos);

        let interpolatedTimeMs = null;
        if (lessThanKey === null && greaterThanKey === null) {
            // No data available
            return null;
        } else if (lessThanKey === null) {
            // Case when going beyond the edge of the image
            interpolatedTimeMs = positionTimestamps.current[greaterThanKey].toDate().getTime();
        } else if (greaterThanKey === null) {
            // Case when going beyond the edge of the image
            interpolatedTimeMs = positionTimestamps.current[lessThanKey].toDate().getTime();
        } else {
            // Interpolate between the two closest timestamps
            const ratio = (pos - lessThanKey) / (greaterThanKey - lessThanKey);
            const lessThanTime = positionTimestamps.current[lessThanKey].toDate().getTime();
            const greaterThanTime = positionTimestamps.current[greaterThanKey].toDate().getTime();
            interpolatedTimeMs = lessThanTime + ratio * (greaterThanTime - lessThanTime);
        }

        // If it is NaN, return null
        if (isNaN(interpolatedTimeMs)) {
            return null;
        }
        return new Date(interpolatedTimeMs);
    }

    function displaySizePosToFullSizePos(displaySizePos) {
        /** Scale the display-size pixel position to the full-size pixel position */
        return displaySizePos * (originalImageHeight.current / displayImageHeight.current);
    }

    function fullSizePosToDisplaySizePos(fullSizePos) {
        /** Scale the full-size pixel position to the display-size pixel position */ 
        if (!fullSizePos) return null;
        return fullSizePos * (displayImageHeight.current / originalImageHeight.current);
    }

    function screenPosToFullSizePos(screenPos) {
        /* Get the full-size pixel position from a position on the screen (counted in pixels from the left screen edge) */
        return displaySizePosToFullSizePos(screenPos - displaySizeOffset);
    }

    function displayCenterToFullSizePos() {
        /* Get the full-size pixel position from the center of the screen */
        return screenPosToFullSizePos(displayWidth / 2);
    }

    function setOffsetToTimestamp(timestamp) {
        /** Set the offset to move center of screen to the timestamp */
        const interpolatedPixel = interpolateFullSizePosForTimestamp(timestamp);
        if (interpolatedPixel === null) {
            // If the timestamp is out of bounds, load new data
            initializeMaterialFlowData(timestamp);
            return;
        }
        const newOffset = displayWidth / 2 - fullSizePosToDisplaySizePos(interpolatedPixel);
        scrollAnimation.current = true;
        setDisplaySizeOffset(newOffset);
    }

    function handleZoom(direction) {
        /** Increase size of material-flow. Set direction to 'in' or 'out' */
        if (direction === "in") {
            displayImageHeight.current = Math.min(displayImageHeight.current*1.5, viewRef.current.offsetHeight);
        } else if (direction === "out") {
            displayImageHeight.current = Math.max(displayImageHeight.current/1.5, 100);
        }
        scrollAnimation.current=true;
        setOffsetToTimestamp(scrollTimestamp);

        // Update the wheel scroll speed. 500 is the default height of the image
        wheelScroolSpeed.current = 2 * (500 / displayImageHeight.current);
    }

    // Update the scroll time when the offset changes
    React.useEffect(() => {
        if (materialFlowData.length === 0) {
            return;
        }
        const pixelPos = displayCenterToFullSizePos();
        const interpolatedTime = interpolateTimestampForFullSizePos(pixelPos);
        if (interpolatedTime === null) {
            return;
        }
        setScrollTimestamp(interpolatedTime);
    }, [displaySizeOffset]);

    // Add event listener for the arrow keys and enter key
    React.useEffect(() => {
        function handleKeyDown(event) {
            switch (event.key) {
                case 'ArrowLeft':
                    observationsLayerRef.current.jumpToNextObservation('left');
                    break;
                case 'ArrowRight':
                    observationsLayerRef.current.jumpToNextObservation('right');
                    break;
                case 'ArrowUp':
                    handleZoom('in');
                    break;
                case 'ArrowDown':
                    handleZoom('out');
                    break;
                case 'Enter':
                    setOffsetToTimestamp(scrollTimestamp);
                    break;
                case 'b':
                    setEnableBboxDisplay(prev => !prev);
                default:
                    break;
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [materialFlowData, scrollTimestamp]);

    function handleWheel(event) {
        /** Handle the wheel event to scroll the material flow */
        scrollImage(event.deltaY > 0 ? 'right' : 'left', Math.abs(event.deltaY)*wheelScroolSpeed.current);
    }

    const handleTouchStart = (event) => {
        touchScrollAnchor.current = event.touches[0].clientX;
    };

    const handleTouchMove = (event) => {
        event.preventDefault(); // Prevent the default scroll action caused by the touch
        const touch = event.touches[0];
        const deltaX = touch.clientX - touchScrollAnchor.current;
        if (Math.abs(deltaX) > 10) {
            touchScrollAnchor.current = touch.clientX;
            const scrollAmount = deltaX * (originalImageHeight.current / displayImageHeight.current);
            scrollImage(deltaX > 0 ? 'right' : 'left', Math.abs(scrollAmount));
        }
    };

    const handleTouchEnd = (event) => {
        // Handle the end of touch
    };

    function checkEdgeLoading() {
        /* Check if the user has scrolled to the edge of the loaded data and load more if necessary */
        if (materialFlowData.length === 0 || loadingNewSegment.current) {
            return;
        }

        const fullSizePosLeftEdge = screenPosToFullSizePos(0);
        const timeLeftEdge = interpolateTimestampForFullSizePos(fullSizePosLeftEdge);
        const timeLeftEdgePadded = addSeconds(timeLeftEdge, LOADING_PADDING_SEC);
        const fullSizePosRightEdge = screenPosToFullSizePos(displayWidth);
        const timeRightEdge = interpolateTimestampForFullSizePos(fullSizePosRightEdge);
        const timeRightEdgePadded = subSeconds(timeRightEdge, LOADING_PADDING_SEC);

        const timestamps = Object.values(positionTimestamps.current).map((timestamp) => timestamp.toDate().getTime());
        const minTimestamp = new Date(Math.min(...timestamps));
        const maxTimestamp = new Date(Math.max(...timestamps));

        if (timeLeftEdgePadded?.getTime() >= maxTimestamp.getTime()) {
            appendMaterialFlowData("left");
        } else if (timeRightEdgePadded?.getTime() <= minTimestamp.getTime()) {
            appendMaterialFlowData("right");
        }
    }
    React.useEffect(() => {
        // Throttle the function to 2 calls per second for scrolling
        if (!lastEdgeLoadingTimer.current || lastEdgeLoadingTimer.current < Date.now() - 500) {
            lastEdgeLoadingTimer.current = Date.now();
            checkEdgeLoading();
        }
    }, [displaySizeOffset, displayWidth]);
    React.useEffect(() => {
        // Check if after appending new data, the edge is still not reached -> load new data until it is
        checkEdgeLoading();
    }, [materialFlowData.length]);

    React.useEffect(() => {
        if (!positionTimestamps.current || Object.keys(positionTimestamps.current).length === 0) {
            return;
        }
        setOffsetToTimestamp(scrollTimestamp);
    }, [displayWidth])

    React.useImperativeHandle(ref, () => ({
        setOffsetToTimestamp,
        jumpToNextObservation: (dir) => {
            observationsLayerRef.current.jumpToNextObservation(dir);
        },
        appendPositionTimestamps: (newPositionTimestamps) => {
            positionTimestamps.current = {...positionTimestamps.current, ...newPositionTimestamps};
        },
        resetPositionTimestamps: () => {
            positionTimestamps.current = {};
        },
        appendBaleFeedPositionTimestamps: (newBaleFeedPositionTimestamps) => {
            baleFeedPositionTimestamps.current = {...baleFeedPositionTimestamps.current, ...newBaleFeedPositionTimestamps};
        },
        resetBaleFeedPositionTimestamps: () => {
            baleFeedPositionTimestamps.current = {};
        },
        setOriginalImageHeight: (height) => {
            originalImageHeight.current = height;
        }
    }));

    /** Filter out visible bale-feed-entries and memoize their display position */
    const visibleBaleFeed = React.useMemo(() => {
        const visibleBaleFeedNew = {};
        Object.entries(baleFeedData).forEach(([id, baleFeedEntry]) => {
            if (!(id in baleFeedFullSizePos)) {
                return;
            }
            let displaySizePosLeft = fullSizePosToDisplaySizePos(baleFeedFullSizePos[id].left);
            let displaySizePosRight = fullSizePosToDisplaySizePos(baleFeedFullSizePos[id].right);
            displaySizePosLeft = displaySizePosLeft===null ? -1 : displaySizePosLeft+displaySizeOffset;
            displaySizePosRight = displaySizePosRight===null ? displayWidth+1 : displaySizePosRight+displaySizeOffset;

            // If the entry is visible, add it to the list. If it is partially visible, clip it to the visible part to correctly display containing text in center.
            if (displaySizePosLeft <= displayWidth && displaySizePosRight >= 0) {
                visibleBaleFeedNew[id] = {...baleFeedEntry, displaySizePos: {
                    left: Math.max(displaySizePosLeft, 0), 
                    right: Math.min(displaySizePosRight, displayWidth),
                }};
            }
        });
        return visibleBaleFeedNew;
    }, [baleFeedData, displaySizeOffset, displayWidth]);

    /** Memoize the color palette for the suppliers */
    const suppliersColorPalette = React.useMemo(() => {
        if (!labelsets?.supplier?.labels) {
            return {};
        }
        const palette = getColorPalette(Object.keys(labelsets?.supplier?.labels).length);
        const suppliersColorPaletteNew = {};
        Object.keys(labelsets?.supplier?.labels).forEach(id => {
            suppliersColorPaletteNew[id] = palette.pop();
        });
        return suppliersColorPaletteNew;
    }, [labelsets]);

    return (
        <div ref={viewRef} className="material-flow-view" onWheel={handleWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <div ref={containerRef} className="material-flow-container" style={{height: displayImageHeight.current}}>
                { /* Display error screen if no data was available for the day */
                materialFlowData.length===0 && <LoadingScreen 
                    loading={t(loadingNewSegment.current ? "loading" : "material_flow_loading_error")} 
                    fullscreen={false} 
                    logoColor="white"
                />}
                { /* Display the material-flow image */
                materialFlowData.length!==0 && <MaterialFlowImg 
                    materialFlowData={materialFlowData} 
                    displaySizeOffset={displaySizeOffset} 
                    fullSizePosToDisplaySizePos={fullSizePosToDisplaySizePos} 
                    scrollAnimation={scrollAnimation.current}
                    displayHeight={displayImageHeight.current}
                />}
                { /* Display the bounding box labeler */ }
                { currentUser.info.user_flags && currentUser.info.user_flags.includes('admin') && enableBboxDisplay && 
                <BBoxLabeler 
                    displaySizePosToFullSizePos={displaySizePosToFullSizePos}
                    fullSizePosToDisplaySizePos={fullSizePosToDisplaySizePos}
                    interpolateTimestampForFullSizePos={interpolateTimestampForFullSizePos}
                    interpolateFullSizePosForTimestamp={interpolateFullSizePosForTimestamp}
                    screenPosToFullSizePos={screenPosToFullSizePos}
                    materialFlowData={materialFlowData}
                    displaySizeOffset={displaySizeOffset}
                    displayWidth={displayWidth}
                    smoothAnimation={scrollAnimation}
                />}
                { /* Display the observations and their labels */ }
                { currentUser.info.user_flags && currentUser.info.user_flags.includes('beta') && materialFlowData.length!==0 &&
                <ObservationsLayer
                    ref={observationsLayerRef}
                    displaySizePosToFullSizePos={displaySizePosToFullSizePos}
                    fullSizePosToDisplaySizePos={fullSizePosToDisplaySizePos}
                    interpolateTimestampForFullSizePos={interpolateTimestampForFullSizePos}
                    interpolateFullSizePosForTimestamp={interpolateFullSizePosForTimestamp}
                    screenPosToFullSizePos={screenPosToFullSizePos}
                    materialFlowData={materialFlowData}
                    displaySizeOffset={displaySizeOffset}
                    displayWidth={displayWidth}
                    smoothAnimation={scrollAnimation}
                    activeObservation={activeObservation}
                    setActiveObservation={setActiveObservation}
                    setOffsetToTimestamp={setOffsetToTimestamp}
                    displayImageHeight={displayImageHeight}
                    scrollTimestamp={scrollTimestamp}
                    appendMaterialFlowData={appendMaterialFlowData}
                    showBoundingBoxes={enableBboxDisplay}
                />}
                { /* Display Suppliers from bale-feed */
                currentUser.info.user_flags && currentUser.info.user_flags.includes('beta') &&
                materialFlowData.length!==0 && <div className="bale-feed-container">
                    {Object.entries(visibleBaleFeed).map(([id, baleFeedEntry]) => {
                        return (
                            <div key={id} className={`bale-feed-entry ${scrollAnimation.current ? "smooth-scrolling" : ""}`} style={{
                                left: baleFeedEntry.displaySizePos.left, 
                                width: baleFeedEntry.displaySizePos.right-baleFeedEntry.displaySizePos.left,
                                background: `linear-gradient(to bottom, ${suppliersColorPalette?.[baleFeedEntry.supplier] ?? '#ffffff'}35, transparent)`,
                            }}>
                                <p className="bale-feed-entry-text">{labelsets?.supplier?.labels?.[baleFeedEntry.supplier]?.full_name ?? baleFeedEntry.supplier}</p>
                            </div>
                        );
                    })}
                </div>}
                <div className="center-line"></div>
            </div>
            { /* Display the time/scrolling interface */
            viewRef.current?.offsetHeight && <MaterialFlowScrollInterface
                scrollTimestamp={scrollTimestamp}
                setScrollTimestamp={setScrollTimestamp}
                setOffsetToTimestamp={setOffsetToTimestamp}
                jumpToNextObservation={(dir) => {if (observationsLayerRef.current) {observationsLayerRef.current.jumpToNextObservation(dir)}}}
                interfaceBottom={(viewRef.current.offsetHeight-displayImageHeight.current)/2}
                positioning={displayImageHeight.current/viewRef.current.offsetHeight > 0.8 ? "top" : "bottom"}
            />}
            { /* Add zooming buttons */}
            <div className="zoom-buttons-container">
                <button className="zoom-button" onClick={() => {handleZoom("in")}}>+</button>
                <button className="zoom-button" onClick={() => {handleZoom("out")}}>-</button>
                { /* Add button to toggle bounding box labeling (kind of misusing the zoom-button classname) */}
                {currentUser.info.user_flags && currentUser.info.user_flags.includes('admin') && <button className={`zoom-button ${enableBboxDisplay && "enabled"}`} 
                    onClick={() => {setEnableBboxDisplay(prev => !prev)}}
                    title={t("material_flow_toggle_bbox_labeling")}
                >
                    <img src={bbox_icon}/>
                </button>}
            </div>
        </div>
    );
});

export default MaterialFlowView;