import React from 'react';
import './DeviceParameters.scss';

import { useTranslation } from "react-i18next";
import {Collapse} from 'react-collapse';

import reset_icon from "../../../assets/icons/gray/refresh.png"

const Parameter = ({ parameter, parameterKey, setDeviceParameters, parameterConfig, nestingDepth }) => {
    const { i18n, t } = useTranslation();
    const [ parameterGroupOpened, setParameterGroupOpened ] = React.useState(false);

    function handleParameterChange(e) {
        /* Update the device parameters with a new value. */
        // Convert the value to the right type
        let value;
        if (parameterConfig.type === "number") {
            value = parseFloat(e.target.value);
        } else if (parameterConfig.type === "bool") {
            value = e.target.value;
        } else {
            value = e.target.value;
        }
        setDeviceParameters(value);
    }

    if (parameterConfig.type === "group") {
        // If the type is an object, then it is a nested parameter group.
        return (
            <div className="parameters-group">
                {nestingDepth === 0 && <h3 className="group-title" onClick={() => {setParameterGroupOpened(prev => !prev)}}>{parameterKey.replace(/_/g, " ")}</h3>}
                {nestingDepth > 0 && <p className="subgroup-title" onClick={() => {setParameterGroupOpened(prev => !prev)}}>{parameterKey.replace(/_/g, " ")}</p>}
                <Collapse isOpened={parameterGroupOpened}>
                    <div className="parameters-container">
                        {Object.keys(parameterConfig.children)
                        .sort((a, b) => {
                            // Move 'group' type parameters to the top. Otherwise sort alphabetically.
                            if (parameterConfig.children[a].type === 'group' && parameterConfig.children[b].type !== 'group') {
                                return -1;
                            }
                            if (parameterConfig.children[a].type !== 'group' && parameterConfig.children[b].type === 'group') {
                                return 1;
                            }
                            return a.localeCompare(b);;
                        })
                        .map((nestedParameterKey, index) => {
                            function setNestedParemeter(value) {
                                /* Set a nested parameter. */
                                setDeviceParameters({
                                    ...parameter,
                                    [nestedParameterKey]: value,
                                })
                            }

                            return (
                                <Parameter
                                    key={index}
                                    parameter={parameter?.[nestedParameterKey]}
                                    parameterKey={nestedParameterKey}
                                    setDeviceParameters={setNestedParemeter}
                                    parameterConfig={parameterConfig.children[nestedParameterKey]}
                                    nestingDepth={nestingDepth + 1}
                                />
                            );
                        })}
                    </div>
                </Collapse>
            </div>
        );
    } else if (parameterConfig.type === "number") {
        return (
            <div className="parameter">
                <label className={parameterConfig.hoverText ? "hover-label" : ""} data-hover={parameterConfig.hoverText}>{parameterKey.replace(/_/g, " ")}</label>
                <input type="number" value={parameter} onChange={handleParameterChange} placeholder="Parameter undefined"/>
                <button className="reset-button" title={"Reset Default Value"} onClick={() => handleParameterChange({target:{value: parameterConfig.default_value}})}><img src={reset_icon}/></button>
            </div>
        );
    } else if (parameterConfig.type === "bool") {
        return (
            <div className="parameter">
                <label className={parameterConfig.hoverText ? "hover-label" : ""} data-hover={parameterConfig.hoverText}>{parameterKey.replace(/_/g, " ")}</label>
                <select value={parameter ?? ""} onChange={handleParameterChange}>
                    <option value="" disabled>Select an option...</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                </select>
                <button className="reset-button" title={"Reset Default Value"} onClick={() => handleParameterChange({target:{value: parameterConfig.default_value}})}><img src={reset_icon}/></button>
            </div>
        );
    } else if (parameterConfig.type === "select") {
        return (
            <div className="parameter">
                <label className={parameterConfig.hoverText ? "hover-label" : ""} data-hover={parameterConfig.hoverText}>{parameterKey.replace(/_/g, " ")}</label>
                <select value={parameter ?? ""} onChange={handleParameterChange}>
                    <option value="" disabled>Select an option...</option>
                    {parameterConfig.options.map((option, index) => {
                        return <option key={index} value={option}>{option}</option>
                    })}
                </select>
                <button className="reset-button" title={"Reset Default Value"} onClick={() => handleParameterChange({target:{value: parameterConfig.default_value}})}><img src={reset_icon}/></button>
            </div>
        )
    } else {
        return (
            <div className="parameter">
                <label className={parameterConfig.hoverText ? "hover-label" : ""} data-hover={parameterConfig.hoverText}>{parameterKey.replace(/_/g, " ")}</label>
                <input type="text" value={parameter} onChange={handleParameterChange} placeholder="Parameter undefined" />
                <button className="reset-button" title={"Reset Default Value"} onClick={() => handleParameterChange({target:{value: parameterConfig.default_value}})}><img src={reset_icon}/></button>
            </div>
        );
    }
};

export default Parameter;