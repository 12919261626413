import React, { useState } from 'react';
import logo_circle from '../assets/logos/logo_uc_blues_on_white.svg';
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../context/FirebaseConfig';

export const Login = () => {
    const [err,setErr] = useState(false);
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);

    const logo_ascii = 
    "ooooo     ooo              .oooooo.    o8o                     oooo            \n"+
    "\`888'     \`8'             d8P'  \`Y8b   \`\"'                     \`888           \n"+  
    " 888       8  oo.ooooo.  888          oooo  oooo d8b  .ooooo.   888   .ooooo.  \n"+
    " 888       8   888' \`88b 888          \`888  \`888\"\"8P d88' \`\"Y8  888  d88' \`88b \n"+
    " 888       8   888   888 888           888   888     888        888  888ooo888 \n"+
    " \`88.    .8'   888   888 \`88b    ooo   888   888     888   .o8  888  888    .o \n"+
    "   \`YbodP'     888bod8P'  \`Y8bood8P'  o888o d888b    \`Y8bod8P' o888o \`Y8bod8P' \n"+
    "               888                                                             \n"+
    "              o888o                                                            \n";

    const hidden_message = "If you are here, you seem to know what you are doing. Work with us! Message us at contact@upcircle.ai"

    console.log(logo_ascii,"\n",hidden_message);
    // creating a handle for the form submission
    const handleSubmit = async (e) => {
        // setLoading(true);
        e.preventDefault();
        const email = e.target[0].value;
        const password = e.target[1].value;

        try{
            await signInWithEmailAndPassword(auth, email, password);
            // if no errors, navigate to home page:
            navigate("/")
        } catch(err){
            setErr(err);
            // setLoading(false);
            console.log(err.code);
            console.log(err.message);
        }  
    };


  return (
    <>
    <div className="formContainer">
        <div className="formWrapper">
            <img className="logo" src={ logo_circle } alt="" />
            <span className='logo_caption'><span className='brand'>UpCircle</span> Analytics</span>
            <span className='title'>Login</span>
            <form onSubmit={handleSubmit}>
                <input type="email" placeholder="email"/>
                <input type="password" placeholder="password"/>
                <button >Sign in</button>
                {err  && <span className='error'> Wrong username or password.</span> }
            </form>
            {/* Uncomment below to allow users to register.  */}
            {/* <p>Don't have an account yet? <Link to="/register">Register.</Link></p> */}
        </div>
        <div className='upcircleFooter'>
            <span className='brand'>UpCircle</span>
            <span className='year'>2023</span>
        </div>
    </div>
    </>
  )
}

export default Login;
