import React from "react";
import "./Labeling.scss";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";

import { collection, doc, query, where, getDocs, onSnapshot, orderBy, limit, startAfter } from "firebase/firestore";

// Importing custom pages
import FullscreenObservationModal from "../../components/FullscreenObservationModal/FullscreenObservationModal";
import ObservationSquare from "../../components/ObservationSquare/ObservationSquare";

export default function LabelingClassicRandomized() {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [ observations, setObservations ] = React.useState({});
    const [ currentObservation, setCurrentObservation ] = React.useState(null);

    async function getLabelObservation() {
        /* Pick an observation for labeling. First check if there are observations where labeling is requested. Otherwise pick a random one */
        if (!currentUser.company.company) {
            return;
        }
        
        var docId = await getRandomObservation();

        //Set up a listener on the doc-id to update on changes
        const observationUpdateQuery = query(doc(firestore, "clients", currentUser.company.company, "observations", docId))
        onSnapshot(observationUpdateQuery, (doc) => {
            const newObservation = {...doc.data(), doc_id:doc.id};
            setObservations((prevObservations) => {
                return {
                    ...prevObservations, 
                    [doc.id]: newObservation,
                };
            });
        })
    }

    async function getRandomObservation() {
        /* Pick a random observation for labeling */
        let observationQuery = query(collection(firestore, "clients", currentUser.company.company, "observations"), 
        where("device", "==", currentUser.device_id),
        where("labelled", "==", false),
        where("random_index", ">=", Math.random()),
        orderBy("random_index", "asc"),
        limit(1));

        // If a filter is set for the client for product groups, apply it to the query.
        const filterObservationClasses = currentUser.devices?.[currentUser.device_id]?.filter_observation_classes;
        if (filterObservationClasses && filterObservationClasses.length > 0) {
            observationQuery = query(observationQuery, where("object_type", "in", filterObservationClasses));
        }

        const querySnapshot = await getDocs(observationQuery);
        let docId = "";
        querySnapshot.forEach((doc) => {
            docId = doc.id;
            const newObservation = {...doc.data(), doc_id:doc.id};
            setObservations((prevObservations) => {
                return {
                    ...prevObservations, 
                    [doc.id]: newObservation,
                };
            });
            setCurrentObservation(newObservation);
        });
        return docId;        
    }

    function getNextObservation(currentObservation) {
        const nextIndex = Object.values(observations).findIndex(obj => obj.doc_id === currentObservation.doc_id) + 1;
        if (nextIndex >= Object.values(observations).length) {
            getLabelObservation();
        } else {
            setCurrentObservation(Object.values(observations)[nextIndex]);
        }
    };
    function getPreviousObservation (currentObservation) {
        const previousIndex = Object.values(observations).findIndex(obj => obj.doc_id === currentObservation.doc_id) - 1;
        setCurrentObservation( Object.values(observations)[Math.max(previousIndex, 0)] )
    };

    return (
        <div className="labeling-classic">
            {<div className="labeling--list">
                {Object.values(observations).map(observation => <div 
                    onClick={() => setCurrentObservation(observation)}
                    key={observation.image_url}>
                        <ObservationSquare observation={observation} />
                </div>)}
                <div className="observation_square overflow-square" onClick={getLabelObservation}>
                    <p className="labeling--new">+</p>
                </div>
            </div>}
            {currentObservation && <FullscreenObservationModal 
                observation={currentObservation}
                closeFullscreenModal={() => {setCurrentObservation(false)}}
                getNext={getNextObservation}
                getPrevious={getPreviousObservation}
            />}
        </div>
    )
}