import { collection, collectionGroup, onSnapshot, query, where, documentId } from "firebase/firestore";
import { firestore } from "../context/FirebaseConfig";
import { format, addDays, addHours } from "date-fns";

export function subscribeAggregatedData(setDataState, timeframe, currentUser, hourly) {
    if (hourly) {
        return subscribeAggregatedDataHourly(setDataState, timeframe, currentUser);
    } else {
        return subscribeAggregatedDataDaily(setDataState, timeframe, currentUser);
    }
}

function subscribeAggregatedDataHourly(setDataState, timeframe, currentUser) {
    /* Aggregate analytics data with hourly entries */
    const dateStringStart = format(timeframe.startDate, "yyyy-MM-dd");
    const dateStringEnd = format(timeframe.endDate, "yyyy-MM-dd");
    let firebaseQuery = query(collectionGroup(firestore, "daily_analytics"), 
            where("client", "==", currentUser.company.company), 
            where("device", "==", currentUser.device_id),
            where("date", ">=", dateStringStart),
            where("date", "<=", dateStringEnd));
    const filterObservationClasses = currentUser.devices?.[currentUser.device_id]?.filter_observation_classes;
    if (filterObservationClasses && filterObservationClasses.length > 0) {
        firebaseQuery = query(firebaseQuery, where("object_type", "in", filterObservationClasses));
    }

    const unsubscribe = onSnapshot(firebaseQuery, (snapshot) => {
        const xValues = getXValuesForTimeframe(timeframe, "hour");
        const yValuesDict = {"productGroup":{}};
        const piechartData = {"productGroup":{}};
        const processedMaterials = new Set();
        let totalCount = 0;
        let totalWeight = 0;

        snapshot.forEach((docSnapshot) => {
            const doc = docSnapshot.data();

            processedMaterials.add(doc.object_type);
            checkAndAddCategory("productGroup", doc.object_type, yValuesDict, piechartData, xValues.length);
            if ("hourly_predictions" in doc) {
                for (const labelset of Object.keys(doc.hourly_predictions)) {
                    for (const label of Object.keys(doc.hourly_predictions[labelset])) {
                        checkAndAddCategory(labelset, label, yValuesDict, piechartData, xValues.length);
                    }
                }
            }

            for (const hour of Object.keys(doc.hourly_count)) {
                const currentTimestamp = createDateTime(doc.date, hour);
                if (currentTimestamp < timeframe.startDate || currentTimestamp > timeframe.endDate) {
                    continue;
                }
                const hourIndex = getHoursBetween(timeframe.startDate, currentTimestamp);

                totalWeight += doc.hourly_weight_kg[hour];
                totalCount += doc.hourly_count[hour];
                piechartData["productGroup"][doc.object_type].value += doc.hourly_weight_kg[hour];
                yValuesDict["productGroup"][doc.object_type][hourIndex] += doc.hourly_count[hour];
                if ("hourly_predictions" in doc) {
                    for (const labelset of Object.keys(doc.hourly_predictions)) {
                        for (const label of Object.keys(doc.hourly_predictions[labelset])) {
                            yValuesDict[labelset][label][hourIndex] += doc.hourly_predictions[labelset][label][hour];
                        }
                    }
                }
            }
        });
        addUncategorizedCount(yValuesDict);

        setDataState({
            "totalCount": totalCount,
            "totalWeight": totalWeight,
            "timelineData": {
                "xValues": xValues,
                "yValuesDict": yValuesDict,
            },
            "piechartData": Object.values(piechartData["productGroup"]),
            "processedMaterials": Array.from(processedMaterials),
        });
    });

    return unsubscribe;
}

function subscribeAggregatedDataDaily(setDataState, timeframe, currentUser) {
    /* Aggregate analytics data with daily entries */
    let firebaseQuery = query(collection(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "analytics"));
    const filterObservationClasses = currentUser.devices?.[currentUser.device_id]?.filter_observation_classes;
    if (filterObservationClasses && filterObservationClasses.length > 0) {
        firebaseQuery = query(firebaseQuery, where(documentId() , "in", filterObservationClasses));
    }

    const unsubscribe = onSnapshot(firebaseQuery, (snapshot) => {
        if (snapshot.size == 0) {
            setDataState(getEmptyAggregatedData());
            return;
        } 

        let totalCount = 0;
        let totalWeight = 0;
        const xValues = getXValuesForTimeframe(timeframe, "day");
        const yValuesDict = {"productGroup":{}};
        let piechartData = {"productGroup":{}};
        const processedMaterials = new Set();

        snapshot.forEach((docSnapshot) => {
            const doc = docSnapshot.data();
            // Initialize all new object types with 0 values
            processedMaterials.add(doc.object_type);
            checkAndAddCategory("productGroup", docSnapshot.id, yValuesDict, piechartData, xValues.length);
            if ("total_predictions" in doc) {
                for (const labelset of Object.keys(doc.total_predictions)) {
                    for (const label of Object.keys(doc.total_predictions[labelset])) {
                        checkAndAddCategory(labelset, label, yValuesDict, piechartData, xValues.length);
                    }
                }
            }

            // Fill in the data for each day available
            for (const dayDate of getXValuesForTimeframe(timeframe, "day")) {
                const dayString = format(dayDate, "yyyy-MM-dd");
                const arrayIndex = xValues.map(Number).indexOf(Number(dayDate));

                totalCount += dayString in doc.daily_count ? doc.daily_count[dayString] : 0;
                totalWeight += dayString in doc.daily_weight_kg ? doc.daily_weight_kg[dayString] : 0;
                piechartData["productGroup"][docSnapshot.id].value += dayString in doc.daily_weight_kg ? doc.daily_weight_kg[dayString] : 0;
                yValuesDict["productGroup"][docSnapshot.id][arrayIndex] += dayString in doc.daily_count ? doc.daily_count[dayString] : 0;
                if ("total_predictions" in doc) {
                    for (const labelset of Object.keys(doc.total_predictions)) {
                        for (const label of Object.keys(doc.total_predictions[labelset])) {
                            yValuesDict[labelset][label][arrayIndex] += dayString in doc.total_predictions[labelset][label] ? doc.total_predictions[labelset][label][dayString] : 0;
                        }
                    }
                }
            }

        });
        addUncategorizedCount(yValuesDict);

        setDataState({
            "totalCount": totalCount,
            "totalWeight": totalWeight,
            "timelineData": {
                "xValues": xValues,
                "yValuesDict": yValuesDict,
            },
            "piechartData": Object.values(piechartData["productGroup"]),
            "processedMaterials": Array.from(processedMaterials),
        });
    });

    return unsubscribe;
}

function checkAndAddCategory(labelset, category, yValuesDict, piechartData, nEntries) {
    if (!yValuesDict[labelset]) {
        yValuesDict[labelset] = {};
        piechartData[labelset] = {};
    }
    if (!yValuesDict[labelset][category]) {
        yValuesDict[labelset][category] = [...Array(nEntries).fill(0)];
        piechartData[labelset][category] = {name: category, value: 0};
    }
}

function addUncategorizedCount(yValuesDict) {
    /* Add a label 'uncategorized' for each observation that doesn't have a prediction for a given labelset */
    if (Object.keys(yValuesDict["productGroup"]).length == 0) {
        return;
    }
    // Get the total number of observations by counting entries in "productGroup" (every observation has this prediction)
    const countTotal = Object.values(yValuesDict["productGroup"]).reduce((acc, labelsetCounts) => {
        return labelsetCounts.map((count, i) => count + acc[i]);
    }, Array(Object.values(yValuesDict["productGroup"])[0].length).fill(0));
    for (const labelset of Object.keys(yValuesDict)) {
        // Count the number of observations that have a predictions for a given labelset
        const countSet = Object.values(yValuesDict[labelset]).reduce((acc, labelsetCounts) => {
            return labelsetCounts.map((count, i) => count + acc[i]);
        }, Array(Object.values(yValuesDict[labelset])[0].length).fill(0));
        // Number of uncategorized is different between total number and number with predictions for labelset
        if (!countSet.reduce((acc, value, i) => { return acc && value===countTotal[i]}, true)) {
            yValuesDict[labelset]['uncategorized'] = countTotal.map((totalCount, i) => Math.max(totalCount-countSet[i], 0));
        }
    }
}

function getXValuesForTimeframe(timeframe, resolution="day") {
    /* Get a list of strings for each day in the timeframe */
    const xValues = [];
    let current = new Date(timeframe.startDate.getTime());
    while (current.getTime() < timeframe.endDate.getTime()) {
        xValues.push(current);
        if (resolution === "day") {
            current = addDays(current, 1);
        } else if (resolution === "hour") {
            current = addHours(current, 1);
        }
    }
    return xValues;
}

export function getEmptyAggregatedData() {
    return {
        "totalCount": 0,
        "totalWeight": 0,
        "timelineData": {
            "product_group": {xValues: [], yValues: []},
        },
        "piechartData": [],
        "processedMaterials": [],
    }
}

function createDateTime(dateString, hour) {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day, hour, 0, 0);
    return date;
}

function getHoursBetween(start, end) {
    const diffInMilliseconds = end - start;
    const hours = Math.floor(diffInMilliseconds / 3600000);
    return hours;
}