import { createContext, useEffect, useState } from "react";
import { auth } from "./FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { firestore } from "./FirebaseConfig";
import { arrayUnion, doc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { fetchUserInfo, fetchLabelsets, fetchParametersConfig } from "../utils/FirebaseUtils";
import { useTranslation } from "react-i18next";

export const AuthContext = createContext();

// Setting up an app-wide user authentication provider. 
export const AuthContextProvider = ({children}) => {
    const { i18n, t } = useTranslation();
    const [authUser, setAuthUser] = useState({});
    const [ userLoaded, setUserLoaded ] = useState(false);
    const [currentUser, setCurrentUser] = useState({
        uid: null,
        info:{languageCode:"de"}, 
        company:{company_displayname:"", company:"", shift_time:0},
        devices: {},
        device_id: null,
        filter_observation_classes: [],
    });
    const [labelsets, setLabelsets] = useState({});
    const [parametersConfig, setParametersConfig] = useState({});

    async function addTimestampToUser(user) {
        updateDoc(doc(firestore, 'users', user), {last_login: serverTimestamp()});
        setDoc(doc(firestore, 'tests', 'user_login_timestamps'), {
        [user]: arrayUnion(new Date().toISOString()) // Using Firestore's server timestamp and arrayUnion
        }, { merge: true });
    }

    async function getUserData(user) {
        const currentUserNew = await fetchUserInfo(user.uid);
        currentUserNew['uid'] = user.uid;

        // Check if the user has "demo" flag enabled. If so, change display name of company for annonymization. 
        if (currentUserNew.info.user_flags && currentUserNew.info.user_flags.includes('demo')) {
            currentUserNew.company.company_displayname = t('demo_mode_company_displayname');
        }

        if (currentUserNew.company) {
            await getLabelsets(currentUserNew.company.company);
        }
        setCurrentUser(currentUserNew);
        setUserLoaded(true);
        if (currentUserNew.info.languageCode) {
            i18n.changeLanguage(currentUserNew.info.languageCode);
        }
    }

    async function getLabelsets(company) {
        if (!company) return;

        const labelsetsNew = await fetchLabelsets(company);        
        setLabelsets(labelsetsNew);
    }

    async function getParametersConfig() {
        /* Fetch the structure of the device parameters and their default values */
        setParametersConfig(await fetchParametersConfig());
    }

    useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
            setAuthUser(user);
            setUserLoaded(false);
            if (user) {
                getUserData(user);
                addTimestampToUser(user.uid)
            } else {
                setCurrentUser({});
            }
        });

        getParametersConfig();
    }, []);

    function changeActiveDevice(device_id) {
        /* Change the active device displayed in the dashboard */
        setCurrentUser({...currentUser, device_id: device_id});
        updateDoc(doc(firestore, 'users', currentUser.uid), {selected_device_id: device_id});
    }

    return(
        // Any component wrapped into "authcontext provider" will be able to reach the current user. 
        // Import into index file to cover the whole application. 
        <AuthContext.Provider value={{authUser, currentUser, userLoaded, labelsets, parametersConfig, changeActiveDevice}}>
            {children}
        </AuthContext.Provider>
    );
};