import "./Overview.scss"
import "../../components/Card/css/Card.scss"
import React from "react";
import { collection, onSnapshot, getDocs, query, where, documentId, orderBy, limit} from "firebase/firestore";
import { firestore } from "../../context/FirebaseConfig";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { format, addDays } from "date-fns";

import Card from '../../components/Card/Card';
import { subscribeAggregatedData, getEmptyAggregatedData } from "../../utils/dataAggregationUtils";

// Importing logos
import card_icon_green from '../../assets/icons/gray/plastics_green.svg';
import card_icon_blue from '../../assets/icons/gray/plastics_blue.svg';
import card_icon_yellow from '../../assets/icons/gray/plastics_yellow.svg';
import card_icon_red from '../../assets/icons/gray/plastics_red.svg';

export default function Insights({ timeframe }) {
    const { currentUser } = React.useContext(AuthContext);
    const { i18n, t } = useTranslation();

    const [numLabels, setNumLabels] = React.useState(0);
    const [tableData, setTableData] = React.useState([]);
    const [forceHourlyData, setForceHourlyData] = React.useState(false);
    const [aggregatedData, setAggregatedData] = React.useState(getEmptyAggregatedData());
    const unsubscribe = React.useRef(null);

    React.useEffect(() => {
        const singleDayTimeframe = forceHourlyData || format(timeframe.startDate, "yyyy-MM-dd") === format(timeframe.endDate, "yyyy-MM-dd");

        if (unsubscribe.current) unsubscribe.current();
        const newUnsubscribe = subscribeAggregatedData(setAggregatedData, timeframe, currentUser, singleDayTimeframe);
        unsubscribe.current = () => newUnsubscribe();

        getNumLabels();
    }, [timeframe, forceHourlyData])

    async function getNumLabels() {
        let totalLabels = 0;

        const q = query(collection(firestore, "clients", currentUser.company.company, "labelling_statistics"), 
            where("timestamp", ">=", timeframe.startDate), where("timestamp", "<", addDays(timeframe.endDate, 1)));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            totalLabels += Object.values(doc.data().labellers).reduce((a,v) => a = a+v, 0);
        })

        setNumLabels(totalLabels);
    }

    React.useEffect(() => {
        //Get Last x Observations for Table
        const numObservations = 25;
        const observations = collection(firestore, "clients", currentUser.company.company, "observations");
        const observationsQuery = query(observations, where("device", "==", currentUser.device_id), orderBy(documentId(), 'desc'), limit(numObservations));
        onSnapshot(observationsQuery, (snapshot) => {
            let newTableData = []
            snapshot.forEach((doc) => {
                newTableData.push({...doc.data(), doc_id: doc.id})
            })
            setTableData(newTableData)
        })
    }, []);

    return (
        <div className="insights">
            <Card 
                cardData={{
                    ctype: 'BasicCard',
                    icon: <img src={card_icon_green} />,
                    title: t("title_count"),
                    subtitle: `Total, ${t(timeframe.label)}`,
                    count: aggregatedData["totalCount"]
                }}
            />
            <Card 
                cardData={{
                    ctype: 'PiechartCard',
                    icon: <img src={card_icon_yellow} />,
                    title: t("material_piechart_card_title"),
                    subtitle: `Total, ${t(timeframe.label)}`,
                }}
                payload={{
                    chartData: aggregatedData["piechartData"].filter(mat => {return mat.value > 0}),
                    tooltipFormatting: (value, name, props) => `${(value/1000).toFixed(1)} t`
                }}
            />
            <Card 
                cardClassName="devicestatuscard"
                cardData={{
                    ctype: 'DeviceStatusCard',
                    icon: <img src={card_icon_green} />,
                    title: t("title_devicestatus"),
                    subtitle: t(timeframe.label),
                    timeframe: timeframe,
                }}       
            />
            <Card 
                cardClassName="timelinecard"
                cardData={{
                    ctype: 'MultiTimelineCard',
                    icon: <img src={card_icon_red} />,
                    title: t("title_timeline"),
                    subtitle: `Total, ${t(timeframe.label)}`,
                    timeframe: timeframe,
                    forceHourlyData: forceHourlyData,
                    setForceHourlyData: setForceHourlyData,
                }}
                payload={aggregatedData["timelineData"]}            
            />
            <Card 
                cardData={{
                    ctype: 'BasicCard',
                    icon: <img src={card_icon_green} />,
                    title: t("title_num_labels"),
                    subtitle: `Total, ${t(timeframe.label)}`,
                    count: `${numLabels}`
                }}
            />
            <Card
                cardClassName="tablecard"
                cardData={{
                    ctype: 'TableCard',
                    icon: <img src={card_icon_blue} />,
                    title: t("title_table"),
                    subtitle: currentUser.devices[currentUser.device_id]?.display_name ?? currentUser.device_id,
                }}
                payload={tableData}
            />
        </div>
    )
}