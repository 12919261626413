import React from "react"

import { firestore } from "../../../context/FirebaseConfig";
import { doc, query, onSnapshot } from "firebase/firestore";
import { subSeconds } from "date-fns";

export function getDeviceStatus(client, device, timeframe, setDeviceStatus) {
    /* Fetches the device status data from Firestore and sets the device status state */
    const deviceStatusQuery = query(doc(firestore, "clients", client, "devices", device, "status_logs_aggregated", "status"));
    const unsubscribe = onSnapshot(deviceStatusQuery, (docSnapshot) => {
        if (!docSnapshot.exists()) {
            return;
        }

        const statusData = docSnapshot.data();
        const statusChartData = getDeviceStatusChartData(timeframe, statusData);
        setDeviceStatus(statusChartData);
    });
    return unsubscribe;
}

function getDeviceStatusChartData(timeframe, statusData) {
    /* Returns chart data for the device status */
    const STEP_DELTA = 300; // step distance between two points in seconds

    const chartData = {x:[], y:[]};
    let lastDate = {date: new Date(0), status: 0};

    const sortedEntries = Object.entries(statusData.status_changes)
        .sort((a, b) => Number(a[0]) - Number(b[0]));
    for (const [timestampString, status] of sortedEntries) {
        const date = new Date(parseInt(timestampString));
        if (date >= timeframe.startDate && date <= timeframe.endDate) {
            if (lastDate.date < timeframe.startDate) {
                // Add first point
                chartData.x.push(timeframe.startDate);
                chartData.y.push(lastDate.status);
            }

            chartData.x.push(subSeconds(date, STEP_DELTA));
            chartData.y.push(lastDate.status);
            chartData.x.push(date);
            chartData.y.push(status);
        } else if (date > timeframe.endDate) {
            break;
        }
        lastDate = {date: date, status: status};
    }
    // Add first point (if there is no valid point in the timeframe)
    if (lastDate.date < timeframe.startDate) {
        chartData.x.push(timeframe.startDate);
        chartData.y.push(lastDate.status);
    }
    // Add last point
    const lastTimestamp = new Date() > timeframe.endDate ? subSeconds(timeframe.endDate, 60) : new Date();
    chartData.x.push(lastTimestamp);
    chartData.y.push(lastDate.status);
    return chartData;
}