import React from "react";
import "./LoadingIcon.scss";

import icon from "../../assets/icons/white/loading.png";

export default function LoadingIcon() {
    return (
        <div className="loading-icon">
            <img src={icon}/>
        </div>
    );
}