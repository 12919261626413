import React from 'react';
import './DeviceParameters.scss';

import DeviceParametersModelWeights from './DeviceParametersModelWeights';
import Parameter from './Parameter';

import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import {Collapse} from 'react-collapse';

const DeviceParameters = ({ deviceParameters, setDeviceParameters }) => {
    const { i18n, t } = useTranslation();
    const { parametersConfig } = React.useContext(AuthContext);
    const [modelWeightsOpened, setModelWeightsOpened] = React.useState(false);

    return (
        <div className="device-parameters">
            <div className="parameters-group">
                <h3 className="group-title" onClick={() => {setModelWeightsOpened(prev => !prev)}}>{"Model Weights"}</h3>
                <Collapse isOpened={modelWeightsOpened}>
                    <DeviceParametersModelWeights deviceParameters={deviceParameters} setDeviceParameters={setDeviceParameters} />
                </Collapse>
            </div>
            {Object.keys(parametersConfig)
            .sort((a, b) => {
                // Move node_orchestration to the top. Otherwise sort alphabetically.
                if (a === 'node_orchestrator_parameters') {
                    return -1;
                } else if (b === 'node_orchestrator_parameters') {
                    return 1;
                }
                return a.localeCompare(b);;
            }).map((parameterKey, index) => {
                function setNestedParameter(value) {
                    setDeviceParameters((prev) => {
                        return {
                            ...prev,
                            [parameterKey]: value
                        }
                    })
                }

                return (
                    <Parameter 
                        key={index}
                        parameter={deviceParameters[parameterKey]}
                        parameterKey={parameterKey} 
                        setDeviceParameters={setNestedParameter}
                        parameterConfig={parametersConfig[parameterKey]}
                        nestingDepth={0}
                    />
                );
            })}
        </div>
    );
};

export default DeviceParameters;