import "./NoRole.scss"
import React from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from "react-i18next";

// Importing custom pages
import Navbar from "../../components/Navbar/Navbar";
import LoadingScreen from "../../components/LoadingScreen";
import Sidebar from "../../components/Navbar/Sidebar";

export default function NoRole() {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);

    return (
        <div className="no-rule">
            <Sidebar />
            <div className="no-rule-right">
                { currentUser.company && <Navbar 
                    title={`${currentUser.company.company_displayname} ${t("sidebar_dashboard")}`}
                />}
                <LoadingScreen loading={t("loading")} fullscreen={false} message={t("user_no_role")} logoColor="dark" />          
            </div> 
        </div>
    )
}