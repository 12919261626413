import React, { useState } from 'react';
import './AdminSettingsModal.scss';

import { useTranslation } from "react-i18next";
import { firestore } from '../../context/FirebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import close_icon from "../../assets/icons/close_black.svg"

const NewClientModal = ({ handleClose }) => {
    const { i18n, t } = useTranslation();
    const [clientId, setClientId] = React.useState('');
    const [clientDisplayName, setClientDisplayName] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    async function clientIdExists() {
        const docSnap = await getDoc(doc(firestore, "clients", clientId));
        return docSnap.exists();
    }

    const handleSubmit = async () => {
        if (!clientId || !clientDisplayName) {
            setErrorMessage("All fields must be filled out.");
            return;
        }

        if (await clientIdExists()) {
            setErrorMessage("Client ID already exists.");
            return;
        }

        const newClient = {
            company: clientId,
            company_displayname: clientDisplayName,
            shift_time: 0,
        }
        await setDoc(doc(firestore, "clients", clientId), newClient);
        handleClose();
    };

    return (
        <div className="admin-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{t("settings_client_create")}</h2>
                    <div className="modal-close-button" onClick={handleClose}>
                        <img className="modal-close-button-icon" src={close_icon} />
                    </div>
                </div>

                <div className="label-container">
                    <label>{t("settings_client_id")}:</label>
                    <input type="text" value={clientId} onChange={(e) => setClientId(e.target.value)} />
                </div>

                <div className="label-container">
                    <label>{t("settings_client_name")}:</label>
                    <input type="text" value={clientDisplayName} onChange={(e) => setClientDisplayName(e.target.value)} />
                </div>

                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <div className="save-delete-buttons">
                    <button className="settings-modal-button light-button" onClick={handleSubmit}>{t("settings_save")}</button>
                </div>
            </div>
        </div>
    );
};

export default NewClientModal;
