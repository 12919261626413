import React from "react";
import "./AdminSettings.scss";

import { collection, query, where, collectionGroup, onSnapshot } from "firebase/firestore";
import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Navbar/Sidebar';
import Dropdown from "../../components/Filters/Dropdown";
import UsersTable from "./UsersTable";
import DevicesTable from "./DevicesTable";
import ClientSettings from "./ClientSettings";
import NewClientModal from "./NewClientModal";
import UserModal from "./UserModal";
import DeviceModal from "./DeviceModal/DeviceModal";

export default function AdminSettings() {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const { currentUser } = React.useContext(AuthContext);

    const [ clients, setClients ] = React.useState({});
    const [ showNewClientModal, setShowNewClientModal ] = React.useState(false);
    const [ showUserModal, setShowUserModal ] = React.useState(false);
    const [ selectedClient, setSelectedClient ] = React.useState({id: 0, name: "All"});

    const [ users, setUsers ] = React.useState({});
    const [ selectedUser, setSelectedUser ] = React.useState(null);

    const [ devices, setDevices ] = React.useState({});
    const [ selectedDevice, setSelectedDevice ] = React.useState(null);
    const [ showDeviceModal, setShowDeviceModal ] = React.useState(false);

    const unsubscribeClients = React.useRef(null);
    const unsubscribeUsers = React.useRef(null);
    const unsubscribeDevices = React.useRef(null);

    React.useEffect(() => {
        if (!currentUser || !currentUser.info || !currentUser.info.user_flags || !currentUser.info.user_flags.includes('admin')) {
            navigate("/")
        }
    }, [currentUser]);

    async function getClients() {
        if (unsubscribeClients.current) unsubscribeClients.current();

        const q = query(collection(firestore, "clients"));
        const unsub = onSnapshot(q, (querySnapshot) => {
            const clientsNew = {};
            querySnapshot.forEach((doc) => {
                clientsNew[doc.id] = doc.data();
            });
            setClients(clientsNew);
        })
        unsubscribeClients.current = () => unsub();
    }

    async function getUsers() {
        if (unsubscribeUsers.current) unsubscribeUsers.current();

        const q = selectedClient.id==0 ? 
            query(collection(firestore, "users")) :
            query(collection(firestore, "users"), where("company", "==", selectedClient.id));
        const unsub = onSnapshot(q, (querySnapshot) => {
            const usersNew = {};
            querySnapshot.forEach((doc) => {
                usersNew[doc.id] = doc.data();
            });
            setUsers(usersNew);
        })
        unsubscribeUsers.current = () => unsub();
    }

    async function getDevices() {
        if (unsubscribeDevices.current) unsubscribeDevices.current();

        const q = selectedClient.id==0 ? 
            query(collectionGroup(firestore, "devices")) :
            query(collection(firestore,"clients", selectedClient.id, "devices"));
        const unsub = onSnapshot(q, (querySnapshot) => {
            const devicesNew = {};
            querySnapshot.forEach((doc) => {
                devicesNew[doc.id] = doc.data();
            });
            setDevices(devicesNew);
        });
        unsubscribeDevices.current = () => unsub();
    }

    React.useEffect(() => {
        getClients();
    }, []);
    React.useEffect(() => {
        getUsers();
        getDevices();
    }, [selectedClient]);

    return (
        <div className="admin-settings">
            <Sidebar />
            <div className="admin-settings--right">
                <Navbar 
                    title={`${t("sidebar_admin_settings")}`}
                />
                <div className="client-header">
                    <Dropdown 
                        options={[
                        {id: 0, name: "All"},
                        ...Object.keys(clients).map(id => {
                            return {
                                id: id,
                                name: clients[id].company_displayname,
                            }
                        })]}
                        setOption={setSelectedClient}
                        defaultOption={{id: 0, name: "All"}}
                        title="Client"
                    />
                    <button className="create-client-button" onClick={() => setShowNewClientModal(true)}>{t("settings_new_client")}</button>
                </div>
                {selectedClient.id !== 0 && 
                <ClientSettings client={clients[selectedClient.id]}/>}
                <UsersTable users={users} setSelectedUser={(user) => {setSelectedUser(user); setShowUserModal(true);}}/>
                <DevicesTable devices={devices} setSelectedDevice={(device) => {setSelectedDevice(device); setShowDeviceModal(true);}}/>
                {showNewClientModal && <NewClientModal
                    handleClose = {() => {setShowNewClientModal(false)}}
                />}
                {showUserModal && <UserModal
                    user = {selectedUser}
                    selectedClient = {selectedClient}
                    clients = {clients}
                    handleClose = {() => {setSelectedUser(null); setShowUserModal(false);}}
                />}
                {showDeviceModal && <DeviceModal
                    device = {selectedDevice}
                    selectedClient = {selectedClient}
                    clients = {clients}
                    handleClose = {() => {setSelectedDevice(null); setShowDeviceModal(false);}}
                />}
            </div>
        </div>
    )
}