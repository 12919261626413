import React from 'react'
import './MaterialFlowView.scss';

import { fetchImageUrl, fetchImageThumbnailUrl } from "../../utils/ImgUtils.js"

export default function MaterialFlowImg({ materialFlowData, displaySizeOffset, fullSizePosToDisplaySizePos, scrollAnimation, displayHeight }) {
    const [imageData, setImageData] = React.useState({});

    React.useEffect(() => {
        materialFlowData.forEach(segment => {
            const useLowres = displayHeight*window.devicePixelRatio <= 300;
            if (imageData?.[segment.id]?.imgHighres) return;
            if (useLowres && imageData?.[segment.id]?.imgLowres) return;

            setImageData(prev => {return {[segment.id]: {imgHighres: "empty", loaded: false}, ...prev}});

            if (useLowres) {
                fetchImageThumbnailUrl(segment.data.image_url, "resized", "_20000x200")
                .then((url) => {
                    const img = new Image();
                    img.src = url;
                    img.onload = () => {
                        const prevSegmentData = imageData[segment.id] || {};
                        setImageData(prev => {return {...prev, [segment.id]: {...prevSegmentData, imgLowres: img, loaded: true}}});
                    }
                });
                return;
            } else {
                fetchImageUrl(segment.data.image_url)
                .then((url) => {
                    const img = new Image();
                    img.src = url;
                    img.onload = () => {
                        const prevSegmentData = imageData[segment.id] || {};
                        setImageData(prev => {return {...prev, [segment.id]: {...prevSegmentData, imgHighres: img, loaded: true}}});
                    }
                });
            }
        });

    }, [materialFlowData, displayHeight]);

    return (
        <>
            {materialFlowData.length!==0 && materialFlowData.map(segment => {
                return <img
                    key={segment.id}
                    className={`material-flow-img ${scrollAnimation ? "smooth-scrolling" : ""}`} 
                    src={imageData[segment.id]?.imgHighres?.src || imageData[segment.id]?.imgLowres?.src || ""} 
                    style={{ 
                        left: displaySizeOffset+fullSizePosToDisplaySizePos(segment.offset) + 'px', 
                        opacity: imageData[segment.id]?.loaded ? 1 : 0
                    }}
                />
            })}
        </>
    );
}