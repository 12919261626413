import { useTranslation } from "react-i18next";
import "./Navbar.scss"
import { Link } from "react-router-dom"
import React, { useContext } from 'react';  

// Importing icons
import uc_logo from '../../assets/logos/logo_uc_technology_ltd_S.png'
import dashboard_icon from '../../assets/icons/gray/dashboard.svg';
import live_icon from '../../assets/icons/gray/live.svg';
import settings_icon from '../../assets/icons/gray/settings.png';
import labeling_icon from '../../assets/icons/gray/labelling.png';
import reclamations_icon from '../../assets/icons/gray/exclamation.png';
import bale_icon from '../../assets/icons/gray/bale_analysis.png';
import truck_icon from '../../assets/icons/gray/truck.png';
import material_flow_icon from '../../assets/icons/gray/material_flow.png';
import back_icon from '../../assets/icons/gray/back_small.png';

// imports for TEST 1 //
import { AuthContext } from "../../context/AuthContext";
import { firestore } from "../../context/FirebaseConfig";
import { doc, increment, setDoc } from 'firebase/firestore';

const Sidebar = React.forwardRef((props, ref) => {
    // translation tools
    const { i18n, t } = useTranslation();
    const [ sidebarOpen, setSidebarOpen ] = React.useState(true);

    // Below are tests for still unused navigation bars - TEST 1 //
    const { currentUser } = useContext(AuthContext);
    const handleClick = async (event, linkName) => {
        try {
            const fieldName = `${linkName}.${currentUser.displayName} ${currentUser.uid}`;
            const docRef = doc(firestore, 'tests', 'button-clicking-tests-v1');
            await setDoc(docRef, {
                [fieldName]: increment(1),
            }, { merge: true });
        } catch (error) {
            console.log('Error updating click count:', error);
        }
    };

    React.useImperativeHandle(ref, () => ({
        toggleSidebar(status = null) {
            if (status !== null) {
                setSidebarOpen(status);
            } else {
                setSidebarOpen(prev => !prev);
            }
        }
    }));

    return (
        <div className={`sidebar-wrapper ${sidebarOpen ? "" : "sidebar-closed"}`}>
            <div className={`sidebar ${sidebarOpen ? "" : "sidebar-closed"}`}>
                <img src={uc_logo} className="sidebar--logo" />
                {currentUser && currentUser.info && currentUser.info.user_flags &&
                <ul className="sidebar--links">
                    {['admin','manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/overview" onClick={(e) => handleClick(e, 'Dashboard')}>
                        <img src={dashboard_icon} alt="dashboard icon"/>
                        <p>{t("sidebar_dashboard")}</p>
                    </Link>}
                    {['admin','manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/deliveries" onClick={(e) => handleClick(e, 'Bale Overview')}>
                        <img src={truck_icon} alt="overview icon"/>
                        <p>{t("sidebar_deliveries")}</p>      
                    </Link>}
                    {['admin','manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/live-view" onClick={(e) => handleClick(e, 'LiveView')}>
                        <img src={live_icon} alt="live icon"/>
                        <p>{t("sidebar_live")}</p>      
                    </Link>}
                    {['admin','manager','labeler'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/plant-feed" onClick={(e) => handleClick(e, 'Reporting')}>
                        <img src={bale_icon} alt="document icon"/>
                        <p>{t("sidebar_plant_feed")}</p>      
                    </Link>}
                    {['admin','manager','labeler'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/material-flow" onClick={(e) => handleClick(e, 'Material Flow')}>
                        <img src={material_flow_icon} alt="material flow icon"/>
                        <p>{t("sidebar_material_flow")}</p>      
                    </Link>}
                    {['admin','manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/reclamations" onClick={(e) => handleClick(e, 'Invoicing')}>
                        <img src={reclamations_icon} alt="reclamation icon"/>
                        <p>{t("sidebar_reclamations")}</p>
                    </Link>}
                    {['admin','manager','labeler'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/labeling" onClick={(e) => handleClick(e, 'Labelling')}>
                        <img src={labeling_icon} alt="labelling icon"/>
                        <p>{t("sidebar_labeling")}</p>
                    </Link>}
                    {currentUser.info.user_flags.includes('admin') &&
                    <Link to="/admin-settings" onClick={(e) => handleClick(e, 'Admin Settings')}>
                        <img src={settings_icon} alt="settings icon"/>
                        <p>{t("sidebar_admin_settings")}</p>
                    </Link>}
                </ul>}
                <button className={`toggle-sidebar-button ${sidebarOpen ? "" : "sidebar-closed"}`} onClick={() => {setSidebarOpen(prev => !prev)}}>
                    <img className={`toggle-sidebar-button-icon ${sidebarOpen ? "" : "sidebar-closed"}`} src={back_icon}/>
                </button>
            </div>
        </div>
    )
});

export default Sidebar;