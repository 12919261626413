import React from 'react'
import "./ObservationSquare.scss"
import { fetchImageThumbnailUrl } from "../../utils/ImgUtils.js"
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext';

import checkmark_icon from "../../assets/icons/checkmark.png";

export default function ObservationSquare({ observation, markLabeled=false }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [authImageUrl, setAuthImageUrl] = React.useState("");
    const [textStyle, setTextStyle] = React.useState({display: 'none'});
    const [flagObservation, setFlagObservation] = React.useState(false);

    const FLAGGING_ACCURACY_THRESHOLD = 0.8;

    React.useEffect(() => {
        setAuthImageUrl(undefined);

        if (!observation.image_url) {
            return;
        }

        fetchImageThumbnailUrl(observation.image_url).then((url) => {
            setAuthImageUrl( url );
        })
    }, [observation.image_url]);

    React.useEffect(() => {
        let flag = false;
        if (currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) &&
            "flag_low_accuracy" in currentUser.company && "predicted_labels" in observation) {
            for(const labelset of currentUser.company.flag_low_accuracy) {
                if (labelset in observation.predicted_labels && observation.predicted_labels[labelset].cumulative_accuracy < FLAGGING_ACCURACY_THRESHOLD) {
                    flag = true;
                }
            }
        }
        setFlagObservation(flag);
    }, [observation]);

    if (!authImageUrl) {
        return (
            <div className="observation_square"></div>
        )
    }

    return (
        <div className="observation_square">
            <img className="observation_square--img" 
                src={authImageUrl} 
                onMouseEnter={e => {setTextStyle({display: 'block', "backgroundColor":"rgba(0,0,0,0.4)"});}}
                onMouseLeave={e => {setTextStyle({display: 'none', "backgroundColor":"rgba(0,0,0,0)"})}}
            />
            <div className="observation_square--overlay" style={textStyle}>
                <div className="observation_square--text-wrapper">
                    <p>{observation.time.slice(0,5)}</p>
                    <p>{t(observation.object_type)}</p>
                </div>
            </div>
            {flagObservation && <div className="observation_square--flag"></div>}
            {markLabeled && observation?.labelled && <img className="observation_square--checkmark" src={checkmark_icon}/>}
        </div>
    )
}