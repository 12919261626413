import React from 'react'
import "./ObservationSquare.scss"
import { useTranslation } from "react-i18next";

export default function OverflowSquare({ numOverflow, onLoadOverflow }) {
    const { i18n, t } = useTranslation();

    return (
        <div className="observation_square overflow-square" onClick={onLoadOverflow}>
            <p className="overflow-number">{`+${numOverflow}`}</p>
        </div>
    )
}