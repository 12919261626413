import React from 'react'
import './BBoxLabeler.scss';

import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext';
import { firestore } from '../../context/FirebaseConfig';
import { doc, collection, setDoc, serverTimestamp, onSnapshot, orderBy, where, query, deleteDoc } from 'firebase/firestore';
import { format, addSeconds, subSeconds } from 'date-fns';

import checkmark_icon   from '../../assets/icons/white/checkmark.png';
import close_icon       from '../../assets/icons/close.svg';

export default function BBoxLabeler({ 
    displaySizePosToFullSizePos, fullSizePosToDisplaySizePos, interpolateFullSizePosForTimestamp, 
    interpolateTimestampForFullSizePos, screenPosToFullSizePos, materialFlowData, displaySizeOffset, 
    displayWidth, smoothAnimation
}) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [enableLabeling, setEnableLabeling] = React.useState(true);
    const [isDragging, setIsDragging] = React.useState(false);
    const [startPos, setStartPos] = React.useState(null);
    const [endPos, setEndPos] = React.useState(null);
    const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });
    const [boundingBoxes, setBoundingBoxes] = React.useState({});
    const [selectedBoundingBox, setSelectedBoundingBox] = React.useState(null);
    const [productGroup, setProductGroup] = React.useState(currentUser.company?.product_groups?.[0] ?? "bale");
    const containerRef = React.useRef(null);
    const unsubscribe = React.useRef(null);
    const loadingAnchorOffset = React.useRef(null);
    const MIN_BBOX_SIZE = 30;

    function loadBoundingBoxes() {
        /* 
        Subscribe to bounding boxes in the current view (with live updates)
        Load bounding boxes withing the current view and a padding of 2x the display width or 10 minutes
        */
        const LOADING_PADDING_SEC = 600;
        loadingAnchorOffset.current = displaySizeOffset;

        // Unsubscribe from previous listener
        if (unsubscribe.current) {
            unsubscribe.current();
        }

        // Get the left and right edges to load bounding boxes (as timestamps)
        const fullSizePosLeftEdge = screenPosToFullSizePos(0);
        const timeLeftEdge = interpolateTimestampForFullSizePos(fullSizePosLeftEdge);
        const timeLeftEdgePaddedTime = addSeconds(timeLeftEdge, LOADING_PADDING_SEC);
        const timeLeftEdgePaddedDistance = interpolateTimestampForFullSizePos(fullSizePosLeftEdge - displayWidth*2);
        const timeLeftEdgePadded = Math.max(timeLeftEdgePaddedTime, timeLeftEdgePaddedDistance);

        const fullSizePosRightEdge = screenPosToFullSizePos(displayWidth);
        const timeRightEdge = interpolateTimestampForFullSizePos(fullSizePosRightEdge);
        const timeRightEdgePaddedTime = subSeconds(timeRightEdge, LOADING_PADDING_SEC);
        const timeRightEdgePaddedDistance = interpolateTimestampForFullSizePos(fullSizePosRightEdge + displayWidth*2);
        const timeRightEdgePadded = Math.min(timeRightEdgePaddedTime, timeRightEdgePaddedDistance);

        if (!timeLeftEdge || !timeRightEdge) return;

        // Query bounding boxes
        const bboxQuery = query(
            collection(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "material_flow_bbox_labels"),
            where("center_timestamp", ">=", new Date(timeRightEdgePadded)),
            where("center_timestamp", "<=", new Date(timeLeftEdgePadded)),
            orderBy("center_timestamp", "desc")
        );
        unsubscribe.current = onSnapshot(bboxQuery, (snapshot) => {
            const bboxData = {};
            snapshot.forEach((doc) => {
                const data = doc.data();
                bboxData[doc.id] = {
                    ...data,
                    created_at: data?.created_at?.toDate(),
                    center_timestamp: data.center_timestamp.toDate(),
                    start: { ...data.start, timestamp: data.start.timestamp.toDate() },
                    end: { ...data.end, timestamp: data.end.timestamp.toDate() },
                };
            });
            setBoundingBoxes(bboxData);
        });
    }

    // Reload bounding boxes when the user scrolled one display width since last loading
    React.useEffect(() => {
        if (!loadingAnchorOffset.current || Math.abs(loadingAnchorOffset.current - displaySizeOffset) > displayWidth) {
            loadBoundingBoxes();
        }
    }, [displaySizeOffset]);
    // Reload bounding boxes when the material flow data changes (e.g. new data loaded)
    React.useEffect(() => {
        loadBoundingBoxes();
    }, [materialFlowData]);

    function handleMouseDown(e) {
        /* Start dragging the bounding box when the user clicks and labeling is enabled */
        if (!enableLabeling) return;
        e.preventDefault();

        const containerRect = containerRef.current.getBoundingClientRect();
        setIsDragging(true);
        setStartPos({
            x: e.clientX - containerRect.left,
            y: e.clientY - containerRect.top,
        });
        setEndPos({
            x: e.clientX - containerRect.left,
            y: e.clientY - containerRect.top,
        });
    };

    function handleMouseMove(e) {
        /* Update the mouse position and the end position of the bounding box */
        const containerRect = containerRef.current.getBoundingClientRect();
        const relativeX = e.clientX - containerRect.left;
        const relativeY = e.clientY - containerRect.top;
        setMousePos({ x: relativeX, y: relativeY });

        if (isDragging) {
            setEndPos({ x: relativeX, y: relativeY });
        }
    };

    function handleMouseUp() {
        /* Create a bounding box label when the user releases the mouse */
        if (!isDragging) return;

        setIsDragging(false);

        // Cancel if the bounding box is too small
        if (Math.abs(startPos.x - endPos.x) < MIN_BBOX_SIZE || Math.abs(startPos.y - endPos.y) < MIN_BBOX_SIZE) {
            setStartPos(null);
            setEndPos(null);
        }
    };

    // Add event listeners for key presses
    React.useEffect(() => {
        const handleKeyPress = (event) => {
            switch (event.key) {
                case ' ': // Space key
                confirmBoundingBox();
                break;
            case 'Escape': // Escape key
                if (startPos && endPos) {
                    setStartPos(null);
                    setEndPos(null);
                } else {
                    setEnableLabeling(false);
                }
                break;
            case 'Delete': // Delete key
                if (selectedBoundingBox) {
                    deleteBoundingBox(selectedBoundingBox);
                }
                break;
            case 'e': // E key
                setEnableLabeling(prev => !prev);
                break;
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9': 
                const index = parseInt(event.key) - 1;
                if (currentUser.company?.product_groups?.length ?? 0 >= index + 1) {
                    setProductGroup(currentUser.company?.product_groups[index]);
                }
                break;
            }
        };
    
        // Add event listener for keydown
        window.addEventListener("keydown", handleKeyPress);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener("keydown", handleKeyPress);
        };
    }, [isDragging, selectedBoundingBox, startPos]);

    async function confirmBoundingBox() {
        /* Create a bounding box label on firebase using the last coordinates */
        if (!startPos || !endPos || isDragging) return;
        const coordinates = {
            x1: Math.min(startPos.x, endPos.x),
            y1: Math.min(startPos.y, endPos.y),
            x2: Math.max(startPos.x, endPos.x),
            y2: Math.max(startPos.y, endPos.y),
        };
        setStartPos(null);
        setEndPos(null);

        // Check if the bounding box is too small
        const width = coordinates.x2 - coordinates.x1;
        const height = coordinates.y2 - coordinates.y1;
        if (width < MIN_BBOX_SIZE || height < MIN_BBOX_SIZE) {
            return;
        }

        // Convert display size coordinates to full size coordinates
        const fullSizeCoordinates = {
            x1: screenPosToFullSizePos(coordinates.x1),
            y1: displaySizePosToFullSizePos(coordinates.y1),
            x2: screenPosToFullSizePos(coordinates.x2),
            y2: displaySizePosToFullSizePos(coordinates.y2),
        };

        // Get timestamps
        const startTimestamp = interpolateTimestampForFullSizePos(fullSizeCoordinates.x2);
        const endTimestamp = interpolateTimestampForFullSizePos(fullSizeCoordinates.x1);
        const centerTimestamp = interpolateTimestampForFullSizePos((fullSizeCoordinates.x1 + fullSizeCoordinates.x2) / 2);

        const segments = getSegmentsBetweenFullSizePos(fullSizeCoordinates.x1, fullSizeCoordinates.x2);
        const startSegment = getSegmentForFullSizePos(fullSizeCoordinates.x2);
        const endSegment = getSegmentForFullSizePos(fullSizeCoordinates.x1);

        const documentId = format(centerTimestamp, "yyyy-MM-dd-HH-mm-ss");
        const newLabelRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "material_flow_bbox_labels", documentId);
        setDoc(newLabelRef, {
            id: documentId,
            device_id: currentUser.device_id,
            client_id: currentUser.company.company,
            created_at: serverTimestamp(),
            created_by: currentUser.uid,
            start: {
                timestamp: startTimestamp,
                segment_id: startSegment.id,
                segment_pos: fullSizeCoordinates.x2 - startSegment.offset,
                segment_image_url: startSegment.data.image_url,
            },
            end: {
                timestamp: endTimestamp,
                segment_id: endSegment.id,
                segment_pos: fullSizeCoordinates.x1 - endSegment.offset,
                segment_image_url: endSegment.data.image_url,
            },
            all_segments: segments.map((segment) => {
                return {
                    segment_id: segment.id,
                    segment_image_url: segment.data.image_url,
                }
            }),
            center_timestamp: centerTimestamp,
            top_pos: fullSizeCoordinates.y1,
            bottom_pos: fullSizeCoordinates.y2,
            object_type: productGroup,
        });
    }

    async function deleteBoundingBox(id) {
        /* Delete a bounding box label from firebase */
        const bboxRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "material_flow_bbox_labels", id);
        deleteDoc(bboxRef);
    }

    function getBoundingBoxStyle(startPos, endPos) {
        /* Get the style for the bounding box positioning */
         if (!startPos || !endPos) return { display: 'none' };

        const x = Math.min(startPos.x, endPos.x);
        const y = Math.min(startPos.y, endPos.y);
        const width = Math.abs(startPos.x - endPos.x);
        const height = Math.abs(startPos.y - endPos.y);

        return {
            left: `${x}px`,
            top: `${y}px`,
            width: `${width}px`,
            height: `${height}px`,
        };
    };

    function getSegmentForFullSizePos(pos) {
        /* Get the material flow segment into which the full-size position falls in */
        for (const segment of materialFlowData) {
            if (pos > segment.offset && pos < segment.offset + segment.data.image_dimensions.width) {
                return segment;
            }
        }
        return null;
    }

    function getSegmentsBetweenFullSizePos(startPos, endPos) {
        /* Get the material flow segments between two full-size positions */
        const posMin = Math.min(startPos, endPos);
        const posMax = Math.max(startPos, endPos);
        const segments = [];
        for (const segment of materialFlowData) {
            if (segment.offset < posMax && segment.offset + segment.data.image_dimensions.width > posMin) {
                segments.push(segment);
            }
        }
        return segments
    }

    /** Memoize display positions for bounding boxes */
    const bboxPositions = React.useMemo(() => {
        const PADDING = displayWidth/2;
        if (!materialFlowData || materialFlowData.length === 0) return {};

        // Get the min and max timestamps of the loaded view -> Can't find positions outside of this range
        const maxTimestamp = materialFlowData[0].data.last_timestamp.toDate();
        const minTimestamp = materialFlowData[materialFlowData.length-1].data.start_timestamp.toDate();

        const bboxPositionsNew = {};
        Object.entries(boundingBoxes).forEach(([id, bbox]) => {
            // Check if the bounding box is within the loaded data range
            if (bbox.start.timestamp < minTimestamp || bbox.end.timestamp > maxTimestamp) return;

            const startPos = {
                x: fullSizePosToDisplaySizePos(interpolateFullSizePosForTimestamp(bbox.start.timestamp)) + displaySizeOffset,
                y: fullSizePosToDisplaySizePos(bbox.top_pos),
            };
            const endPos = {
                x: fullSizePosToDisplaySizePos(interpolateFullSizePosForTimestamp(bbox.end.timestamp)) + displaySizeOffset,
                y: fullSizePosToDisplaySizePos(bbox.bottom_pos),
            };

            // Check if the bounding box is within the display range
            if (Math.max(startPos.x, endPos.x) > displayWidth + PADDING && Math.min(startPos.x, endPos.x) < -PADDING) return;
            
            bboxPositionsNew[id] = {startPos: startPos, endPos: endPos};
        });
        return bboxPositionsNew;
    }, [boundingBoxes, displaySizeOffset, displayWidth, materialFlowData]);

    return (
        <div className="bbox-labeler-container">
            <div className={`bbox-labeler ${enableLabeling && "editing"}`}
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            >
                { /* Display the bounding box being created */}
                {startPos && endPos && <div className="bounding-box" style={getBoundingBoxStyle(startPos, endPos)}>
                    {!isDragging && <div className="confirm-button" 
                        onClick={confirmBoundingBox}
                        onMouseDown={(e) => e.stopPropagation()}
                        title={t("material_flow_confirm_text")}
                    >
                        <img src={checkmark_icon}/>
                    </div>}    
                </div>}

                { /* Display existing bounding boxes */}
                {Object.entries(bboxPositions).map(([id, bbox]) => {
                    return (
                        <div className={`bounding-box confirmed ${smoothAnimation.current && "smooth-animation"}`} key={id} 
                            style={getBoundingBoxStyle(bbox.startPos, bbox.endPos)}
                            onClick={() => setSelectedBoundingBox(id)}
                        >
                            {!enableLabeling && selectedBoundingBox && selectedBoundingBox === id && 
                            <div className="delete-button" onClick={() => {deleteBoundingBox(id)}} title={t("material_flow_delete_text")}>
                                <img src={close_icon}/>
                            </div>}
                        </div>
                    )})
                }

                { /* Display bbox helper lines */}
                { enableLabeling && <div className="helper-line vertical" style={{ left: `${mousePos.x}px` }} />}
                { enableLabeling && <div className="helper-line horizontal" style={{ top: `${mousePos.y}px` }} />}
            </div>
            { /* Display editing menu */}
            <div className="editing-menu">
                <button className={`toggle-editing-button ${enableLabeling && "active"}`} 
                    onClick={() => setEnableLabeling(prev => !prev)}
                    title={t("material_flow_editing_text")}
                >
                    {t("material_flow_edit")}
                </button>
                <select className="select-object-type" value={productGroup} onChange={(e) => setProductGroup(e.target.value)}>
                    {(currentUser.company?.product_groups ?? ["bale"]).map((product_group) => {
                        return <option key={product_group} value={product_group}>{product_group}</option>
                    })}
                </select>
            </div>
        </div>
    )
}