import React from "react"
import "./BaleView.scss"
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext.js';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { firestore } from "../../context/FirebaseConfig.js";

import PropertyField from "../PropertyField/PropertyField.jsx";
import DeliveryImagePreview from "../DeliveryImagePreview/DeliveryImagePreview.jsx";
import LoadingIcon from "../LoadingIcon/LoadingIcon.jsx";
import { fetchImageUrl, authAndDownloadImage } from "../../utils/ImgUtils.js"

import close_icon           from '../../assets/icons/close.svg';
import back_icon_white      from '../../assets/icons/white/back_small.png';
import forward_icon_white   from '../../assets/icons/white/forward_small.png';
import download_icon        from '../../assets/icons/white/download.png';
import notes_icon           from '../../assets/icons/lightblue/document.png';

export default function BaleView({deliveryId, baleId, deliveryImages, onClose, goToPreviousBale, goToNextBale, showDeliveryImage}) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [bale, setBale] = React.useState(null);
    const [baleImg, setBaleImg] = React.useState("");
    const [isImgLoaded, setIsImgLoaded] = React.useState(false);
    const unsubscribe = React.useRef(null);

    React.useEffect(() => {
        // Unsubscribe from previous query
        if (unsubscribe.current) unsubscribe.current();

        // Subscribe to bale document
        const baleRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "bales", baleId);
        const newUnsubscribe = onSnapshot(baleRef, (snapshot) => {
            setBale(snapshot.data());
        });
        unsubscribe.current = () => newUnsubscribe();
    }, [deliveryId, baleId]);

    React.useEffect(() => {
        setIsImgLoaded(false);
        if (!bale?.image_URL) return;
        
        fetchImageUrl(bale?.image_URL).then((url) => {
            setBaleImg(url);
        })
    }, [bale?.image_URL]);

    async function handleBaleFieldChange(event) {
        /** Change value of a bale field and update on database */
        const { name, value } = event.target;
        const baleRef = doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "bales", baleId);
        await updateDoc(baleRef, {[name]: value});
    }

    React.useEffect(() => {
        /* Add event listener for arrow keys to navigate between images */
        function handleKeyDown(event) {
            // Check for left arrow key
            if (event.keyCode === 37) {
                goToPreviousBale();
            }
    
            // Check for right arrow key
            if (event.keyCode === 39) {
                goToNextBale();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => { document.removeEventListener('keydown', handleKeyDown); }
    }, [baleId]);

    return (
        <div className="fullscreen-bale-modal">
            <div className="fullscreen-modal--buttons">
                <div className="fullscreen-modal--button fullscreen-modal--next" onClick={goToPreviousBale}>
                    <img className="fullscreen-modal--button-icon" src={back_icon_white}/>
                </div>
            </div>
            <div className="fullscreen-modal--center">
                <div className="fullscreen-modal--img-wrapper">
                    <img className="fullscreen-modal--img"
                        src={baleImg}
                        onLoad={() => setIsImgLoaded(true)}
                        style={{ display: isImgLoaded ? 'block' : 'none' }}
                    />

                    {/* Show loading spinner */}
                    { !isImgLoaded &&
                        <LoadingIcon />
                    }
                </div>
                <div className="center-footer">
                    <PropertyField 
                        icon={notes_icon} 
                        title={t("property_notes").toUpperCase()} 
                        value={bale?.notes} 
                        name="notes"
                        type="textarea"
                        handleChange={handleBaleFieldChange}
                        editable={true}
                        grid_span={1}
                    />
                    <div className="delivery-image-preview-container">
                        <DeliveryImagePreview
                            deliveryImageData={deliveryImages?.[bale?.delivery_image_id]}
                            displayBale={bale}
                            onClick={() => showDeliveryImage(bale?.delivery_image_id)}
                        />
                    </div>
                </div>
                
            </div>
            <div className="fullscreen-modal--buttons">
                <div className="fullscreen-modal--button" onClick={onClose}>
                    <img className="fullscreen-modal--button-icon" src={close_icon}/>
                </div>
                <div className="fullscreen-modal--button" onClick={() => {
                    authAndDownloadImage(bale?.image_URL, `bale_${baleId}.jpg`)}}>
                    <img className="fullscreen-modal--button-icon" src={download_icon}/>
                </div>
                <div className="fullscreen-modal--button fullscreen-modal--next" onClick={goToNextBale}>
                    <img className="fullscreen-modal--button-icon" src={forward_icon_white}/>
                </div>
            </div>
        </div>
    )
}