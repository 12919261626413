import React from "react";
import "./MaterialFlowPage.scss";

import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from "react-i18next";

// Importing custom pages
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Navbar/Sidebar';
import MaterialFlow from "../../components/MaterialFlow/MaterialFlow";

export default function MaterialFlowPage({}) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const sidebarHandle = React.useRef(null);
    const { timestamp } = useParams();

    // Retract the sidebar when the page is loaded
    React.useEffect(() => {
        sidebarHandle.current.toggleSidebar(false);
    }, []);

    return (
        <div className="material-flow-page">
            <Sidebar ref={sidebarHandle} className="material-flow-sidebar"/>
            <div className="material-flow-page--right">
                <Navbar 
                    title={`${currentUser.company.company_displayname} ${t("sidebar_material_flow")}`}
                />
                <MaterialFlow className="material-flow" timestamp={timestamp} />
            </div>
        </div>
    )
}