import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext';

import './Dropdown.scss'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, defaultStaticRanges, Calendar } from 'react-date-range';
import { isSameDay, subDays, format, startOfYear, endOfDay } from "date-fns";

export default function TimeframeFilter({ timeframe, setTimeframe, singleDate=false }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [dateRange, setDateRange] = useState({
        selection: {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    });

    // Add custom timeframe selections
    const customStaticRanges = [
        ...defaultStaticRanges.map(range => {
            const label_code = "timeframe_" + range.label.toLowerCase().replace(/\s+/g, '_');
            return {...range, label: t(label_code), label_code: label_code};
        }),
        {
            label: t("timeframe_this_year"),
            label_code: "timeframe_this_year",
            range: () => ({
              startDate: startOfYear(new Date()),
              endDate: new Date()
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
        },
    ]

    // If a device initialization date is available, add an 'all' timeframe
    const deviceInitialization = currentUser?.devices?.[currentUser.device_id]?.device_initialization?.toDate() ?? undefined;
    if (deviceInitialization) {
        customStaticRanges.push({
            label: t("timeframe_all"),
            label_code: "timeframe_all",
            range: () => ({
              startDate: deviceInitialization,
              endDate: new Date()
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
        });
    }

    function onDateChange(date) {
        /* handle date pick for time-span calendar */
        setDateRange({...dateRange, ...date});

        let timeframeLabel = format(date.selection.startDate, date.selection.startDate.getFullYear() === new Date().getFullYear() ? 'dd. MMM' : 'dd. MMM yyyy');
        if (date.selection.endDate.toString() !== date.selection.startDate.toString()) {
            timeframeLabel += " - " + format(date.selection.endDate, date.selection.startDate.getFullYear() === new Date().getFullYear() ? 'dd. MMM' : 'dd. MMM yyyy');
        }
        for (let staticRange of customStaticRanges) {
            if (staticRange.isSelected(date.selection)) {
                timeframeLabel = staticRange.label_code;
            }
        }

        setTimeframe({startDate: date.selection.startDate, endDate: endOfDay(date.selection.endDate), label: timeframeLabel})
    }

    function onSingleDateChange(date) {
        /* Handle date pick for single-day calendar */
        let timeframeLabel = isSameDay(new Date(), date) ? "timeframe_today" :
        isSameDay(subDays(new Date(), 1), date) ? "timeframe_yesterday" :
        format(date, date.getFullYear() === new Date().getFullYear() ? 'dd. MMMM' : 'dd. MMM yyyy');
        
        setTimeframe({startDate: date, endDate: date, label: timeframeLabel})
    }

    function getDatePicker() {
        /* Return a calendar for a single day or for a time-span */
        if (singleDate) {
            // Return a calendar that lets you select a single day
            return (
                <Calendar 
                    onChange = {onSingleDateChange}
                    date = {timeframe.startDate}
                />
            )
        } else {
            // Return a calendar that lets you select a time-span
            return (
                <DateRangePicker
                    onChange={onDateChange}
                    ranges={[dateRange.selection]}
                    inputRanges={[]}
                    staticRanges={customStaticRanges}
                />
            )
        }
    }

    return (
        <div className="dropdown">
            <div className="dropdown-splitbutton">
                <button className="dropdown-splitbutton-text">{t("timeframe_title")}:</button>
                <button className="dropdown-splitbutton-button">{t(timeframe.label)}</button>
            </div>
            <div className="dropdown-content dropdown-content-left-align">
                {getDatePicker()}
            </div>
        </div>
    )
}